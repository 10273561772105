import React, { useEffect } from "react";
import Button from "./Button";

const UserManagementTabs = ({
  masterUser,
  firstBtnText,
  firstBtnHandleClick,
  setSuccessMessage,
  secondBtnText,
  secondBtnHandleClick,
}) => {

  useEffect(() => {
    setSuccessMessage('');
  }, [masterUser]);
  return (
    <div className="flex flex-col md:flex-row gap-5 md:gap-0 justify-between w-[80%] my-8 mx-auto">
      <p className="text-sm text-main font-light">Master User: {masterUser}</p>
      <div className="flex flex-col gap-3">
        <Button text={firstBtnText} handleClick={firstBtnHandleClick} />
        {/* <Button text={secondBtnText} handleClick={secondBtnHandleClick} /> */}
      </div>
    </div>
  );
};

export default UserManagementTabs;
