import React from "react";
import { Link } from "react-router-dom";

const HomeCard = ({ href, text, number }) => {
  return (
    <Link
      to={href}
      className="grid grid-cols-4 min-h-[100px] border-[3px] rounded-sm border-[#2d8bba]"
    >
      <div className="flex justify-center items-center text-center text-[#2d8bba] col-span-3 font-bold underline">
        {text}
      </div>
      <div className="flex items-center justify-center bg-[#2d8bba] text-white font-bold">
        {number}
      </div>
    </Link>
  );
};

export default HomeCard;
