import React, { useState } from "react";
import TextInput from "./TextInput";
import Button from "./Button";
import { PHOTOS_PRICE, VIDEOS_PRICE } from "../helpers/constants";
import axios from "axios";

const BoophoPriceTab = ({
  userId,
  photographerId,
  boophoPriceData,
  formData,
  setFormData,
  handleCancel,
  photographer
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("authToken");


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [subcategory, item] = name.split("_");
  
    setFormData((prevData) => ({
      ...prevData,
      boopho_services: {
        ...prevData?.boopho_services, // Ensure boopho_services is defined
        [subcategory]: {
          ...prevData?.boopho_services?.[subcategory], // Ensure the subcategory is defined
          [item]: parseFloat(value) || 0, // Ensure value is parsed as a float or default to 0
        },
      },
    }));
  };

  return (
    <>
      <div className="w-full flex flex-col gap-3">
        {/* Photos */}
        <>
          <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
            Photos
          </h2>
          {photographer?.typesOfPhotography?.map((item, index) => {
            return (
              <TextInput
                key={index}
                label={item}
                name={`photos_${item}`}
                type="number"
                placeholder="Enter Price to show in UI "
                value={formData?.boopho_services?.photos?.[item] || ""}
                handleChange={handleInputChange}
              />
            );
          })}
        </>
        {/* Videos */}
        <>
          <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
            Videos
          </h2>
          {photographer?.typesOfVideography?.map((item, index) => {
            return (
              <TextInput
                key={index}
                label={item}
                name={`videos_${item}`}
                type="number"
                placeholder="Enter per day price"
                value={formData?.boopho_services?.videos?.[item]  || ""}
                handleChange={handleInputChange}
              />
            );
          })}
        </>
        {/* Album */}
        <>
          <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
            Album
          </h2>
          <TextInput
            type="number"
            label={"Album rinting Cost"}
            placeholder="Enter per day price"
            name="album_Album rinting Cost"
            value={formData?.boopho_services?.album?.['Album rinting Cost'] || ""}
            handleChange={handleInputChange}
          />
        </>

        {/* Other */}
        <>
          <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
            Other
          </h2>
          <TextInput
            type="number"
            label={"Any other costs"}
            placeholder="Enter per day price"
            name="other_Any other costs"
            value={formData?.boopho_services?.other?.['Any other costs'] || ""}
            handleChange={handleInputChange}
          />
        </>
      </div>
      <div className="flex gap-2 justify-end">
        <Button text="Cancel" handleClick={handleCancel} />
        {/* <Button text="Save" handleClick={handleSaveClick}/> */}
      </div>
    </>
  );
};

export default BoophoPriceTab;
