import React from "react";
import Button from "./Button";

//react-icons
import { IoLocationSharp } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa";

const RecordingCard = ({
  photographerName,
  photographerCity,
  photographerPhone,
  customerName,
  customerCity,
  customerPhone,
  btnText,
  btnHandleClick,
}) => {
  return (
    <div className="w-full flex flex-col gap-2 p-4 my-1 bg-white border border-[#FFE6D4]">
      <div className="flex flex-wrap gap-5 items-center">
        <p className="text-sm">
          <b className="text-main">Call Date:</b> <span>12-03-2023</span>
        </p>
        <p className="text-sm">
          <b className="text-main">Event Date:</b> <span>12-03-2023</span>
        </p>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-main">
            {photographerName}
          </h1>

          <div className="flex gap-1">
            <IoLocationSharp />
            <p className="text-xs text-main">{photographerCity}</p>
          </div>
          <p>{photographerPhone}</p>
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-2xl font-semibold text-main">{customerName}</h1>
          <div className="flex gap-1">
            <IoLocationSharp />
            <p className="text-xs text-main">{customerCity}</p>
          </div>
          <p>{customerPhone}</p>
        </div>
      </div>

      <Button
        text={btnText}
        rounded="lg"
        handleClick={btnHandleClick}
        icon={<FaMicrophone />}
      />
    </div>
  );
};

export default RecordingCard;
