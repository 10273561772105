import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//react-icons
import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarLeftCollapse,
} from "react-icons/tb";

//constants
import { SIDEBAR_LINKS } from "../helpers/constants";
import { getSocket } from "../Utils/socket";
import { CustomerContext } from "../ContextApi/Customer";
import { useSession } from "./SessionContext";

const Sidebar = () => {
  const [openSideBar, setOpenSideBar] = useState(false);
  const { socket: newSocket, unReadMsg } = useSession();
  // const [newChatCounts, setNewChatCounts] = useState(0);
  const { data, setData } = useContext(CustomerContext);
  let socket = getSocket();
console.log(data, "101myDataat");
  // useEffect(()=>{
  //   socket = getSocket();
  //   newSocket?.on("unread_msg_count", (data) => {
  //     console.log("unread_msg_count", data);
  //     // setNewChatCounts(Object.keys(data).length);
  //     setData(data);
  //   });
  // }, [newSocket]);

  // console.log(unReadMsg, "unReadMsg:sidebar");

  const pathname = useLocation().pathname;

  if (pathname === "/login") {
    return null;
  }


  return (
    <>
      <TbLayoutSidebarRightCollapse
        className="md:hidden fixed top-1/2 text-main text-2xl"
        onClick={() => setOpenSideBar(true)}
      />
      <TbLayoutSidebarLeftCollapse
        className={`${
          openSideBar ? "left-[60%]" : "-left-full"
        } md:hidden  top-1/2 fixed transition-all duration-200 ease-in-out text-main text-2xl`}
        onClick={() => setOpenSideBar(false)}
      />
      <aside
        className={`${
          openSideBar ? "left-0" : "-left-full"
        } fixed w-[60%] overflow-y-scroll md:w-auto md:static md:overflow-y-hidden h-[88vh] transition-all duration-200 ease-in-out z-50`}
      >
        <div
          className={` md:fixed md:left-0 md:h-[88vh] md:w-[23%] overflow-y-scroll bg-white`}
        >
          <div className="flex flex-col ">
            {SIDEBAR_LINKS?.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.href}
                  className={`${
                    pathname.includes(item.href)
                      ? "bg-main text-white"
                      : "text-main"
                  } border-t-2 border-t-[#eedbb5] py-4 text-center text-sm relative flex justify-center items-center`}
                >
                  {item.text} {item.text === "Customer Chats" && Object.keys(unReadMsg)?.length > 0 && <span className={`w-[20px] h-[20px] rounded-full bg-[#2d8bba] text-sm font-bold absolute right-4 flex justify-center items-center ${pathname.includes(item.href) ? "bg-white text-black":""}`}>{Object.keys(unReadMsg)?.length}</span>}
                </Link>
              );
            })}
          </div>
        </div>
      </aside>
    </>
  );
};


export default Sidebar;
