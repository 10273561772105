import React, { useEffect, useState } from "react";
import Chat from "../components/Chat";

// helpers
import { PHOTOGRAPHER_CUSTOMER_CHATS } from "../helpers/constants";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const token = sessionStorage.getItem("authToken");

const PhotographerCustomerChats = () => {
  const [pgUserList, setPgUserList] = useState([]);

  useEffect(()=>{
    const headers = { "x-auth-token": token, };
    axios.get(`${baseUrl}/pg-customer-list`, { headers, })
      .then((response) => {
        console.log("user-pg-list", response.data.data);
        setPgUserList(response.data.data);
      })
      .catch((error) => {
        console.error("Error adding favorite photo:", error);
        console.log("Response data:", error.response.data);
        console.log("Response status:", error.response.status);
        console.log("Response headers:", error.response.headers);
      });
  }, [])
  
  return (
    <section>
      <h1 className="text-2xl md:text-3xl text-main font-bold mb-8">
        Photographer & Customer Chats
      </h1>
      <div className="w-full grid grid-cols-1">
        {pgUserList?.map((item, index) => {
          console.log(item["PHOTOGRAPHER"]?.id, ", ", item["USER"]?.id, "haehehehsshasahs");
          return (
            <Chat
              key={index}
              isFromPgUserChat={true}
              href={`/photographer-customer-chats/${item["PHOTOGRAPHER"]?.id}-${item["USER"]?.id}`}
              data={item}
              profilePic={item.profilePic}
              customerName={"PG: " + item["PHOTOGRAPHER"]?.name + " & User: " + item["USER"]?.name}
              lastMsg={item.lastMsg}
              lastMsgTime={item.lastMsgTime}
              unread={item.unread}
            />
          );
        })}
      </div>
    </section>
  );
};

export default PhotographerCustomerChats;
