import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Button from "../components/Button";

// Assets
import ExampleProfile from "../assets/profile.png";

//helpers
import { PHOTOGRAPHER_CUSTOMER_CHATS } from "../helpers/constants";
import ChatMessage from "../components/ChatMessage";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const token = sessionStorage.getItem("authToken");

// this is a dynamic chat page of user with admin
// example url of this page: http://localhost:3000/photographer-customer-chats/1

const PhotographerCustomerChatPage = () => {
  const params = useParams();
  const location = useLocation();
  const { data } = location.state;
  
  const [chat, setChat] = useState([]);
  
  
  useEffect(()=>{
    const headers = { "x-auth-token": token, };
    const ids = params.id.split("-");
    axios.get(`${baseUrl}/pg-customer-chat?first=${ids[0]}&second=${ids[1]}`, { headers })
      .then((response) => {
        console.log("user-pg-chattsss", response.data.data);
        setChat(response.data.data);
      })
      .catch((error) => {
        console.error("Error adding favorite photo:", error);
        console.log("Response data:", error.response.data);
        console.log("Response status:", error.response.status);
        console.log("Response headers:", error.response.headers);
      });
  }, [])

  return (
    <section className="w-full h-screen overflow-auto bg-white p-3">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex items-center justify-between border-b-[1px] border-b-gray-300 py-1">
            <div className="flex items-center gap-3">
              <img
                src={chat?.profilePic ? chat?.profilePic : ExampleProfile}
                alt="profile"
                width={50}
                height={50}
                className="rounded-full"
              />
              <p className="text-gray-700 font-semibold">
                {chat?.customerName}
              </p>
              <div className="flex flex-col gap-1">
                <p className="text-xs text-gray-600">
                  <b>USER</b>
                </p>
                <p className="text-xs text-gray-600">
                  <b>Email: </b>
                  {data["USER"]?.email}
                </p>
                <p className="text-xs text-gray-600">
                  <b>Phone: </b>
                  {data["USER"]?.phoneNo}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <img
                src={
                  chat?.photographerProfilePic
                    ? chat?.photographerProfilePic
                    : ExampleProfile
                }
                alt="profile"
                width={50}
                height={50}
                className="rounded-full"
              />
              <p className="text-gray-700 font-semibold">
                {chat?.photographerName}
              </p>
              <div className="flex flex-col gap-1">
                <p className="text-xs text-gray-600">
                  <b>PHOTOGRAPHER</b>
                </p>
                <p className="text-xs text-gray-600">
                  <b>Email: </b>
                  {data["PHOTOGRAPHER"]?.email}
                </p>
                <p className="text-xs text-gray-600">
                  <b>Phone: </b>
                  {data["PHOTOGRAPHER"]?.phoneNo}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="flex gap-2"></div> */}

          <div className="flex flex-col gap-3 my-4">
            {chat?.map((item, index) => (
              <ChatMessage
                key={index}
                profilePic={chat.profilePic}
                photographerProfilePic={chat.photographerProfilePic}
                msg={item.message}
                role={data["PHOTOGRAPHER"]?.id == item.senderId ? "photographer": "customer"}
                time={item.createdAt}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-between border py-1 px-2 border-gray-300 focus:border-gray-500">
          <input
            type="text"
            placeholder="Write a message..."
            className="outline-none w-full"
          />
          <Button text="Send" rounded="md" />
        </div>
      </div>
    </section>
  );
};

export default PhotographerCustomerChatPage;
