import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../components/Button";

const RequestEdit = ({ showModal, setShowModal, handleBtnClick,setSuccessMessage, setErrorMessage, successMessage, errorMessage }) => {
  const [comment, setComment] = useState("");

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const closeModal = () => {
    setShowModal(false);
    setComment("");
    setSuccessMessage('');
    setErrorMessage('');

  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-5 p-3 min-w-[80vw] md:min-w-[50vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Ask to Edit</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
          {successMessage && (<p style={{ color: 'green' }}>{successMessage}</p>)}
          {errorMessage && (<p style={{ color: 'red' }}>{errorMessage}</p>)}
            <div className="flex justify-between gap-3 rounded-md w-full m-auto">
              <p className="text-gray-500 text-sm min-w-max">Enter Comment</p>
              <textarea
                className="resize-none border border-gray-300 bg-white p-2 outline-none text-sm w-full"
                value={comment}
                onChange={handleChange}
                placeholder="Comment"
                rows={4}
              />
            </div>

            <div className="text-end">
              <Button
                text="Send to Photographer"
                handleClick={() => handleBtnClick(comment)}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default RequestEdit;
