import React, { useState } from "react";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useSession } from "../components/SessionContext";

// react icons
import { AiOutlineMenu, AiFillSetting, AiOutlineLogout } from "react-icons/ai";
import { RiAccountCircleFill } from "react-icons/ri";

const MenuBar = ({ handleLogout }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const { loggedIn, userName, userId } = useSession();

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
      <div className="relative flex flex-col">
        <AiOutlineMenu
          className="cursor-pointer"
          onClick={() => setOpenMenu(!openMenu)}
        />
        <ul
          className={`${
            openMenu ? "opacity-1 flex" : "opacity-0 hidden"
          } flex-col absolute rounded-md right-0 top-[200%] text-sm bg-white shadow-lg w-[200px]`}
        >
          <li className="flex items-center gap-2 text-gray-500  w-full hover:bg-gray-100 px-2 py-2 ">
            <RiAccountCircleFill className="text-lg" />
            <span>{userName}</span>
          </li>
          <Link
            to="/my-profile"
            className="flex items-center gap-2 text-gray-500  w-full hover:bg-gray-100 px-2 py-2 "
          >
            <RiAccountCircleFill className="text-lg" />
            <span>My Profile</span>
          </Link>
          <Link
            to="/change-password"
            className="flex items-center gap-2 text-gray-500 w-full hover:bg-gray-100 px-2 py-2 "
          >
            <AiFillSetting className="text-lg" />
            <span>Change Password</span>
          </Link>
          <a
            href=""
            className="flex items-center gap-2 text-gray-500 w-full hover:bg-gray-100 px-2 py-2 "
            onClick={handleLogout}
          >
            <AiOutlineLogout className="text-lg" />
            <span>Logout</span>
          </a>
        </ul>
      </div>
    </OutsideClickHandler>
  );
};

export default MenuBar;
