import React, { useState, useEffect } from "react";
import HorizontalTab from "../components/HorizontalTab";
import HorizontalTabsLayout from "../layout/HorizontalTabsLayout";
import UserManagementTabs from "../components/UserManagementTabs";
import Pagination from "../components/Pagination";
import AddUser from "../modals/AddUser";
import RemoveUser from "../modals/RemoveUser";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "../components/DataTable";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import PasswordModal from "../modals/PasswordModal";
import { toast } from "react-toastify";
import NoResults from "../components/NoResults";

const SEARCH_SUGGESTIONS = [
  "123@boopho.com",
  "1298.test@boopho.com",
  "Rahul.12@boopho.com",
];

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState("SUPER_ADMIN");

  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showRemoveAdmin, setShowRemoveAdmin] = useState(false);
  const navigate = useNavigate();
  const [successAdminMessage, setSuccessAdminMessage] = useState(false);
  const [showAddSuperAdmin, setShowAddSuperAdmin] = useState(false);
  const [showRemoveSuperAdmin, setShowRemoveSuperAdmin] = useState(false);
  const token = sessionStorage.getItem("authToken");
  const email = sessionStorage.getItem("userEmail");
  const role = sessionStorage.getItem("role");
  console.log({role});

  const [data, setData] = useState([]); // State to store the fetched data
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchQuery, setSearchQuery] = useState("");
  const [adminId, setAdminId] = useState(null);
  const [adminEmail, setAdminEmail] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [errorMessage, setErrorMessage] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const addAdmin = async (user, setEmailValidation, setPasswordValidation) => {
    if (user.email && user.password) {
      try {
        const headers = {
          "x-auth-token": token,
        };
        const response = await axios.post(
          `${baseUrl}/user/admin`,
          {
            email: user.email,
            password: user.password,
            role: "ADMIN",
          },
          {
            headers: headers,
          }
        );

        if (response.status === 200) {
          setShowAddAdmin(false);
          fetchData();
          setSuccessMessage("User Added Successfully");
          toast.success("User Added Successfully");
        }
      } catch (error) {
        toast.error(error.response.data.error.message);
        console.error(
          "Error while adding admin:",
          error.response.data.error.message
        );
        setErrorMessage(error.response.data.error.message);
      }
    } else {
      if (user.email === "") {
        setEmailValidation(true);
      }
      if (user.password === "") {
        setPasswordValidation(true); // Corrected condition here
      }
    }
  };

  // console.log(errorMessage);
  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };



  const fetchData = () => {
    const apiUrl = `${baseUrl}/user/admin`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    const params = {
      role: activeTab === "admins" ? "ADMIN" : "SUPER_ADMIN",
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      search: searchQuery,
      sortOrder: order
    };

    // Make a GET request to the API
    axios
      .get(apiUrl, {
        params: params,
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        console.log(response);
        // Handle the successful response here
        setData(response.data.data.rows);
        const randomArra = [...response?.data?.data?.count]
        setTotalResults(randomArra.length); // Set the total number of items
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
        // setErrorMessage(error.response.data.error.message);
      });
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  useEffect(() => {
    fetchData(activeTab, currentPage, itemsPerPage, token);
  }, [activeTab, currentPage, order]);

  console.log(data);
  const handleRemoveAdmin = (id, email) => {
    setPasswordModalVisible(true);
    setAdminId(id);
    fetchData();
    setAdminEmail(email);
  };

  const removeTheAdmin = (id) => {
    // Show a confirmation dialog
    const apiUrl = `${baseUrl}/user/admin/${id}`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    // Make a DELETE request to the API
    axios
      .delete(apiUrl, {
        headers: headers,
      })
      .then((response) => {
        // Handle the successful response here
        setShowRemoveAdmin(false);
        fetchData();
        console.log("Admin deleted successfully", response);
        setSuccessMessage("User Deleted Successfully");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error deleting admin:", error);
      });
  };

  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const addSuperAdmin = async (
    user,
    setEmailValidation,
    setPasswordValidation
  ) => {
    console.log(user);
    if (user.email && user.password) {
      try {
        const headers = {
          "x-auth-token": token,
        };
        const response = await axios.post(
          `${baseUrl}/user/admin`,
          {
            email: user.email,
            password: user.password,
            role: "SUPER_ADMIN",
          },
          {
            headers: headers,
          }
        );

        if (response.status === 200) {
          console.log(response);
          setShowAddSuperAdmin(false);
          fetchData();
          setSuccessMessage("User Added Successfully");
        }
      } catch (error) {
        console.error(
          "Error while adding admin:",
          error.response.data.error.message
        );
        setErrorMessage(error.response.data.error.message);
      }
    } else {
      if (user.email === "") {
        setEmailValidation(true);
      }
      if (user.password === "") {
        setPasswordValidation(true); // Corrected condition here
      }
    }
  };

  const removeSuperAdmin = (email) => {
    window.confirm(`Are you sure you want to remove ${email}`);
    console.log(`${email} user removed`);

    const apiUrl = `${baseUrl}/user/admin/${email}`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };
    // Make a GET request to the API
    axios
      .delete(apiUrl, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        // Handle the successful response here
        setShowRemoveSuperAdmin(false);
        setSuccessMessage("User Deleted Successfully");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  return (


    <section className="w-full">

      <HorizontalTabsLayout>
        <HorizontalTab
          text={"Admins"}
          selected={activeTab === "admins"}
          handleClick={() => setActiveTab("admins")}
        />
        {role == "SUPER_ADMIN" || role == "MASTER_ADMIN" ? (
          <HorizontalTab
            text={"Super Admins"}
            selected={activeTab === "superadmins"}
            handleClick={() => setActiveTab("superadmins")}
          />
        ): null}
      </HorizontalTabsLayout>
      {/* {errorMessage && (<p style={{ color:'red' }}>{errorMessage}</p> )} */}

      {activeTab === "admins" ? (
        <UserManagementTabs
          masterUser="admin@boopho.com"
          firstBtnText={"Add Admins"}
          firstBtnHandleClick={() => setShowAddAdmin(true)}
          secondBtnText={"Remove Admins"}
          secondBtnHandleClick={() => setShowRemoveAdmin(true)}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <UserManagementTabs
          masterUser="superadmin@boopho.com"
          firstBtnText={"Add Super Admins"}
          firstBtnHandleClick={() => setShowAddSuperAdmin(true)}
          secondBtnText={"Remove Super Admins"}
          secondBtnHandleClick={() => setShowRemoveSuperAdmin(true)}
          setSuccessMessage={setSuccessMessage}
        />
      )}

      <AddUser
        showModal={showAddAdmin}
        setShowModal={setShowAddAdmin}
        btnText="Create Admin"
        handleBtnClick={addAdmin}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />

      {/* <RemoveUser
       showModal={showRemoveAdmin}
       setShowModal={setShowRemoveAdmin}
       btnText="Remove Admin"
       handleBtnClick={removeAdmin}
       allUsers={SEARCH_SUGGESTIONS}
     /> */}

      <AddUser
        showModal={showAddSuperAdmin}
        setShowModal={setShowAddSuperAdmin}
        btnText="Create Super Admin"
        handleBtnClick={addSuperAdmin}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />

      {/* <RemoveUser
       showModal={showRemoveSuperAdmin}
       setShowModal={setShowRemoveSuperAdmin}
       btnText="Remove Super Admin"
       handleBtnClick={removeSuperAdmin}
       allUsers={SEARCH_SUGGESTIONS}
     /> */}

      {activeTab === "admins" ? (
        <div className="flex justify-between">
          <p className="text-2xl text-main font-bold my-5 w-full">
            Admin Listing
          </p>
          <SearchAndFilterPanel
            onSearch={(e) => onSearch(e)}
            searchQuery={searchQuery}
            onChangeSearch={onChangeSearch}
            handleOders={handleOders}
          />
        </div>
      ) : (
        <div className="flex justify-between">
          <p className="text-2xl text-main font-bold my-5 w-full">
            Super Admin Listing
          </p>
          <SearchAndFilterPanel
            onSearch={(e) => onSearch(e)}
            searchQuery={searchQuery}
            onChangeSearch={onChangeSearch}
            handleOders={handleOders}
          />
        </div>
      )}

      {/* {successMessage && (
       <div>
         <p
           style={{
             backgroundColor: "#51c151",
             width: "300px",
             width: "362px",
             padding: "15px",
             borderRadius: " 5px",
             marginBottom: "20px",
           }}
         >
           {successMessage}
         </p>
       </div>
     )} */}
      {data.length > 0 ? (
        <div>

          {activeTab === "admins" ? (
            <DataTable data={data} masterUser="admin@boopho.com" removeAdmin={handleRemoveAdmin} />
          ) : (
            <DataTable data={data} masterUser="superadmin@boopho.com" removeAdmin={handleRemoveAdmin} />
          )}

          {/* Add a button to trigger the password confirmation modal */}
          {/* <button onClick={() => setPasswordModalVisible(true)}>Remove Admin</button> */}

          <PasswordModal
            email={email}
            adminId={adminId}
            adminEmail={adminEmail}
            visible={passwordModalVisible}
            setVisible={setPasswordModalVisible}
            onConfirm={() => {
              setPasswordModalVisible(false);
            }}
            setError={setPasswordError}
            removeAdmin={removeTheAdmin}
          />


          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <NoResults  />

      )}

    </section>

  );
};

export default UserManagement;
