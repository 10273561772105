import React, { useEffect, useState } from "react";
import RecordingCard from "../components/RecordingCard";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import Recording from "../modals/Recording";
import NoResults from "../components/NoResults";
import axios from "axios";

// helpers
import { CALL_RECORDINGS } from "../helpers/constants";

//sample audio
import sampleAudio from "../assets/sample_audio.mp3";

const CallRecording = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showRecording, setShowRecording] = useState(false);
  const [recording, setRecording] = useState("");
  const [data, setData] = useState([]); // State to store the fetched data
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("authToken");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState();

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss");
    setOrders(prop);
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const listenRecording = (id) => {
    let foundRecording = data.find((x) => x.id === id);
    console.log(foundRecording, "FOUND");
    setShowRecording(true);
    setRecording(foundRecording.audioUrl);
    console.log(id);
  };

  const fetchData = () => {
    const apiUrl = `${baseUrl}/recording`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    const params = {
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      search: searchQuery,
      sortOrder: order,
    };

    // Make a GET request to the API
    axios
      .get(apiUrl, {
        params: params,
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        console.log(response);
        // Handle the successful response here
        setData(response.data.data.rows);
        setTotalResults(response.data.data.count); // Set the total number of items
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
        // setErrorMessage(error.response.data.error.message);
      });
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [order, currentPage]);
  return (
    <>
      <SearchAndFilterPanel
        onSearch={(e) => onSearch(e)}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
        handleOders={handleOders}
      />
      {data.length > 0 ? (
        <section>
          <h1 className="text-3xl text-main font-bold mb-4">Call Recordings</h1>
          {data?.map((item, index) => {
            return (
              <RecordingCard
                key={index}
                photographerName={item?.photographerName}
                photographerCity={item?.photographerCity}
                photographerPhone={item?.photographerPhone}
                customerName={item?.customerName}
                customerCity={item?.customerCity}
                customerPhone={item?.customerPhone}
                btnText={"Listen Recording"}
                btnHandleClick={() => listenRecording(item.id)}
              />
            );
          })}
          <Recording
            showModal={showRecording}
            setShowModal={setShowRecording}
            audioId={recording}
          />
          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />
        </section>
      ) : (
        <NoResults heading="Call Recordings" />
      )}
    </>
  );
};

export default CallRecording;
