import React, { useEffect, useState } from "react";
import FullWidthCard from "../components/FullWidthCard";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";
import axios from "axios";

// helpers
import { SEARCH_HISTORY } from "../helpers/constants";

const CallRecording = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const token = sessionStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  console.log({searchQuery})


  const headers = {
    "x-auth-token": token,
  };

  const fetchSearchResults = async () => {
    try {
      const response = await axios.get(`${baseUrl}/search-history`, {
        headers,
        params: {
          limit: resultsPerPage,
          offset: (currentPage - 1) * resultsPerPage,
          search:searchQuery,
          sortOrder: order
        },
      });
      setSearchResults(response.data.data.rows);
      setTotalResults(response.data.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSearchResults();
  }, [currentPage,searchQuery, order]); // Re-fetch data when the current page or search query changes

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to the first page when performing a new search
    fetchSearchResults();
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleOders= (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  return (
    <>
      <SearchAndFilterPanel
        onSearch={handleSearch}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
        handleOders={handleOders}
      />
      {searchResults.length > 0 ? (
        <section>
          <h1 className="text-3xl text-main font-bold mb-4">Search History</h1>
          <div className="grid md:grid-cols-2 gap-2">
            {searchResults.map((item, index) => (
              <FullWidthCard
                key={index}
                email={item?.photographerUser?.email || "Not Available"}
                text={item?.photographerUser?.name || "Not Available"}
                city={item?.photographerUser?.Photographer?.serviceLocation || "Not Available"}
                phone={item?.photographerUser.Photographer?.studioName || "Not Available"}
                showBtn={false}
              />
            ))}
          </div>
          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />
        </section>
      ) : (
        <NoResults heading="Search History" />
      )}
    </>
  );
};

export default CallRecording;
