import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import axios from "axios";
import NoResults from "../components/NoResults";

const InProgressEvent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cardData, setCardData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiUrl = `${baseUrl}/bookings`;
  const token = sessionStorage.getItem("authToken");

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }
  const fetchData = async () => {
    try {
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.get(apiUrl, {
        params: {
          status: "CANCELLED",
          limit: resultsPerPage,
          offset: (currentPage - 1) * resultsPerPage,
          search:searchQuery,
          sortOrder: order
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        if (Array.isArray(responseData.rows)) {
          // Ensure rows is an array
          setCardData(responseData.rows);

          // setTotalPages(Math.ceil(responseData.count / 10));
          setTotalResults(responseData.count)
        } else {
          console.error("Invalid data format: rows is not an array.");
        }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage,order, token]);

  return (
    <>
          <SearchAndFilterPanel
            onSearch={(e) => onSearch(e)}
            searchQuery={searchQuery}
            onChangeSearch={onChangeSearch}
            handleOders={handleOders}
          />
      {cardData.length > 0 ? (
        <section>
          <h1 className="text-xl md:text-3xl text-main font-bold mb-3">
            Cancelled Events
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {cardData.map((item) => (
              <Card
                key={item.id}
                href={`/event-timeline/${item.id}`}
                status={"Cancelled"}
                name={item?.PhotographerUser?.name}
                city={item?.PhotographerUser?.Photographer?.serviceLocation}
                phone={item?.PhotographerUser?.phoneNo}
                eventStartDate={item.startDate}
                eventEndDate={item.endDate}
                location={item.address}
              />
            ))}
          </div>
          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />
        </section>
      ) : (
        <NoResults heading="Cancelled Events" />
      )}
    </>
  );
};

export default InProgressEvent;
