import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSession } from "../components/SessionContext";
import MenuBar from "./MenuBar";
import NotificationMenu from "./NotificationMenu";

// logo
import logo from "../assets/logo.png";

const Navbar = () => {
  const pathName = useLocation().pathname;

  const showNavBar = pathName !== "/";

  const navigate = useNavigate();
  const { loggedIn, userName, userId } = useSession();

  const handleLogout = () => {
    sessionStorage.removeItem("loggedIn");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("authToken");
    sessionStorage.removeItem("userEmail");
    window.location.reload();
    navigate("/");
  };

  return (
    <>
      {showNavBar && (
        <nav className="sticky top-0 flex justify-between min-h-[12vh] px-6 py-4 z-20 bg-white w-full">
          <div className="flex items-center">
            <Link to="/">
              <img src={logo} alt="" width={200} height={50} />
            </Link>
          </div>
          <div className="flex items-center gap-5 ">
            {loggedIn && <NotificationMenu />}
            {loggedIn ? (
              <MenuBar handleLogout={handleLogout} />
            ) : (
              <Link to="/" className="text-main">
                Login
              </Link>
            )}
          </div>
        </nav>
      )}
    </>
  );
};

export default Navbar;
