import React, { useState } from "react";
import MorePhotos from "../modals/MorePhotos";
import VideoGrid from "../modals/VideoGrid";
import videoThumbnail from "../assets/video-thumbnail.jpg";
import ReusableModel from "../modals/ReusableModel";

const ImageDetail = ({ label, required, value, categoryId, favPhoto }) => {
  const [showMorePhotos, setShowMorePhotos] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [view, setView] = useState(false);
  const [image, setImage] = useState(false);

  const handleThumbnailClick = (videoUrl) => {
    console.log(value);
    setSelectedVideo(videoUrl);
  };
  console.log(value, "valuessss");

  return (
    <>
      <div className="grid items-center md:grid-flow-col md:grid-cols-3">
        <p className="text-sm col-span-1 text-gray-600">
          {label} {required && <span className="text-red-500">*</span>}
        </p>
        <div className="row">
          <div className="col-span-2 flex items-center gap-3">
            {value?.image?.length >= 1 && (
              <p className="text-sm font-semibold text-secondary">Images:</p>
            )}
            {value?.image?.map((item, index) => {
              console.log(item, "itemmm")
              if (index <= 5) {
                return (
                  <img
                    key={index}
                    src={item}
                    alt=""
                    className="max-w-[30px] max-h-[30px] md:max-w-[55px] md:max-h-[55px]"
                    onClick={() => {
                      setImage(item);
                      setView(true);
                    }}
                  />
                );
              } else {
                return;
              }
            })}
            {value?.image?.length > 1 && (
              <div
                className="bg-secondary px-3 rounded-md py-1 min-w-max text-center cursor-pointer"
                onClick={() => setShowMorePhotos(true)}
              >
                <span className="text-sm text-white">See All</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid items-center md:grid-flow-col md:grid-cols-3">
        <div className="row"></div>
        <div className="col-span-2 flex items-center gap-3">
          {value?.video?.length >= 1 && (
            <p className="text-sm font-semibold text-secondary">Videos:</p>
          )}
          {value?.video?.map((item, index) => {
            if (index <= 5) {
              return (
                <div key={index}>
                  <img
                    src={videoThumbnail}
                    alt="Video Thumbnail"
                    onClick={() => handleThumbnailClick(item)}
                    style={{ cursor: "pointer", width: "40px" }}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
      <MorePhotos
        showModal={showMorePhotos}
        setShowModal={setShowMorePhotos}
        morePhotos={value}
        categoryId={categoryId}
        favPhoto={favPhoto}
      />

      {selectedVideo && (
        <VideoGrid
          videoUrl={selectedVideo}
          closeModal={() => setSelectedVideo(null)}
        />
      )}

      <ReusableModel
        children={
          <img
            src={image}
            alt=""
            className="h-full w-full mt-10 border border-black p-1"
          />
        }
        view={view}
        setView={setView}
      />
    </>
  );
};

export default ImageDetail;
