import React, { useState, useEffect, Children } from "react";
import Card from "../components/Card";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import axios from "axios";
import NoResults from "../components/NoResults";
import Folder from "../components/Folder";
import { Collapse } from "antd";
const ApproveFootage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [state, setState] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL_NON_ADMIN;
  const apiUrl = `${baseUrl}/share/photographer-shares`;
  const token = sessionStorage.getItem("authToken");

  // const onChangeSearch = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const fetchData = async () => {
    try {
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.get(apiUrl, {
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response?.data?.data;
        console.log(responseData, "REPOSNENNENENE");
        const data = responseData.map((x) => {
          return {
            key: x[0].id,
            label: x[0].User.name,
            children: (
              <div className="flex flex-row">
                {x.map((y) => {
                  return (
                    <Folder
                      color="#54adef"
                      href={`/my-footage/${y.sharedUrl?.replace(
                        "photographer-uploads/",
                        "photographer-uploads-"
                      )}`}
                      data={y}
                      text={y.name}
                    />
                  );
                })}
              </div>
            ),
          };
        });
        setState(data);
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <>
      {/* <SearchAndFilterPanel
        onSearch={(e) => onSearch(e)}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
       
      /> */}
      {true ? (
        <div>
          <h1 className="md:text-3xl text-xl text-main font-bold mb-10">
            Approve Footage
          </h1>
          <Collapse accordion items={state} className="mb-5" />
        </div>
      ) : (
        <NoResults heading="Approve Footage" />
      )}
    </>
  );
};

export default ApproveFootage;
