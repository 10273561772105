import React, { useEffect, useState } from "react";
import PieChart from "../components/PieChart";
import BarChart from "../components/BarChart";
import LineChart from "../components/LineChart";

//helpers
import {
  PIE_CHART_EVENT_LABELS,
  PIE_CHART_EVENT_DATA,
  PIE_CHART_BOOKINGS_LABELS,
  PIE_CHART_WEDDING_DATA,
  PIE_CHART_BABY_DATA,
  BEST_PHOTOGRAPHERS,
  BEST_PHOTOGRAPHERS_DATA,
  TRAFFIC_LABELS,
  TRAFFIC_DATA,
} from "../helpers/constants";
import axios from "axios";


const Home = () => {
  const token = sessionStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const weddingData = PIE_CHART_WEDDING_DATA;
  const babyData = PIE_CHART_BABY_DATA;
  const birthdayData = PIE_CHART_BABY_DATA;
  const businessData = PIE_CHART_BABY_DATA;
  const maternityData = PIE_CHART_BABY_DATA;

  const [bookingData, setBookingData] = useState([]);
  const [dashboardData, setDashboardData] = useState({})
  const [trafficData, setTrafficData] = useState([]);
  const [totalBookingData, setTotalBooking] = useState([]);
  console.log(dashboardData, "dashboarddDataa")

  const PhotographerchartData = {
    17: "Wedding",
    18: "Maternity",
    22: "Baby",
    24: "Birthday",
    25: "Business",
    // data : bookingData,
    // labels: BEST_PHOTOGRAPHERS,
    // colors: ["#007bff", "#dc3545", "#ffc107", "#28a745", "#17a2b8"],
    // legend: {
    //   display: true,
    //   position: "right",
    // },
  }


  const BEST_PHOTOGRAPHERS = bookingData?.map((acc) => acc?.User?.Photographer?.studioName);
  const BEST_PHOTOGRAPHERS_DATA = bookingData?.map((acc) => acc?.bookingCount);


  // console.log({ BEST_PHOTOGRAPHERS_DATA })




  const headers = {
    "x-auth-token": token,
  };


  const fetchDashboard = async (val, type) => {
    console.log(val, type, "valuefetchhh")
    try {
      if (val == "" || val == undefined) {
        console.log("object is undefined")
        const bookingcatogries = await axios.get(`${baseUrl}/dashboard/17/phographer-bookings`, { headers })
        const totalBooking = await axios.get(`${baseUrl}/dashboard/17/booking-count-by-month`, { headers })
        setBookingData(bookingcatogries?.data?.data)
        setTotalBooking(totalBooking?.data?.data)
      } else if (val && type === "total_bookings") {
        const totalBooking = await axios.get(`${baseUrl}/dashboard/${val}/booking-count-by-month`, { headers })
        setTotalBooking(totalBooking?.data?.data)
      }
      else {
        console.log("object is not undefined")
        const bookingcatogries = await axios.get(`${baseUrl}/dashboard/${val}/phographer-bookings`, { headers })

        setBookingData(bookingcatogries?.data?.data)

      }
      const data = await axios.get(`${baseUrl}/dashboard/events`, { headers });

      console.log(data, "daashboardDataaaaaaa")

      const trafficData = await axios.get(`${baseUrl}/dashboard/traffic`, { headers })
      const updatedTrafficData = Array.from({ length: 12 }, (_, i) => {
        const apiDataForMonth = trafficData?.data?.data?.find(data => data.month === i + 1);
        return apiDataForMonth ? apiDataForMonth.usersCount : 0;
      });
      // console.log({ bookingcatogries })
      console.log({ updatedTrafficData })
      setTrafficData(updatedTrafficData)
      setDashboardData(data.data.data)
      // return data.data;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    try {
      fetchDashboard()
    } catch (error) {
      console.log(error);
    }
  }, [])
  const pieChartData = [dashboardData?.upcoming, dashboardData?.ongoing
  ]

  const handleChangeEventData = (e) => {
    console.log(e.target.value, "Valueeeeeee")
    switch (e.target.value) {
      case "17":
        fetchDashboard(e.target.value)
        // setBookingData(weddingData);
        break;
      case "18":
        fetchDashboard(e.target.value)
        break;
      case "22":
        fetchDashboard(e.target.value)
        break;
      case "24":
        fetchDashboard(e.target.value)
        break;
      case "25":
        fetchDashboard(e.target.value)
        break;

      default:
        break;
    }
  };
  console.log({ totalBookingData })

  const handleTotalBooking = (e) => {

    switch (e.target.value) {
      case "17":
        fetchDashboard(e.target.value, "total_bookings");
        // setBookingData(weddingData);
        break;
      case "18":
        fetchDashboard(e.target.value, "total_bookings");
        break;
      case "22":
        fetchDashboard(e.target.value, "total_bookings");
        break;
      case "24":
        fetchDashboard(e.target.value, "total_bookings");
        break;
      case "25":
        fetchDashboard(e.target.value, "total_bookings");
        break;

      default:
        break;
    }
  };

  const PieChartDataTotalBooking = totalBookingData?.monthlyBookingCounts?.map(val => val.bookingCount)
  const totalBookingCount = totalBookingData?.totalBookings

  console.log({ totalBookingCount })

  return (
    <section className="w-full overflow-hidden">
      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col gap-3">

          <PieChart
            dataLabels={PIE_CHART_BOOKINGS_LABELS}
            chartData={PieChartDataTotalBooking}
            label="bookings"
            title="Total Bookings"
            dataChecked={totalBookingCount}

          />

          <div className="flex justify-evenly">
            <select
              onChange={handleTotalBooking}
              className="w-max bg-blue-500 outline-none text-white text-sm px-2 py-1 rounded-md"
            >
              <option value="17" className="bg-white text-blue-500">
                Wedding
              </option>
              <option value="24" className="bg-white text-blue-500">
                Birthday
              </option>
              <option value="18" className="bg-white text-blue-500">
                Maternity
              </option>
              <option value="22" className="bg-white text-blue-500">
                Baby
              </option>
              <option value="25" className="bg-white text-blue-500">
                Business
              </option>
            </select>
            {/* <select className="w-max bg-blue-500 outline-none text-white text-sm px-2 py-1 rounded-md">
              <option value="wedding" className="bg-white text-blue-500">
                2023
              </option>
              <option value="birthday" className="bg-white text-blue-500">
                2022
              </option>
              <option value="maternity" className="bg-white text-blue-500">
                2021
              </option>
            </select> */}
          </div>
        </div>
        <PieChart
          dataLabels={PIE_CHART_EVENT_LABELS}
          chartData={pieChartData}
          label="events"
          title="Events"
        />
      </div>
      <div className="flex flex-col gap-3">
        <BarChart
          dataLabels={BEST_PHOTOGRAPHERS}
          chartData={BEST_PHOTOGRAPHERS_DATA}
          label="Best Photographers"
          title="Best Photographers"
        />
        <div className="flex justify-evenly">
          <select onChange={handleChangeEventData} className="w-max bg-blue-500 outline-none text-white text-sm px-2 py-1 rounded-md">
            <option value="17" className="bg-white text-blue-500">
              Wedding
            </option>
            <option value="24" className="bg-white text-blue-500">
              Birthday
            </option>
            <option value="18" className="bg-white text-blue-500">
              Maternity
            </option>
            <option value="22" className="bg-white text-blue-500">
              Baby
            </option>
            <option value="25" className="bg-white text-blue-500">
              Business
            </option>
          </select>
          {/* <select className="w-max bg-blue-500 outline-none text-white text-sm px-2 py-1 rounded-md">
            <option value="wedding" className="bg-white text-blue-500">
              2023
            </option>
            <option value="birthday" className="bg-white text-blue-500">
              2022
            </option>
            <option value="maternity" className="bg-white text-blue-500">
              2021
            </option>
          </select> */}
        </div>
      </div>
      <LineChart
        dataLabels={TRAFFIC_LABELS}
        chartData={trafficData}
        label="Traffic"
        title="Traffic"
      />
    </section>
  );
};

export default Home;
