import React, { useEffect, useRef, useState } from "react";
import Button from "../components/Button";

//react-icons
import { RxCross1 } from "react-icons/rx";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Confirmation from "../modals/Confirmation";

const AddPhoto = ({
  showModal,
  setShowModal,
  uploadedFile,
  setUploadedFile,
  onFileUpload,
  saveFile,
  handleDelete,
  errorMessage,
  alreadyUploadedImgs,
  successMessage,
  setSuccessMessage,
  setErrorMessage,
  addPhotosFunc,
  categoryId
}) => {
  const fileUploadRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const closeModal = () => {
    setShowModal(false);
    setUploadedFile("");
    setErrorMessage('');
    setSuccessMessage('');
  };

  const openDeleteModal = (itemId) => {
    setShowDeleteModal(true);
    setDeleteItemId(itemId); // Set item.id in the state
  };

  
  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
      setSuccessMessage('');
      setErrorMessage('');
      
    } else {
      document.body.style.overflowY = "visible";
    }
  
    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal, categoryId]); // Include categoryId in the dependency array
  

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-[8%] z-20 flex flex-col gap-1 pb-8 min-w-[80vw] max-w-[80vw] md:min-w-[40vw] md:max-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Add Photo</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
            
            {errorMessage && ( <p style={{ color: 'red',  marginLeft: '10px' }}>{errorMessage}</p>)}
            {successMessage && ( <p style={{ color: 'green', marginLeft: '10px' }}>{successMessage}</p>)}
            <div className="flex flex-col gap-3 justify-center items-center pt-4">
              {!uploadedFile && (
                <div
                  className="w-[80%] min-h-[200px] flex gap-2 justify-center items-center border border-dashed border-gray-500 cursor-pointer"
                  onClick={() => fileUploadRef.current.click()}
                >
                  <AiOutlineCloudUpload className="text-3xl text-gray-700 font-semibold" />
                  <h1 className="text-xl text-gray-700 font-semibold">
                    Upload Photo
                  </h1>
                </div>
              )}
              {uploadedFile && (
                <div className="max-w-[80%] overflow-auto max-h-[200px] flex gap-2 justify-center items-center ">
                  <img src={uploadedFile} alt="" />
                </div>
              )}

              <input
                type="file"
                hidden
                ref={fileUploadRef}
                onChange={onFileUpload}
              />
              {uploadedFile && (
                <div className="flex gap-2 items-center">
                  <Button text={"Save"} handleClick={saveFile} />
                  <Button
                    text={"Change Image"}
                    handleClick={() => fileUploadRef.current.click()}
                  />
                </div>
              )}
            </div>
            <div className="grid w-[80%] grid-cols-4 m-auto pt-4 max-h-[200px]">
              {alreadyUploadedImgs?.map((item, index) => {
                return (
                  <div className="relative w-max my-2" key={index}>
                    <img src={item.imageUrl} alt="" className="max-h-[80px]" />
                    <button className="absolute -top-[6px] -right-[6px] bg-white border rounded-full border-gray-600"
                      // onClick={() => handleDelete(item.id)}
                      onClick={() => openDeleteModal(item.id)}
                    >
                      <MdDelete className="text-red-500 text-xl" />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <Confirmation
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            heading="Are you sure?"
            text="Are you sure you want to Delete?"
            btnText="Delete"
            handleConfirm={() => handleDelete(deleteItemId)}
          />
        </section>
      )}
    </>
  );
};

export default AddPhoto;
