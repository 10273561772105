import React, { useState, useEffect } from "react";
import DataTable from "../components/DataTable";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const AllPhotographers = () => {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [allPhotographer, setAllPhotographer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const token = sessionStorage.getItem("authToken");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchPhotographers();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const fetchPhotographers = () => {
    const apiUrl = `${baseUrl}/user/photographers`;
    const headers = {
      "x-auth-token": token,
    };

    const params = {
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      sortOrder: order
    };

    if (searchQuery) {
      params.search = searchQuery;
    }

    axios
      .get(apiUrl, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        setAllPhotographer(response.data.data.rows);
        setTotalResults(response.data.data.count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const removeAdmin = async (email) => {
    // window.confirm(`Are you sure you want to remove ${email}`);
    // console.log(`${email} admin removed`);

    const willDelete = await swal({
      title: "Are you sure?",
      text: `Are you sure you want to remove ${email}`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: true,
      },
    });

    console.log(willDelete);

    if (willDelete) {
      const apiUrl = `${baseUrl}/user/admin/${email}`;
      const headers = {
        "x-auth-token": token, // Replace with your actual token
      };
      // Make a GET request to the API
      axios
        .delete(apiUrl, {
          headers: headers, // Pass the headers object here
        })
        .then((response) => {
          // Handle the successful response here
          // setShowRemoveAdmin(false);
          console.log("admin deleted successfully", response);
          fetchPhotographers();
          // setSuccessMessage("User Deleted Successfully");
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error fetching data:", error);
        });
    }
  };

  useEffect(() => {
    fetchPhotographers();
  }, [currentPage, resultsPerPage, order]);

  return (
    <>
      <SearchAndFilterPanel
        onSearch={(e) => onSearch(e)}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
        handleOders={handleOders}
      />
      {allPhotographer.length > 0 ? (
        <section>
          <h1 className="text-3xl text-main font-bold mb-4">
            All Photographers
          </h1>
          <DataTable
            hideRemoveBtn={true}
            data={allPhotographer}
            option="photographer"
            showViewDetailsAction={true}
            removeAdmin={removeAdmin}
            viewDetails={(id) =>
              navigate(`/all-photographers/view-details/${id}`)
            }
          />

          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />
        </section>
      ) : (
        <NoResults heading="All Photographers" />
      )}
    </>
  );
};

export default AllPhotographers;
