import React from "react";

const HorizontalTabsLayout = ({ children, gap, className = "" }) => {
  return (
    <ul
      className={`${className} flex flex-wrap text-sm font-medium text-center border-b border-secondary `}
      style={{ gap: gap ? `${gap}px` : "" }}
    >
      {children}
    </ul>
  );
};

export default HorizontalTabsLayout;
