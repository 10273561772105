import React, { useState, useEffect } from "react";
import AddEvent from "../modals/AddEvent";
import RemoveUser from "../modals/RemoveUser";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "../components/Button";

import CategoryCards from "../components/CategoryCards";
import AddPhoto from "../modals/AddPhoto";
import AddCategoryPhotos from "../modals/AddCategoryPhotos";

import { MdAdd, MdDelete } from "react-icons/md";

//helpers
import { CATEGORY_PHOTOS } from "../helpers/constants";
import { async } from "q";

const SEARCH_SUGGESTIONS = [
  "123@boopho.com",
  "1298.test@boopho.com",
  "Rahul.12@boopho.com",
];

const ManageCategories = () => {
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [showRemoveAdmin, setShowRemoveAdmin] = useState(false);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("authToken"); // Replace with your actual token
  const [data, setData] = useState([]); // State to store the fetched data
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [alreadyUploadedImgs, setAlreadyUploadedImgs] = useState([]);
  const [imgData, setImageData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userUrl = `${process.env.REACT_APP_URL}/user`;
  const [dataId, setDataID] = useState("");

  const addEvent = async (eventCategory, setEventValidation) => {
    if (!eventCategory.trim()) {
      setEventValidation(true);
      setErrorMessage("Event category is required.");
      return;
    }
    if (selectedImages.length < 0) {
      setErrorMessage("Category must have at least 5 images.");
      return;
    }

    // Create a new FormData object to send text and file data
    const formData = new FormData();
    formData.append("name", eventCategory); // Add the event name

    selectedImages?.forEach((img, index) => {
      formData.append("media", img);
    });

    try {
      const headers = {
        "x-auth-token": token,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      };

      const response = await axios.post(
        `${baseUrl}/user/event-category`,
        formData,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log(response);
        setShowAddEvent(false);
        getEvents();
        setSuccessMessage("Category created successfully");
      } else {
        setErrorMessage("Unable to add category. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error while adding admin:",
        error.response.data.error.message
      );
      setErrorMessage(error.response.data.error.message);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getEvents = () => {
    const apiUrl = `${userUrl}/event-categories`;
    const headers = {
      "x-auth-token": token,
    };

    axios
      .get(apiUrl, {
        headers: headers,
      })
      .then((response) => {
        setData(response.data.data);
        setTotalItems(response.data.data.count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  const removeAdmin = (email) => {
    window.confirm(`Are you sure you want to remove ${email}`);
    console.log(`${email} admin removed`);

    const apiUrl = `${baseUrl}/user/admin/${email}`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };
    // Make a GET request to the API
    axios
      .delete(apiUrl, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        // Handle the successful response here
        setShowRemoveAdmin(false);
        console.log("admin deleted successfully", response);
        setSuccessMessage("User Deleted Successfully");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  const onFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setUploadedFile(e.target.result);
        setImageData(file);
      };

      reader.readAsDataURL(file);
    }
  };

  const deleteMedia = async (images) => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      for (const image of images) {
        const apiUrl = `${baseUrl}/user/event-category/${image.id}`;
        const headers = {
          "x-auth-token": token,
        };
        const requestBody = {
          type: "Media",
        };

        const response = await axios.delete(apiUrl, {
          headers: headers,
          data: requestBody,
        });

        console.log(response);

        if (response.status === 200) {
          console.log("Media deleted successfully");
          // setSuccessMessage("Media deleted successfully");
          toast.success("Media deleted successfully", {
            position: "top-right",
            autoClose: 3000,
          });
          setShowAddPhotoModal(false);

          getEvents();
        } else {
          console.error("Failed to delete media");
        }
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const saveFile = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    const formData = new FormData();
    uploadedFile.map((item) => {
      formData.append("media", item);
    });

    try {
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.patch(
        `${baseUrl}/user/event-category/${categoryId}`,
        formData,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log(response);
        setShowAddEvent(false);
        getEvents();
        setShowAddPhotoModal(false);
        setUploadedFile("");
        toast.success("Media added successfully", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        setErrorMessage("Unable to add category. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error while adding admin:",
        error.response.data.error.message
      );
      toast.error(error.response.data.error.message, {
        position: "top-right",
        autoClose: 3000,
      });
      // setErrorMessage(error.response.data.error.message);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();

    selectedImages.forEach((image) => {
      formData.append("images", image);
    });
  };

  const addPhotosFunc = (index) => {
    // setSuccessMessage('');
    // setErrorMessage('');
    console.log(index);
    setDataID(index);
    setShowAddPhotoModal(true);
    console.log("other", data);
    setCategoryId(data[index].id);
    setAlreadyUploadedImgs(data[index].EventCategoryMedia);
    getEvents();
  };

  return (
    <section>
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-main text-xl md:text-3xl font-bold mb-4">
          Manage Categories
        </h1>

        {/* <div className="flex items-center gap-2 justify-end">
          <Button
            text="Add Event Category"
            icon={<MdAdd className="text-lg" />}
            rounded="md"
            bg="green"
            handleClick={() => setShowAddEvent(true)}
          />
          <Button
            text="Remove Event Category"
            icon={<MdDelete className="text-lg" />}
            rounded="md"
            bg="red"
            handleClick={() => setShowRemoveAdmin(true)}
          />
        </div> */}
      </div>

      <AddEvent
        showModal={showAddEvent}
        setShowModal={setShowAddEvent}
        btnText="Create Event Category"
        handleBtnClick={addEvent}
        handleUpload={handleUpload}
        setSelectedImages={setSelectedImages}
        selectedImages={selectedImages}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />

      <RemoveUser
        showModal={showRemoveAdmin}
        setShowModal={setShowRemoveAdmin}
        btnText="Remove Category"
        handleBtnClick={removeAdmin}
        allUsers={SEARCH_SUGGESTIONS}
      />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
        {data?.map((item, index) => {
          return (
            <CategoryCards
              key={index}
              name={item.name}
              img={item.EventCategoryMedia}
              handleBtnClick={() => addPhotosFunc(index)}
              successMessage={successMessage}
            />
          );
        })}
      </div>
      {/* <AddPhoto
        showModal={showAddPhotoModal}
        setShowModal={setShowAddPhotoModal}
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
        onFileUpload={onFileUpload}
        saveFile={saveFile}
        alreadyUploadedImgs={alreadyUploadedImgs}
        handleDelete={deleteMedia}
        errorMessage={errorMessage}
        successMessage={successMessage}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        addPhotosFunc={addPhotosFunc}
        categoryId={dataId}
      /> */}

      <AddCategoryPhotos
        showModal={showAddPhotoModal}
        setShowModal={setShowAddPhotoModal}
        alreadyUploadedImgs={alreadyUploadedImgs}
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
        onFileUpload={onFileUpload}
        saveFile={saveFile}
        handleDelete={deleteMedia}
        errorMessage={errorMessage}
        successMessage={successMessage}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        categoryId={dataId}
      />
    </section>
  );
};

export default ManageCategories;
