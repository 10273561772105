import React from "react";
import { MdDone } from "react-icons/md";

const CheckBoxDetail = ({ required, label, value }) => {
  console.log(value, "valuevaluevaluevaluevaluevalue");
  return (
    <div className="grid gap-2 md:gap-0 md:grid-flow-col md:grid-cols-3">
      <p className="text-sm md:col-span-1 text-gray-600">
        {label} {required && <span className="text-red-500">*</span>}
      </p>
      <ul
        className="w-full md:col-span-2 grid grid-flow-col gap-3"
        style={{
          gridTemplateRows: `repeat(${Math.ceil(
            value?.length / 2
          )}, minmax(0, 1fr))`,
        }}
      >
        {/* Comment code because code split */}
        {value?.map((item, index) => {
          return (
            <li key={index} className="flex items-center gap-1">
              <div
                className="bg-secondary rounded-full p-[2px]"
                style={{ opacity: !item.selected ? 0.7 : 1 }}
              >
                <MdDone className="text-white text-sm" />
              </div>
              <span className="text-sm text-secondary">{item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CheckBoxDetail;
