import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Button from "../components/Button";
import SetEventDate from "../modals/SetEventDate";

const INITIAL_EVENTS = [
  {
    id: 549,
    title: "Mayoon",
    start: "2023-10-16",
    end: "2023-10-20",
  },
];

const ChangeEventCalendar = () => {
  const params = useParams();

  const calendarRef = useRef();

  const [showEventModal, setShowEventModal] = useState(false);

  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleDateSelect = (selectInfo) => {
    const calendarApi = selectInfo.view.calendar;

    const { startStr, endStr } = selectInfo;

    setSelectedDates({ startDate: startStr, endDate: endStr });
  };

  const setEventDate = async (title) => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.addEvent({
      // id: 1848,
      title: title,
      start: selectedDates.startDate,
      end: selectedDates.endDate,
      allDay: true,
      backgroundColor: "#22a6b3",
    });

    // Clear the selection
    calendarApi.unselect();

    setShowEventModal(false);
  };

  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  return (
    <>
      <section className="w-full flex flex-col gap-10 items-center py-10">
        <div className="bg-white p-4 rounded-lg shadow-md md:w-[70%] w-[95%]">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev",
              center: "title",
              right: "next",
            }}
            eventBackgroundColor="#22a6b3"
            height={500}
            initialEvents={INITIAL_EVENTS} //set here initial events
            eventClick={handleEventClick}
            eventDisplay="auto"
            initialView={"dayGridMonth"}
            editable={true}
            selectable={true}
            weekends={true}
            select={handleDateSelect}
          />
        </div>
        <div className="">
          <Button
            text="Set Event Date"
            rounded="md"
            handleClick={() => setShowEventModal(true)}
          />
        </div>
      </section>
      <SetEventDate
        showModal={showEventModal}
        setShowModal={setShowEventModal}
        handleBtnClick={(title) => setEventDate(title)}
      />
    </>
  );
};

export default ChangeEventCalendar;
