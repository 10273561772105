import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

//react-icons
import { FaFilter } from "react-icons/fa";
import {
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  AiOutlineStar,
  AiFillStar,

} from "react-icons/ai";
import { BsCalendarDate } from "react-icons/bs";

const Filter = ({ rating, handleOders }) => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setShowFilters(false)}>
      <div className="relative z-10">
        <button
          className="flex items-center text-sm gap-1"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FaFilter />
          <span>Sort</span>
        </button>
        <ul
          className={`${!showFilters ? "opacity-0 pointer-events-none" : " opacity-1 "
            } transition-all duration-200 top-[150%] right-0 ease-in-out absolute py-2 bg-white shadow-lg rounded-lg min-w-max text-sm`}
        >
          <li className="px-4 py-2 hover:bg-gray-100 text-gray-600 cursor-pointer flex items-center gap-1">
            <AiOutlineSortAscending />
            <span onClick={() => handleOders("ASC")}>Ascending Order</span>
          </li>
          <li className="px-4 py-2 hover:bg-gray-100 text-gray-600 cursor-pointer flex items-center gap-1">
            <AiOutlineSortDescending />
            <span onClick={() => handleOders("DESC")}>Descending Order </span>
          </li>
          {/* <li className="px-4 py-2 hover:bg-gray-100 text-gray-600 cursor-pointer flex items-center gap-1">
            <BsCalendarDate />
            <span>Event Date </span>
          </li> */}
          {rating && (
            <li className="px-4 py-2 hover:bg-gray-100 text-gray-600 cursor-pointer flex items-center gap-1">
              <AiFillStar />
              <span>Best Rating </span>
            </li>
          )}
          {rating && (
            <li className="px-4 py-2 hover:bg-gray-100 text-gray-600 cursor-pointer flex items-center gap-1">
              <AiOutlineStar />
              <span>Lowest Rating </span>
            </li>
          )}
        </ul>
      </div>
    </OutsideClickHandler>
  );
};

export default Filter;
