import React, { ReactDOM, useState, useEffect } from "react";
import DataTable from "../components/DataTable";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import Recording from "../modals/Recording";
import NoResults from "../components/NoResults";
import axios from "axios";

import { CSVLink, CSVDownload } from "react-csv";

import PasswordModal from '../modals/PasswordModal';


// helpers
// import { ALL_CUSTOMERS } from "../helpers/constants";

const AllCustomers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [allCustomsers, setAllCustomsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const [downloadableData, setDownloadableData] = useState([]);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const token = sessionStorage.getItem("authToken"); // Replace with your actual token
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [adminId, setAdminId] = useState(null);
  const email = sessionStorage.getItem("userEmail");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };


  const removeTheCustomer = (id) => {
    // Show a confirmation dialog
    const apiUrl = `${baseUrl}/user/${id}/customer`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    // Make a DELETE request to the API
    axios
      .delete(apiUrl, {
        headers: headers,
      })
      .then((response) => {
        // Handle the successful response here
        // setShowRemoveAdmin(false);
        fetchData();
        console.log("Customer deleted successfully", response);
        setSuccessMessage("Customer Deleted Successfully");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error deleting admin:", error);
      });
  };

  const handleRemoveCustomer = (id, email) => {
    console.log(email);
    setPasswordModalVisible(true);
    setAdminId(id);
    fetchData();
  };

  const fetchData = () => {

    const apiUrl = `${baseUrl}/user/customers`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    const params = {
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      search: searchQuery,
      sortOrder: order

    };

    // Make a GET request to the API
    axios
      .get(apiUrl, {
        params: params,
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        // Handle the successful response here
        setAllCustomsers(response.data.data.rows);
        setTotalResults(response.data.data.count); // Set the total number of items
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }
  useEffect(() => {
    fetchData();
  }, [currentPage, resultsPerPage, order, token]);

  useEffect(() => {
    const generateDownloadData = () => {
      let data = allCustomsers?.map((item) => {
        return {
          Id: item.id,
          Email: item.email,
          PhoneNo: item.phoneNo,
          Status: item.status,
        };
      });
      setDownloadableData(data);
    };

    generateDownloadData();
  }, [allCustomsers]);


  return (
    <>
      <SearchAndFilterPanel
        onSearch={onSearch}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
        handleOders={handleOders}
      />
      {allCustomsers.length > 0 ? (
        <section>
          <div className="flex flex-col md:flex-row justify-between">
            <h1 className="text-3xl text-main font-bold mb-4">All Customers</h1>
            <CSVLink data={downloadableData} filename="all-customers.csv">
              <button className="bg-main py-1 px-3 text-white rounded-md">
                Download
              </button>
            </CSVLink>
          </div>
          {successMessage && <p style={{ color: 'green', marginBottom: '20px' }}>{successMessage}</p>}
          <DataTable data={allCustomsers} option="customer" removeAdmin={handleRemoveCustomer} />

          <PasswordModal
            email={email}
            adminId={adminId}
            visible={passwordModalVisible}
            setVisible={setPasswordModalVisible}
            onConfirm={() => {
              setPasswordModalVisible(false);
            }}
            setError={setPasswordError}
            removeAdmin={removeTheCustomer}
          />
          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />
        </section>
      ) : (
        <NoResults heading="All Customers" />
      )}
    </>
  );
};

export default AllCustomers;
