import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
const ReusableModel = ({children,view,setView}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
   if(view===true){
    setOpen(true)
   }
  }, [view])
  
  return (
    <>
     
      <Modal
       
        centered
        open={open}
       
        onCancel={() => {
            setOpen(false)
            setView(false)
        
        }}
        footer={null}
        width={1000}
      >
        {children}
      </Modal>
    </>
  );
};
export default ReusableModel;