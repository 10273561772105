import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../components/Button";
import ForgotPassword from "../components/ForgotPassword";
import { toast } from "react-toastify";

//react-icons
import { BiHide, BiShow, BiSolidUser } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";

// logo
import logo from "../assets/logo.png";
import ResetPassword from "../components/ResetPassword";

const Login = () => {
  const navigate = useNavigate();

  const [showForgotPassword, setShowForgotPassword] = useState(false);


  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [showPass, setShowPass] = useState(false);




  console.log({ showForgotPassword, emailValidation, passwordValidation })



  const toggleShowPassword = () => {

    setShowPass(!showPass);
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (email && password) {
      try {
        const response = await axios.post(`${baseUrl}/auth/login`, {
          email: email,
          password: password,
        });

        if (response.status === 200) {
          // toast.success("Login successful!");
          const userData = response.data.data;
          console.log(userData);

          // Set session storage values
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem("userId", userData.id);
          sessionStorage.setItem("userName", userData.name);
          sessionStorage.setItem("authToken", userData.authToken);
          sessionStorage.setItem("userEmail", userData.email);
          sessionStorage.setItem("role", userData.role);

          // Redirect to home page
          navigate("/home");
          window.location.reload();
        } else {
          console.log("Login error");
          setErrorMessage("Invalid credentials. Please try again.");
        }
      } catch (error) {
        console.error("Error during login:", error);
        setErrorMessage(error.response?.data?.error?.message || "An error occurred during login.");
      }
    } else {
      // Handle validation logic if needed
      setEmailValidation(true);
      setPasswordValidation(true);
    }
  };


  return (
    <section className="flex justify-center items-center w-full min-h-screen bg-gray-100/70">
      {!showForgotPassword && (
        <div className=" md:w-[40%] bg-white shadow-xl rounded-md p-8">
          <div className="flex flex-col gap-3 items-center">
            <img
              src={logo}
              alt=""
              width={200}
              height={50}
              className="cursor-pointer"
            />

            <h1 className="text-2xl text-main font-bold">Login</h1>

            <form className="w-full" onSubmit={handleEmailSubmit}>
              <div className="w-full flex flex-col gap-4">
                {/* Email Field */}
                <div className="flex flex-col gap-2">
                  <label htmlFor="email" className="text-gray-800">
                    Email
                  </label>
                  <div className="w-full flex  gap-1 bg-white border border-gray-300">
                    <div className="bg-gray-300 flex items-center px-2 ">
                      <BiSolidUser />
                    </div>
                    <input
                      id="email"
                      type="email"
                      required
                      placeholder="Enter Email"
                      className="outline-none border-none p-1 w-full"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                {email == "" && emailValidation && (
                  <div>
                    <p style={{ color: "red" }}>Email is Required</p>
                  </div>
                )}
                {/* Password Field */}
                <div className="flex flex-col gap-2">
                  <label htmlFor="password" className="text-gray-800">
                    Password
                  </label>
                  <div className="w-full flex  gap-1 bg-white border border-gray-300">
                    <div className="bg-gray-300 flex items-center px-2 ">
                      <FaRegEye />
                    </div>
                    <input
                      id="password"
                      type={showPass ? "text" : "password"}
                      required
                      placeholder="Enter Password"
                      className="outline-none border-none p-1 w-full"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className=" flex items-center px-2 ">

                      {password ? showPass ? (
                        <BiShow
                          className="text-xl cursor-pointer text-main"
                          onClick={toggleShowPassword}
                        />

                      ) : (
                        <BiHide
                          className="text-xl cursor-pointer text-main"
                          onClick={toggleShowPassword}
                        />
                      ) : null}
                    </div>
                  </div>
                  {password == "" && passwordValidation && (
                    <div>
                      <p style={{ color: "red" }}>Password is Required</p>
                    </div>
                  )}
                </div>

                {errorMessage && (
                  <p className="text-red-500 text-sm">{errorMessage}</p>
                )}
                <div className="flex justify-end">
                  <p
                    className="text-xs w-max text-red-500 cursor-pointer"
                    onClick={() => setShowForgotPassword(true)}
                  >
                    Forgot Password
                  </p>
                </div>
                <Button type="submit" text="Submit" />
              </div>
            </form>
          </div>
        </div>
      )}
      <ForgotPassword
        showForgotPassword={showForgotPassword}
        setShowForgotPassword={setShowForgotPassword}
      />
    </section>
  );
};

export default Login;
