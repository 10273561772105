import React, { useEffect, useState } from "react";
import TextInput from "./TextInput";
import Button from "./Button";

//helpers
import { PHOTOS_PRICE, VIDEOS_PRICE } from "../helpers/constants";

import { MdDelete } from "react-icons/md";

const AddPackages = ({
  handleSave,
  handleCancel,
  errorMessage,
  setErrorMessage,
  setSuccessMessage,
  successMessage,
  setFormData,
  formData,
  photographer,
}) => {
  const [babyPackages, setBabyPackages] = useState([]);
  const [birthdayPackages, setBirthdayPackages] = useState([]);
  const [businessPackages, setBusinessPackages] = useState([]);
  const [maternityPackages, setMaternityPackages] = useState([]);
  const [weddingPackages, setWeddingPackages] = useState([]);

  console.log("ad", photographer);

  const handleRemove = (index, category) => {
    if (category === "22") {
      const updatedPackages = [...babyPackages];
      updatedPackages.splice(index, 1);
      setBabyPackages(updatedPackages);
    } else if (category === "24") {
      const updatedPackages = [...birthdayPackages];
      updatedPackages.splice(index, 1);
      setBirthdayPackages(updatedPackages);
    } else if (category === "25") {
      const updatedPackages = [...businessPackages];
      updatedPackages.splice(index, 1);
      setBusinessPackages(updatedPackages);
    } else if (category === "18") {
      const updatedPackages = [...maternityPackages];
      updatedPackages.splice(index, 1);
      setMaternityPackages(updatedPackages);
    } else if (category === "17") {
      const updatedPackages = [...weddingPackages];
      updatedPackages.splice(index, 1);
      setWeddingPackages(updatedPackages);
    }
  };
  
  const handleRemoveService = (index, i, category) => {
    if (category === "22") {
      const updatedPackages = [...babyPackages];
      updatedPackages[index].services.splice(i, 1);
      setBabyPackages(updatedPackages);
    } else if (category === "24") {
      const updatedPackages = [...birthdayPackages];
      updatedPackages[index].services.splice(i, 1);
      setBirthdayPackages(updatedPackages);
    } else if (category === "25") {
      const updatedPackages = [...businessPackages];
      updatedPackages[index].services.splice(i, 1);
      setBusinessPackages(updatedPackages);
    } else if (category === "18") {
      const updatedPackages = [...maternityPackages];
      updatedPackages[index].services.splice(i, 1);
      setMaternityPackages(updatedPackages);
    } else if (category === "17") {
      const updatedPackages = [...weddingPackages];
      updatedPackages[index].services.splice(i, 1);
      setWeddingPackages(updatedPackages);
    }
  };

  
  const addPackage = (category) => {
    const newPackage = {
      name: "",
      services: [],
    };
    if (category === "22") {
      setBabyPackages([...babyPackages, newPackage]);
    } else if (category === "24") {
      setBirthdayPackages([...birthdayPackages, newPackage]);
    } else if (category === "25") {
      setBusinessPackages([...businessPackages, newPackage]);
    } else if (category === "18") {
      setMaternityPackages([...maternityPackages, newPackage]);
    } else if (category === "17") {
      setWeddingPackages([...weddingPackages, newPackage]);
    }
  };

  const addService = (index, category) => {
    if (category === "22") {
      const updatedPackages = [...babyPackages];

      const service = {
        label: "",
        amount: "",
      };

      updatedPackages[index].services.push(service);
      setBabyPackages(updatedPackages);
    } else if (category === "24") {
      const updatedPackages = [...birthdayPackages];

      const service = {
        label: "",
        amount: "",
      };

      updatedPackages[index].services.push(service);

      setBirthdayPackages(updatedPackages);
    } else if (category === "25") {
      const updatedPackages = [...businessPackages];

      const service = {
        label: "",
        amount: "",
      };

      updatedPackages[index].services.push(service);

      setBusinessPackages(updatedPackages);
    } else if (category === "18") {
      const updatedPackages = [...maternityPackages];

      const service = {
        label: "",
        amount: "",
      };

      updatedPackages[index].services.push(service);

      setMaternityPackages(updatedPackages);
    } else if (category === "17") {
      const updatedPackages = [...weddingPackages];

      const service = {
        label: "",
        amount: "",
      };

      updatedPackages[index].services.push(service);

      setWeddingPackages(updatedPackages);
    }
  };

  const handlePackageName = (e, index, category) => {
    if (category === "22") {
      const updatedPackages = [...babyPackages];
      updatedPackages[index].name = e.target.value;
      setBabyPackages(updatedPackages);
    } else if (category === "24") {
      const updatedPackages = [...birthdayPackages];
      updatedPackages[index].name = e.target.value;
      setBirthdayPackages(updatedPackages);
    } else if (category === "25") {
      const updatedPackages = [...businessPackages];
      updatedPackages[index].name = e.target.value;
      setBusinessPackages(updatedPackages);
    } else if (category === "18") {
      const updatedPackages = [...maternityPackages];
      updatedPackages[index].name = e.target.value;
      setMaternityPackages(updatedPackages);
    } else if (category === "17") {
      const updatedPackages = [...weddingPackages];
      updatedPackages[index].name = e.target.value;
      setWeddingPackages(updatedPackages);
    }
  };

  const handleServiceLabel = (e, index, i, category) => {
    if (category === "22") {
      const updatedPackages = [...babyPackages];
      updatedPackages[index].services[i].label = e.target.value;
      setBabyPackages(updatedPackages);
    } else if (category === "24") {
      const updatedPackages = [...birthdayPackages];
      updatedPackages[index].services[i].label = e.target.value;
      setBirthdayPackages(updatedPackages);
    } else if (category === "25") {
      const updatedPackages = [...businessPackages];
      updatedPackages[index].services[i].label = e.target.value;
      setBusinessPackages(updatedPackages);
    } else if (category === "18") {
      const updatedPackages = [...maternityPackages];
      updatedPackages[index].services[i].label = e.target.value;
      setMaternityPackages(updatedPackages);
    } else if (category === "17") {
      const updatedPackages = [...weddingPackages];
      updatedPackages[index].services[i].label = e.target.value;
      setWeddingPackages(updatedPackages);
    }
  };

  const handleServiceAmount = (e, index, i, category) => {
    if (category === "22") {
      const updatedPackages = [...babyPackages];
      updatedPackages[index].services[i].amount = e.target.value;
      setBabyPackages(updatedPackages);
    } else if (category === "24") {
      const updatedPackages = [...birthdayPackages];
      updatedPackages[index].services[i].amount = e.target.value;
      setBirthdayPackages(updatedPackages);
    } else if (category === "25") {
      const updatedPackages = [...businessPackages];
      updatedPackages[index].services[i].amount = e.target.value;
      setBusinessPackages(updatedPackages);
    } else if (category === "18") {
      const updatedPackages = [...maternityPackages];
      updatedPackages[index].services[i].amount = e.target.value;
      setMaternityPackages(updatedPackages);
    } else if (category === "17") {
      const updatedPackages = [...weddingPackages];
      updatedPackages[index].services[i].amount = e.target.value;
      setWeddingPackages(updatedPackages);
    }
  };

  const clearMessagesAndCancel = () => {
    setErrorMessage("");
    setSuccessMessage("");

    handleCancel();
  };

  const savePackages = () => {
    if (typeof babyPackages == "undefined") {
      setBabyPackages([]);
      setBirthdayPackages([]);
      setBusinessPackages([]);
      setMaternityPackages([]);
      setWeddingPackages([]);
      return false;
    }

    const packages = {
      22: [...babyPackages],
      24: [...birthdayPackages],
      25: [...businessPackages],
      18: [...maternityPackages],
      17: [...weddingPackages],
    };

    setFormData((prevData) => ({
      ...prevData,
      package_services: {
        ...prevData.package_services,
        ...packages,
      },
    }));
  };

  useEffect(() => {
    savePackages();
  }, [
    babyPackages,
    birthdayPackages,
    businessPackages,
    maternityPackages,
    weddingPackages,
  ]);

  useEffect(() => {
    setBabyPackages(formData?.package_services?.[22]);
    setBirthdayPackages(formData?.package_services?.[24]);
    setMaternityPackages(formData?.package_services?.[18]);
    setWeddingPackages(formData?.package_services?.[17]);
    setBusinessPackages(formData?.package_services?.[25]);
  }, []);

  return (
    <>
      {errorMessage && (
        <p style={{ color: "red", marginLeft: "10px" }}>{errorMessage}</p>
      )}
      {successMessage && (
        <p style={{ color: "green", marginLeft: "10px" }}>{successMessage}</p>
      )}
      <div className="w-full flex flex-col gap-3">
        {/* Baby Packages*/}
        {photographer?.category?.[22] && (
          <>
            <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
              Baby
            </h2>
            {babyPackages?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col gap-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="border border-gray-300 outline-none p-2 w-full"
                      placeholder="Package Name"
                      name="name"
                      value={babyPackages[index]?.name || ""}
                      onChange={(e) => handlePackageName(e, index, "22")}
                    />
                    <button onClick={() => handleRemove(index, "22")}>
                      <MdDelete className="text-red-500 text-lg" />
                    </button>
                  </div>

                  {item.services?.map((service, i) => {
                    return (
                      <div className="flex gap-3" key={i}>
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-1/4 text-sm"
                          placeholder="Service"
                          value={
                            service.label ||
                            babyPackages[index]?.services[i]?.label
                          }
                          onChange={(e) =>
                            handleServiceLabel(e, index, i, "22")
                          }
                        />
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-3/4 text-sm"
                          placeholder="Amount"
                          value={
                            service.amount ||
                            babyPackages[index]?.services[i]?.amount
                          }
                          onChange={(e) =>
                            handleServiceAmount(e, index, i, "22")
                          }
                        />
                        <button onClick={() => handleRemoveService(index, i, "22")}>
                          <MdDelete className="text-red-500 text-lg" />
                        </button>
                      </div>
                    );
                  })}
                  {item.services.length > 0 && (
                    <div className="text-sm text-end">
                      <b>
                        SubTotal:{" "}
                        {item.services.reduce(
                          (sum, service) => sum + Number(service.amount),
                          0
                        )}
                      </b>
                    </div>
                  )}
                  <div>
                    <Button
                      text="+ Add Service"
                      bg="#27ae60"
                      rounded="md"
                      handleClick={() => addService(index, "22")}
                    />
                  </div>
                </div>
              );
            })}
            <Button
              text="+ Add Package"
              bg="#27ae60"
              rounded="md"
              handleClick={() => addPackage("22")}
            />
          </>
        )}
        {/* Birthday Packages*/}
        {photographer?.category?.[24] && (
          <>
            <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
              Birthday
            </h2>
            {birthdayPackages?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col gap-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="border border-gray-300 outline-none p-2 w-full"
                      placeholder="Package Name"
                      name="name"
                      value={birthdayPackages[index].name}
                      onChange={(e) => handlePackageName(e, index, "24")}
                    />
                    <button onClick={() => handleRemove(index, "24")}>
                      <MdDelete className="text-red-500 text-lg" />
                    </button>
                  </div>

                  {item.services?.map((service, i) => {
                    return (
                      <div className="flex gap-3" key={i}>
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-1/4 text-sm"
                          placeholder="Service"
                          value={
                            service.label ||
                            birthdayPackages[index]?.services[i]?.label
                          }
                          onChange={(e) =>
                            handleServiceLabel(e, index, i, "24")
                          }
                        />
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-3/4 text-sm"
                          placeholder="Amount"
                          value={
                            service.amount ||
                            birthdayPackages[index]?.services[i]?.amount
                          }
                          onChange={(e) =>
                            handleServiceAmount(e, index, i, "24")
                          }
                        />
                        <button onClick={() => handleRemoveService(index, i, "24")}>
                          <MdDelete className="text-red-500 text-lg" />
                        </button>
                      </div>
                    );
                  })}
                  {item.services.length > 0 && (
                    <div className="text-sm text-end">
                      <b>
                        SubTotal:{" "}
                        {item.services.reduce(
                          (sum, service) => sum + Number(service.amount),
                          0
                        )}
                      </b>
                    </div>
                  )}
                  <div>
                    <Button
                      text="+ Add Service"
                      bg="#27ae60"
                      rounded="md"
                      handleClick={() => addService(index, "24")}
                    />
                  </div>
                </div>
              );
            })}
            <Button
              text="+ Add Package"
              bg="#27ae60"
              rounded="md"
              handleClick={() => addPackage("24")}
            />
          </>
        )}

        {/* Business Packages*/}
        {photographer?.category?.[25] && (
          <>
            <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
              Business
            </h2>
            {businessPackages?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col gap-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="border border-gray-300 outline-none p-2 w-full"
                      placeholder="Package Name"
                      name="name"
                      value={businessPackages[index].name}
                      onChange={(e) => handlePackageName(e, index, "25")}
                    />
                    <button onClick={() => handleRemove(index, "25")}>
                      <MdDelete className="text-red-500 text-lg" />
                    </button>
                  </div>

                  {item.services?.map((service, i) => {
                    return (
                      <div className="flex gap-3" key={i}>
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-1/4 text-sm"
                          placeholder="Service"
                          value={
                            service.label ||
                            businessPackages[index]?.services[i]?.label
                          }
                          onChange={(e) =>
                            handleServiceLabel(e, index, i, "25")
                          }
                        />
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-3/4 text-sm"
                          placeholder="Amount"
                          value={
                            service.amount ||
                            businessPackages[index]?.services[i]?.amount
                          }
                          onChange={(e) =>
                            handleServiceAmount(e, index, i, "25")
                          }
                        />
                        <button onClick={() => handleRemoveService(index, i, "25")}>
                          <MdDelete className="text-red-500 text-lg" />
                        </button>
                      </div>
                    );
                  })}
                  {item.services.length > 0 && (
                    <div className="text-sm text-end">
                      <b>
                        SubTotal:{" "}
                        {item.services.reduce(
                          (sum, service) => sum + Number(service.amount),
                          0
                        )}
                      </b>
                    </div>
                  )}
                  <div>
                    <Button
                      text="+ Add Service"
                      bg="#27ae60"
                      rounded="md"
                      handleClick={() => addService(index, "25")}
                    />
                  </div>
                </div>
              );
            })}
            <Button
              text="+ Add Package"
              bg="#27ae60"
              rounded="md"
              handleClick={() => addPackage("25")}
            />
          </>
        )}
        {/* Maternity Packages*/}
        {photographer?.category?.[18] && (
          <>
            <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
              Maternity
            </h2>
            {maternityPackages?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col gap-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="border border-gray-300 outline-none p-2 w-full"
                      placeholder="Package Name"
                      name="name"
                      value={maternityPackages[index].name}
                      onChange={(e) => handlePackageName(e, index, "18")}
                    />
                    <button onClick={() => handleRemove(index, "18")}>
                      <MdDelete className="text-red-500 text-lg" />
                    </button>
                  </div>

                  {item.services?.map((service, i) => {
                    return (
                      <div className="flex gap-3 items-center" key={i}>
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-1/4 text-sm"
                          placeholder="Service"
                          value={
                            service.label ||
                            maternityPackages[index]?.services[i]?.label
                          }
                          onChange={(e) =>
                            handleServiceLabel(e, index, i, "18")
                          }
                        />
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-3/4 text-sm"
                          placeholder="Amount"
                          value={
                            service.amount ||
                            maternityPackages[index]?.services[i]?.amount
                          }
                          onChange={(e) =>
                            handleServiceAmount(e, index, i, "18")
                          }
                        />
                        <button onClick={() => handleRemoveService(index, i, "18")}>
                          <MdDelete className="text-red-500 text-lg" />
                        </button>
                      </div>
                    );
                  })}
                  {item.services.length > 0 && (
                    <div className="text-sm text-end">
                      <b>
                        SubTotal:{" "}
                        {item.services.reduce(
                          (sum, service) => sum + Number(service.amount),
                          0
                        )}
                      </b>
                    </div>
                  )}
                  <div>
                    <Button
                      text="+ Add Service"
                      bg="#27ae60"
                      rounded="md"
                      handleClick={() => addService(index, "18")}
                    />
                  </div>
                </div>
              );
            })}
            <Button
              text="+ Add Package"
              bg="#27ae60"
              rounded="md"
              handleClick={() => addPackage("18")}
            />
          </>
        )}

        {/* Wedding Packages*/}
        {photographer?.category?.[17] && (
          <>
            <h2 className="bg-secondary max-w-[30%] text-white text-center py-2">
              Wedding
            </h2>
            {weddingPackages?.map((item, index) => {
              return (
                <div key={index} className="flex flex-col gap-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="border border-gray-300 outline-none p-2 w-full"
                      placeholder="Package Name"
                      name="name"
                      value={weddingPackages[index].name}
                      onChange={(e) => handlePackageName(e, index, "17")}
                    />
                    <button onClick={() => handleRemove(index, "17")}>
                      <MdDelete className="text-red-500 text-lg" />
                    </button>
                  </div>
                  {item.services?.map((service, i) => {
                    return (
                      <div className="flex gap-3" key={i}>
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-1/4 text-sm"
                          placeholder="Service"
                          value={
                            service.label ||
                            weddingPackages[index]?.services[i]?.label
                          }
                          onChange={(e) =>
                            handleServiceLabel(e, index, i, "17")
                          }
                        />
                        <input
                          type="text"
                          className="p-2 border border-gray-300 outline-none w-3/4 text-sm"
                          placeholder="Amount"
                          value={
                            service.amount ||
                            weddingPackages[index]?.services[i]?.amount
                          }
                          onChange={(e) =>
                            handleServiceAmount(e, index, i, "17")
                          }
                        />
                        <button onClick={() => handleRemoveService(index, i, "17")}>
                          <MdDelete className="text-red-500 text-lg" />
                        </button>
                      </div>
                    );
                  })}
                  {item.services.length > 0 && (
                    <div className="text-sm text-end">
                      <b>
                        SubTotal:{" "}
                        {item.services.reduce(
                          (sum, service) => sum + Number(service.amount),
                          0
                        )}
                      </b>
                    </div>
                  )}
                  <div>
                    <Button
                      text="+ Add Service"
                      bg="#27ae60"
                      rounded="md"
                      handleClick={() => addService(index, "17")}
                    />
                  </div>
                </div>
              );
            })}
            <Button
              text="+ Add Package"
              bg="#27ae60"
              rounded="md"
              handleClick={() => addPackage("17")}
            />
          </>
        )}
      </div>
      <div className="flex gap-2 justify-end">
        <Button text="Cancel" handleClick={clearMessagesAndCancel} />
        <Button text="Approve" handleClick={handleSave} />
      </div>
    </>
  );
};

export default AddPackages;
