import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../components/Button";

const AddEvent = ({ showModal,
    setShowModal,
    btnText,
    handleBtnClick,
    imageURLS,
    setSelectedImages,
    selectedImages,
    errorMessage,
    setErrorMessage
  }) => {
  const [eventCategory, setEventCategory] = useState("");

  const [eventValidation, setEventValidation] = useState(false);
  const [imagesPreview, setImagesPreview] = useState([]);

  const handleImageChange = (e) => {

    const files = Array.from(e.target.files);

    setSelectedImages([]);
    setImagesPreview([]);
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImagesPreview([...imagesPreview, e.target.result]);
        setSelectedImages([...selectedImages, file]);
      }

      reader.readAsDataURL(file);
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setEventCategory("");
    setSelectedImages('');
    setImagesPreview('');
    setErrorMessage('');
  };



  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-1 pb-8 min-w-[80vw] md:min-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Message</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <div className="flex flex-col items-center gap-3 rounded-md w-3/4 m-auto my-3">
              <p className="text-main">Enter Event Category</p>
              <input
                type="text"
                required
                className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full"
                name="eventCategory"
                value={eventCategory}
                onChange={(e) => setEventCategory(e.target.value)}
              />
            </div>
              <div className="flex flex-col items-center gap-1 rounded-md w-3/4 m-auto my-3">
                <h2>Image Upload</h2>
                <input type="file"
                className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full"
                 multiple onChange={handleImageChange} />
                 {imagesPreview && (
                <div className="grid grid-rows-2 grid-flow-col gap-1">
                {imagesPreview?.map((imageSrc) => (
                  <img src={imageSrc} alt="" width={"100"} />
                ))}

                </div>

                 )}
              {errorMessage && (
              <p className="error text-danger d-block" style={{ color: "red" }}>
                {errorMessage}
              </p>
              )}
              </div>

            <div className="text-center">
              <Button
                text={btnText}
                handleClick={() =>
                  handleBtnClick(
                    eventCategory,
                    // setErrorMessage,
                    setEventValidation
                  )
                }
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AddEvent;
