import React from "react";

const Pagination = (props) => {
  const totalPages = Math.ceil(props.total / props.perPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <nav aria-label="..." className="w-full flex justify-center my-4">
      <div className="flex text-sm">
        <button
          className={`${
            props.current <= 1
              ? "text-gray-600 pointer-events-none"
              : "text-secondary"
          } py-[6px] px-3 border border-gray-200`}
          disabled={props.current <= 1}
          onClick={() => props.onPageChange(props.current - 1)}
        >
          Previous
        </button>

        {pageNumbers.map((page) => (
          <button
            key={page}
            className={`${
              props.current === page
                ? "bg-secondary text-white border-secondary"
                : "text-secondary bg-transparent border-[#dee2e6]"
            } py-[6px] px-3 border`}
            onClick={() => props.onPageChange(page)}
          >
            {page}
          </button>
        ))}

        <button
          className={`${
            props.current >= totalPages
              ? "text-gray-600 pointer-events-none"
              : "text-secondary"
          } py-[6px] px-3 border border-[#dee2e6]`}
          disabled={props.current >= totalPages}
          onClick={() => props.onPageChange(props.current + 1)}
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
