import React from "react";
import Button from "./Button";
const AddComment = ({ value, handleChange, handleBtnClick }) => {
  return (
    <div className="w-full my-1">
      <div className="w-full flex items-center border-gray-300 border pl-2 bg-white">
        <input
          type="text"
          value={value}
          onChange={handleChange}
          className="w-full outline-none border-none text-sm"
          placeholder="Add A Comment"
        />
        <Button text="Send" handleClick={handleBtnClick} />
      </div>
    </div>
  );
};

export default AddComment;
