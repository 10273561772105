import React, { useState } from "react";
import EditExclusiveDeal from "../modals/EditExclusiveDeal";

// react-icons
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Confirmation from "../modals/Confirmation";

const ExclusiveDealCard = ({
  id,
  text,
  img,
  handleEdit,
  defaultValues,
  handleDelete,
}) => {
  console.log(img, "KSKSKSLLA");
  const [showEditDeal, setShowEditDeal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const openDeleteModal = (id) => {
    setShowDeleteModal(true);
    setDeleteItemId(id);
  };

  return (
    <>
      <div
        className="relative flex items-center justify-center h-[150px] text-white text-lg rounded-md"
        style={{
          backgroundImage: `url('${img}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
          backdropFilter: "blur(20px)",
        }}
      >
        <div className="absolute inset-0 backdrop-blur-[2px] hover:backdrop-blur-none cursor-pointer transition-all duration-500 ease-out flex items-center justify-center text-center font-semibold ">
          {text}
        </div>
        <button
          className="bg-green-700 p-1 rounded-full absolute top-0 right-0"
          onClick={() => setShowEditDeal(true)}
        >
          <AiFillEdit className="text-gray-100 text-xl" />
        </button>
        <button
          className="bg-red-500 p-1 rounded-full absolute top-0 right-10"
          onClick={() => openDeleteModal(id)}
        >
          <AiFillDelete className="text-white text-lg" />
        </button>
      </div>
      <EditExclusiveDeal
        showModal={showEditDeal}
        setShowModal={setShowEditDeal}
        handleSave={(content) => handleEdit(content)}
        defaultValues={defaultValues}
      />

      <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        heading="Are you sure?"
        text="Are you sure you want to Delete?"
        btnText="Delete"
        handleConfirm={() => handleDelete(deleteItemId)}
      />
    </>
  );
};

export default ExclusiveDealCard;
