import "./App.css";
import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useMatch,
} from "react-router-dom";
// import { useLocation } from "react-router-dom";
//components
import Navbar from "./components/Navbar";

import Login from "./pages/Login";

// layout
import Rootlayout from "./layout/Rootlayout";
import Pageslayout from "./layout/PagesLayout";

// pages
import UpcomingEvent from "./pages/UpcomingEvent";
import InProgressEvent from "./pages/InProgressEvent";
import CancelledEvents from "./pages/CancelledEvents";
import PastEvent from "./pages/PastEvent";
import EventTimeline from "./pages/EventTimeline";
import PastEventFootages from "./pages/PastEventFootages";
import UserManagement from "./pages/UserManagement";
import RegistrationPending from "./pages/RegistrationPending";
import Home from "../src/pages/Home";
import { SessionProvider } from "./components/SessionContext";
import CallRecording from "./pages/CallRecording";
import PhotographerManagement from "./pages/PhotographerManagement";
import TermsConditions from "./pages/TermsConditions";
import ViewDetails from "./pages/ViewDetails";
import AllCustomers from "./pages/AllCustomers";
import SearchHistory from "./pages/SearchHistory";
import ManageCategories from "./pages/ManageCategories";
import AddEventCategory from "./pages/AddEventCategory";
import Faqs from "./pages/Faqs";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AllPhotographers from "./pages/AllPhotographers";
import MyProfile from "./pages/MyProfile";
import ChangePassword from "./pages/ChangePassword";
import CouponCodeGenerator from "./pages/CouponCodeGenerator";
import ExclusiveDeals from "./pages/ExclusiveDeals";
import AdminHomepage from "./pages/AdminHomepage";
import AddStates from "./pages/AddStates";
import RequestedEvent from "./pages/RequestedEvent";
import PhotographerDetails from "./pages/PhotographerDetails";
import CancellationPolicy from "./pages/CancellationPolicy";
import CustomerChats from "./pages/CustomerChats";
import ChatPage from "./pages/ChatPage";
import ChangeEventDate from "./pages/ChangeEventDate";
import ChangeEventCalendar from "./pages/ChangeEventCalendar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhotographerCustomerChats from "./pages/PhotographerCustomerChats";
import PhotographerCustomerChatPage from "./pages/PhotographerCustomerChatPage";
import ApproveFootage from "./pages/ApproveFootage";
import CustomerFiles from "./pages/CustomerFiles";

const ProtectedRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/" />;
};

function App() {
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") === "true"
  );
  // const location = useLocation();
  const SuperAdmin = sessionStorage.getItem("role") === "SUPER_ADMIN";

  // const [showSidebar, setShowSidebar] = useState(true); // Default value, modify as needed

  // useEffect(() => {
  //   setShowSidebar(location.pathname !== '/');
  // }, [location]);
  return (
    <>
      <SessionProvider>
        <Router>
          <Navbar />
          <Rootlayout>
            <Pageslayout>
              <Routes>
                <Route
                  exact
                  path="/home"
                  element={
                    <ProtectedRoute
                      element={SuperAdmin ? <Home /> : <AdminHomepage />}
                      isAuthenticated={loggedIn}
                    />
                  }
                />
                <Route exact path="/my-profile" element={<MyProfile />} />

                <Route
                  exact
                  path="/change-password"
                  element={<ChangePassword />}
                />

                <Route
                  exact
                  path="/requested-event"
                  element={<RequestedEvent />}
                />

                <Route
                  exact
                  path="/upcoming-event"
                  element={<UpcomingEvent />}
                />
                <Route
                  exact
                  element={<CustomerFiles />}
                  path="/my-footage/:id"
                />
                <Route
                  exact
                  path="/approve-footage"
                  element={<ApproveFootage />}
                />
                <Route
                  exact
                  path="/cancelled-event"
                  element={<CancelledEvents />}
                />

                <Route
                  exact
                  path="/event-timeline/:id"
                  element={<EventTimeline />}
                />
                <Route
                  exact
                  path="/in-progress-event"
                  element={<InProgressEvent />}
                />
                <Route exact path="/past-event" element={<PastEvent />} />
                <Route
                  exact
                  path="/past-event/:id"
                  element={<PastEventFootages />}
                />

                <Route
                  exact
                  path="/exclusive-deals"
                  element={<ExclusiveDeals />}
                />

                <Route
                  exact
                  path="/call-recording"
                  element={<CallRecording />}
                />

                <Route
                  exact
                  path="/manage-categories"
                  element={<ManageCategories />}
                />

                {/* <Route
                  exact
                  path="/add-event-category"
                  element={<AddEventCategory />}
                /> */}

                <Route
                  exact
                  path="/customer-chats"
                  element={<CustomerChats />}
                />

                <Route
                  exact
                  path="/customer-chats/:id"
                  element={<ChatPage />}
                />

                <Route
                  exact
                  path="/photographer-customer-chats"
                  element={<PhotographerCustomerChats />}
                />

                <Route
                  exact
                  path="/photographer-customer-chats/:id"
                  element={<PhotographerCustomerChatPage />}
                />

                <Route exact path="/about-us" element={<AboutUs />} />

                <Route
                  exact
                  path="/photographer-management"
                  element={<PhotographerManagement />}
                />
                <Route
                  exact
                  path="/registration-pending"
                  element={<RegistrationPending />}
                />

                <Route
                  exact
                  path="/registration-pending/view-details/:slug"
                  element={<ViewDetails />}
                />

                <Route exact path="/all-customers" element={<AllCustomers />} />

                <Route
                  exact
                  path="/search-history"
                  element={<SearchHistory />}
                />

                <Route
                  exact
                  path="/all-photographers"
                  element={<AllPhotographers />}
                />

                <Route
                  exact
                  path="/all-photographers/view-details/:slug"
                  element={<ViewDetails />}
                />

                <Route
                  exact
                  path="/terms-conditions"
                  element={<TermsConditions />}
                />
                <Route
                  exact
                  path="/cancellation-policy"
                  element={<CancellationPolicy />}
                />

                <Route exact path="/faqs" element={<Faqs />} />

                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                />

                <Route exact path="/add-states" element={<AddStates />} />

                <Route
                  exact
                  path="/change-events-date"
                  element={<ChangeEventDate />}
                />

                <Route
                  exact
                  path="/change-events-date/:id"
                  element={<ChangeEventCalendar />}
                />

                <Route
                  exact
                  path="/user-management"
                  element={<UserManagement />}
                />

                <Route
                  exact
                  path="/generate-coupon"
                  element={<CouponCodeGenerator />}
                />
                <Route
                  exact
                  path="/"
                  element={loggedIn ? <Navigate to="/home" /> : <Login />}
                />
              </Routes>
            </Pageslayout>
          </Rootlayout>
        </Router>
      </SessionProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
