import React, { useState } from "react";
import axios from "axios";

const PasswordModal = ({
  adminId,
  email,
  visible,
  setVisible,
  onConfirm,
  setError,
  removeAdmin,
}) => {
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const confirmPassword = async () => {
    if (password.length > 0) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/login`,
          {
            email: email,
            password: password,
          }
        );

        setVisible(false);
        onConfirm();

        if (response.status === 200 && removeAdmin) {
          removeAdmin(adminId);
        }
      } catch (error) {
        setError("Incorrect password. Please try again.");
        setIsPasswordValid(false);
        console.error("Authentication error:", error);
      }
    } else {
      setIsPasswordValid(false);
    }
  };

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center z-50 ${
        visible ? "" : "hidden"
      }`}
    >
      <div className="bg-black/50 absolute w-full h-full top-0"></div>

      <div className="fixed z-20 p-3 w-full max-w-sm bg-bgMain rounded-lg">
        <div className="flex justify-center items-center bg-secondary p-2 rounded-t-md">
          <p className="text-white">Confirm Password</p>
          <button
            className="absolute right-2 text-white text-xl cursor-pointer"
            onClick={() => setVisible(false)}
          >
            ✕
          </button>
        </div>

        <div className="flex flex-col gap-1 rounded-md w-full m-auto">
          <p className="text-gray-500 text-sm min-w-max">Password</p>
          <input
            type="password"
            className={`resize-none border ${
              !isPasswordValid ? "border-red-500" : "border-gray-300"
            } bg-white p-2 outline-none text-sm w-full`}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setIsPasswordValid(true);
            }}
          />
          {!isPasswordValid && (
            <p className="text-red-500 text-sm">
              Incorrect password. Please try again.
            </p>
          )}
        </div>

        <div className="flex justify-end mt-4">
          <button
            className="bg-gray-300 text-black px-4 py-2 ml-2 rounded-md"
            onClick={confirmPassword}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
