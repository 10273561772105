import React, { useState } from "react";
import EditModal from "../modals/EditModal";
// react-icons
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Confirmation from "../modals/Confirmation";

const CRUDCard = ({
  imgInput,
  defaultValue,
  heading,
  paragraph,
  handleEdit,
  handleDelete,
  showDeleteButton = true,
  // showEditModal,
  // setShowEditModal
  
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };
  return (
    <>
      <div className="relative flex flex-col gap-3 bg-white shadow-md rounded-md p-4">
        <h2 className="text-main text-lg font-bold">{heading}</h2>
        <p className="text-sm">
          {paragraph.slice(0, 300)}
          {paragraph.length > 300 ? "..." : ""}
        </p>

        <div className="absolute -top-[10px] right-0 flex items-center gap-3">
          <button
            className="bg-green-700 p-1 rounded-full"
            onClick={() => setShowEditModal(true)}
          >
            <AiFillEdit className="text-gray-100 text-xl" />
          </button>
       {showDeleteButton && (
          <button
            className="bg-red-700 p-1 rounded-full"
            onClick={() => setShowDeleteModal(true)}
          >
            <MdDelete className="text-gray-100 text-xl" />
          </button>
       )}
          
        </div>
      </div>
      <EditModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        handleBtnClick={async (content) => {
          const success = await handleEdit(content);
          console.log(success)
          if (success) {
            handleCloseEditModal();
          }
        }}
        defaultValue={defaultValue}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        setErrorMessage={setErrorMessage}
        imgInput={imgInput}
      />
      <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        handleConfirm={handleDelete}
        heading="Are you sure?"
        text="Are you sure you want to Delete?"
        btnText="Delete"
      />
    </>
  );
};

export default CRUDCard;
