import React from "react";
import { useLocation } from "react-router-dom";
const PagesLayout = ({ children }) => {
  const pathname = useLocation().pathname;

  if (pathname === "/") {
    return <>{children}</>;
  }

  return <section className={`p-5 bg-bgMain min-h-screen`}>{children}</section>;
};

export default PagesLayout;
