import React, { useEffect, useState } from "react";
import PieChart from "../components/PieChart";

//helpers
import {
  PIE_CHART_EVENT_LABELS,
  PIE_CHART_EVENT_DATA,
} from "../helpers/constants";
import HomeCard from "../components/HomeCard";
import { IoIosMail } from "react-icons/io";
import axios from "axios";


const AdminHomepage = () => {
  const [dashboardData, setDashboardData] = useState({})
  const token = sessionStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  console.log(dashboardData, "dashboarddDataa")

  const headers = {
    "x-auth-token": token, 
  };


  const fetchDashboard = async () => {
    try {
      const data = await axios.get(`${baseUrl}/dashboard/admin-analytics`, { headers });
      console.log(data, "dataaaaaadas")
      setDashboardData(data.data.data)
      // return data.data;
    } catch (error) {
      console.log(error)
    }
  }

  const pieChartData = [dashboardData?.events?.upcoming, dashboardData?.events?.ongoing
  ]
  console.log({ pieChartData })
  useEffect(() => {
    try {
      fetchDashboard()
    } catch (error) {
      console.log(error);
    }
  }, [])
  return (
    <section className="w-full overflow-hidden">
      <div className="grid md:grid-cols-2 gap-5 items-center">
        <PieChart
          dataLabels={PIE_CHART_EVENT_LABELS}
          chartData={pieChartData}
          label="events"
          title="Events"
        />
        <HomeCard href="/" text="Footage Review Pending" number={dashboardData?.footageReviewPending} />
        <div className="relative flex items-center justify-center">
          <IoIosMail className="text-[300px] text-[#2d8bba]" />
          <div className="absolute top-[15%] right-[8%] md:right-[20%] w-[50px] h-[50px] flex items-center justify-center bg-red-500 rounded-full font-bold text-white">
            {dashboardData?.messageCount
            }
          </div>
        </div>

        <HomeCard
          href="/registration-pending"
          text="Photographer Registration pending"
          number={dashboardData?.photgrapherPendingCount
            }
        />
      </div>
    </section>
  );
};

export default AdminHomepage;
