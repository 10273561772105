import React, { useState } from "react";
import Button from "../components/Button";

//react-icons
import { BiSolidUser } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";

// logo
import logo from "../assets/logo.png";

const ResetPassword = ({ showResetPassword, setShowResetPassword }) => {
  const handleResetPassword = () => {};

  return (
    <>
      {showResetPassword && (
        <div className=" md:w-[40%] bg-white shadow-xl rounded-md p-8">
          <div className="flex flex-col gap-3 items-center">
            <img
              src={logo}
              alt=""
              width={200}
              height={50}
              className="cursor-pointer"
            />

            <h1 className="md:text-2xl text-main font-bold">Reset Password</h1>

            <form className="w-full" onSubmit={handleResetPassword}>
              <div className="w-full flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <label htmlFor="password" className="text-gray-800">
                    Enter New Password
                  </label>
                  <div className="w-full flex  gap-1 bg-white border border-gray-300">
                    <div className="bg-gray-300 flex items-center px-2 ">
                      <FaRegEye />
                    </div>
                    <input
                      id="password"
                      type="password"
                      required
                      placeholder="Enter New Password"
                      className="outline-none border-none p-1 w-full"
                    />
                  </div>
                </div>

                <Button type="submit" text={"Reset Password"} />
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
