import React from "react";

// react-icons
import { BiSearch } from "react-icons/bi";

const SearchBar = ({ onSearch, value, onChange }) => {
  return (
    <form className="w-full flex justify-end my-3" onSubmit={onSearch}>
      <div className="flex justify-between items-center gap-1 p-2 border-gray-400 rounded-sm border bg-white">
        <input
          type="text"
          placeholder="Search"
          className="border-none outline-none w-full text-sm text-gray-500"
          value={value}
          onChange={onChange}
        />
        <button type="submit">
          <BiSearch className="text-lg text-gray-400" />
        </button>
      </div>
    </form>
  );
};

export default SearchBar;
