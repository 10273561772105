import React, { useEffect, useState } from "react";

//components
import Button from "../components/Button";

// react-icons
import { RxCross1 } from "react-icons/rx";

const GenerateCoupon = ({ showModal, setShowModal, text }) => {
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-1 pb-8 min-w-[80vw] max-w-[80vw] md:min-w-[40vw] md:max-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Message</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <p className="text-gray-800 flex items-center text-center w-[80%] m-auto min-h-[110px]">
              {text}
            </p>

            <div className="flex justify-center">
              <Button text="Ok" handleClick={() => setShowModal(false)} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default GenerateCoupon;
