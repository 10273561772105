import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Button from "../components/Button";

// Assets
import ExampleProfile from "../assets/profile.png";

//helpers
import { CUSTOMER_CHATS } from "../helpers/constants";
import ChatMessage from "../components/ChatMessage";
import { readMessageAndUpdateStatus, sendMessage } from "../Utils/socket";
import { getTimeWithFormat } from "../Utils/utils";
import { getSocket } from "../Utils/socket";
import { useSession } from "../components/SessionContext";

// this is a dynamic chat page of user with admin
// example url of this page: http://localhost:3000/customer-chats/1

const ChatPage = () => {
  const [loading, setLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const msgInputRef = useRef();
  const params = useParams();
  const location = useLocation();
  const { data: customer } = location.state;
  const { socket: newSocket } = useSession();
  let socket = getSocket();
  //   const [chats, setChats] = useState(CUSTOMER_CHATS);

  console.log(newSocket, "==newSocketnewSocket", socket);

  useEffect(() => {
    socket = getSocket();
    setLoading(true);
    readMessageAndUpdateStatus({ userId: params.id }, (data) => {
      console.log(data, "msg data of userId = 178");
      if(data.success){
        setChats(data.data);
      }
      setLoading(false);
    });

    newSocket?.on('new_message', (data) => {
      console.log('New Message Rec: ', data);
      setChats(prev => ([...prev, data]));
    });
    // let chat = CUSTOMER_CHATS.find((item) => item.id == params.id);
    // console.log(CUSTOMER_CHATS.find((item) => item.id == params.id));
    // setCustomer(chat);
    // console.log(customer.chats);
  }, [socket]);

  const handleMsgSend = () => {
    const msg = msgInputRef.current.value
    const dataToBeSend = {
      message: {
        text: msg,
        messageType: 'text',
      },
      isForAdmin: true,
      receiverId: params.id,
      // senderId: -1,
    }
    // console.log(dataToBeSend, "datataataTobe");
    setChats(prev => ([...prev, { isForAdmin: true, receiverId: params.id, message: msg, messageType: 'text' }]));
    sendMessage(dataToBeSend, (ackData) => { console.log(ackData, "sendMessage:acknowledged") });
    msgInputRef.current.value = "";
  }

  return (
    <section className="w-full h-screen overflow-auto bg-white p-3">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex items-center justify-between border-b-[1px] border-b-gray-300 py-1">
            <div className="flex items-center gap-3">
              <img
                src={
                  customer?.profilePic ? customer?.profilePic : ExampleProfile
                }
                alt="profile"
                width={50}
                height={50}
                className="rounded-full"
              />
              <p className="text-gray-700 font-semibold">
                {customer?.name}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-xs text-gray-600">
                <b>Email: </b>
                {customer?.email}
              </p>
              <p className="text-xs text-gray-600">
                <b>Phone: </b>
                {customer?.phone ?? "+91XXXXXXXXX"}
              </p>
            </div>
          </div>

          {loading ? (<div>
            Loading...
          </div>) : (<div className="flex flex-col gap-3 my-4">
            {chats.length > 0 ? chats?.map((item, index) => (
              <ChatMessage
                key={index}
                profilePic={customer.profilePic}
                msg={item.message}
                role={customer.id === item.senderId ? "customer": "admin"}
                time={getTimeWithFormat(item.createdAt)}
              />
            )): "No Chat Found" }
          </div>)}
        </div>
        <div className="flex justify-between border py-1 px-2 border-gray-300 focus:border-gray-500">
          <input
            type="text"
            placeholder="Write a message..."
            className="outline-none w-full"
            ref={msgInputRef}
          />
          <Button handleClick={handleMsgSend} text="Send" rounded="md" />
        </div>
      </div>
    </section>
  );
};

export default ChatPage;
