import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCross1 } from "react-icons/rx";
import { AiFillHeart } from "react-icons/ai";
import axios from "axios";
import { useParams } from "react-router-dom";

const MorePhotos = ({
  showModal,
  setShowModal,
  morePhotos,
  categoryId,
  favPhoto,
}) => {
  const [favoritePhoto, setFavoritePhoto] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const token = sessionStorage.getItem("authToken");
  const { slug } = useParams(); // Extract the 'slug' parameter from the URL

  const addToFavorite = (index, imageUrl) => {
    setFavoritePhoto(index);
    setSelectedImageUrl(imageUrl);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (selectedImageUrl) {
      sendFavoriteRequest();
    }
  }, [selectedImageUrl]);

  const sendFavoriteRequest = () => {
    if (selectedImageUrl) {
      const data = {
        photographerId: Number(slug), // Convert the 'slug' to a number
        imageUrl: selectedImageUrl, // Use selectedImageUrl
        categoryId: categoryId,
      };

      const headers = {
        "x-auth-token": token, // Replace with your actual token
      };
      axios
        .post(`${process.env.REACT_APP_URL}/fav-pic/create`, data, {
          headers,
        })
        .then((response) => {
          console.log("api send Favorite photo added:", response.data);
          toast.success("Added to favorites!");
        })
        .catch((error) => {
          console.error("Error adding favorite photo:", error);
          console.log("Response data:", error.response.data);
          console.log("Response status:", error.response.status);
          console.log("Response headers:", error.response.headers);
        });
    }
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-[10%] z-20 flex flex-col gap-1 pb-8 min-w-[80vw] md:min-w-[60vw] md:max-w-[60vw] md:max-h-[500px] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">More Photos</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            {/* <div className="flex flex-wrap gap-3 justify-center items-center min-h-[300px] overflow-auto py-5"> */}
            <div className="grid grid-cols-3 gap-3 min-h-[300px] overflow-auto p-5">
              {morePhotos?.image?.map((item, index) => {
                const isFavorite = favPhoto.some(
                  (fav) => fav.categoryId == categoryId && fav.imageUrl == item
                );

                return (
                  <div key={index} className="relative">
                    <img
                      key={index}
                      src={item}
                      alt=""
                      className="w-full"
                      onDoubleClick={() => addToFavorite(index, item)}
                    />

                    {!favoritePhoto && isFavorite && (
                      <AiFillHeart className="absolute top-[-10px] right-[-10px] text-red-600 text-4xl" />
                    )}
                    {favoritePhoto === index && (
                      <AiFillHeart className="absolute top-[-10px] right-[-10px] text-red-600 text-4xl" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MorePhotos;
