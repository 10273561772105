import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextDetail from "../components/TextDetail";
import CheckBoxDetail from "../components/CheckBoxDetail";
import ImageDetail from "../components/ImageDetail";

//react-icons
import { BiArrowBack } from "react-icons/bi";

// helpers
import {
  TYPE_OF_PHOTOGRAPHY,
  TYPE_OF_VIDEOGRAPHY,
  OCCASSION,
  WEDDING_PHOTOS_VIDEOS,
  MATERNITY_PHOTOS_VIDEOS,
  NEW_BORN_PHOTOS_VIDEOS,
  BIRTHDAY_PHOTOS_VIDEOS,
  BUSINESS_PHOTOS_VIDEOS,
} from "../helpers/constants";

const PhotographerDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <section className="flex flex-col gap-8 p-4">
      <div>
        <button
          className="flex items-center gap-1 bg-secondary rounded-md text-white text-sm py-1 px-3"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack /> <span>Back</span>
        </button>
      </div>
      <TextDetail label="Name" value="Ibrahim Khan" required={true} />
      <TextDetail
        label="Contact Number"
        value="+91 9876543210"
        required={true}
      />
      <TextDetail label="E-Mail ID" value="abc@xml.com" required={true} />
      <TextDetail
        label="Studio Name"
        value="Photowale Studio"
        required={true}
      />
      <TextDetail label="Service Location" value="Bengaluru" required={true} />
      <TextDetail
        label="Experience in photography"
        value="In Years"
        required={true}
      />
      <TextDetail
        label="Languages Known"
        value="English, Hindi"
        required={true}
      />
      <CheckBoxDetail
        label="Type of Photography"
        value={TYPE_OF_PHOTOGRAPHY}
        required={true}
      />
      <CheckBoxDetail
        label="Type of Videography"
        value={TYPE_OF_VIDEOGRAPHY}
        required={true}
      />
      <CheckBoxDetail label="Occasion" value={OCCASSION} required={true} />
      <ImageDetail
        label="Wedding Photos and Videos"
        required={false}
        value={WEDDING_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="Maternity Photos and Videos"
        required={false}
        value={MATERNITY_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="New Born Photos and Videos"
        required={false}
        value={NEW_BORN_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="Birthday Photos and Videos"
        required={false}
        value={BIRTHDAY_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="Business Photos and Videos"
        required={false}
        value={BUSINESS_PHOTOS_VIDEOS}
      />
      <TextDetail label="Willing to Travel" value="Yes" required={true} />
      <TextDetail
        label="Which State You can travel"
        value="All India"
        required={true}
      />
      <TextDetail label="Delivery Time" value="30 - 60 days" required={true} />
      <TextDetail
        label="Are you an individual/ a team"
        value="Team"
        required={true}
      />
      <TextDetail
        label="Number of members in the team"
        value="20"
        required={true}
      />
      <TextDetail
        label="Business google link"
        value="https://www.google.com/test/photowalestudio/"
        required={true}
      />
      <TextDetail
        label="Any highlights about your services"
        value="I can provide edited footages in 5 days."
      />
      <TextDetail
        label="About You"
        value="I am a passionate photographer capturing life's essence through my lens. With an eye for detail and a heart for emotions, I freeze moments in time. Each frame tells a story, each click preserves a memory. Let's embark on this visual journey together."
        required={true}
      />
      <TextDetail
        label="Any Additional Info you would like to provide us ?"
        value="With a decade of experience, I specialize in portrait and landscape photography. My work has been featured in renowned publications, showcasing my unique blend of artistry and technical skill. I thrive on capturing fleeting emotions and transforming them into timeless images. Let's create lasting memories together."
      />
    </section>
  );
};

export default PhotographerDetails;
