import React, { useState, useEffect } from "react";
import HorizontalTab from "../components/HorizontalTab";
import HorizontalTabsLayout from "../layout/HorizontalTabsLayout";
import UserManagementTabs from "../components/UserManagementTabs";
import Pagination from "../components/Pagination";
import AddEvent from "../modals/AddEvent";
import RemoveUser from "../modals/RemoveUser";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "../components/DataTable";

const SEARCH_SUGGESTIONS = [
  "123@boopho.com",
  "1298.test@boopho.com",
  "Rahul.12@boopho.com",
];

const AddEventCategory = () => {
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [showRemoveAdmin, setShowRemoveAdmin] = useState(false);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("authToken"); // Replace with your actual token

  const [data, setData] = useState([]); // State to store the fetched data
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  console.log(data);
  const addEvent = async (
    eventCategory,
    setEventValidation,
    setErrorMessage
  ) => {
    console.log(eventCategory);
    if (!eventCategory.trim()) {
      // Check if the eventCategory is empty or contains only whitespace
      setEventValidation(true); // Set a validation flag to indicate an error
      setErrorMessage("Event category is required."); // Set an error message
      return; // Exit the function, don't proceed with the API call
    }

    try {
      const headers = {
        "x-auth-token": token,
      };
      const response = await axios.post(
        `${baseUrl}/user/event-category`,
        {
          name: eventCategory,
          media: "",
        },
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log(response);
        setShowAddEvent(false);
        setSuccessMessage("Category created successfully");
      } else {
        setErrorMessage("Unable to add category. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error while adding admin:",
        error.response.data.error.message
      );
      setErrorMessage(error.response.data.error.message);
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    // Define the API URL
    const apiUrl = `${process.env.REACT_APP_URL}/user/event-categories`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    axios
      .get(apiUrl, {
        // params: params,
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        // Handle the successful response here
        setData(response.data.data.rows);
        setTotalItems(response.data.data.count); // Set the total number of items
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  });

  const removeAdmin = (email) => {
    window.confirm(`Are you sure you want to remove ${email}`);
    console.log(`${email} admin removed`);

    const apiUrl = `${baseUrl}/user/admin/${email}`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };
    // Make a GET request to the API
    axios
      .delete(apiUrl, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        // Handle the successful response here
        setShowRemoveAdmin(false);
        console.log("admin deleted successfully", response);
        setSuccessMessage("User Deleted Successfully");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  };

  return (
    <section className="w-full">
      <UserManagementTabs
        masterUser="admin@boopho.com"
        firstBtnText={"Add Event Category"}
        firstBtnHandleClick={() => setShowAddEvent(true)}
        secondBtnText={"Remove Event Category"}
        secondBtnHandleClick={() => setShowRemoveAdmin(true)}
      />

      <AddEvent
        showModal={showAddEvent}
        setShowModal={setShowAddEvent}
        btnText="Create Event Category"
        handleBtnClick={addEvent}
      />

      <RemoveUser
        showModal={showRemoveAdmin}
        setShowModal={setShowRemoveAdmin}
        btnText="Remove Category"
        handleBtnClick={removeAdmin}
        allUsers={SEARCH_SUGGESTIONS}
      />

      <p className="text-2xl text-main font-bold my-5">
        Event Category Listing
      </p>
      {successMessage && (
        <div>
          <p
            style={{
              backgroundColor: "#51c151",
              width: "300px",
              width: "362px",
              padding: "15px",
              borderRadius: " 5px",
              marginBottom: "20px",
            }}
          >
            {successMessage}
          </p>
        </div>
      )}

      <DataTable data={data} removeAdmin={removeAdmin} />

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />
    </section>
  );
};

export default AddEventCategory;
