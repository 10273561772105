// Faqs.js
import React, { useState, useEffect } from 'react';
import CRUDCard from '../components/CRUDCard';
import Pagination from '../components/Pagination';
import Button from '../components/Button';
import { MdAdd } from 'react-icons/md';
import AddModal from '../modals/AddModal';
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import { getAllFaqs, createFaq, updateFaq, deleteFaq } from '../api';
import './message.css';
import NoResults from '../components/NoResults';

const Faqs = () => {
  const [showAddFaq, setShowAddFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()


  const [formData, setFormData] = useState({
    heading: '',
    paragraph: '',
  });

  const token = sessionStorage.getItem('authToken');

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchFaqs();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }


  const fetchFaqs = () => {
    getAllFaqs(token, resultsPerPage, currentPage, searchQuery, order)
      .then((response) => {
        const data = response.data;
        console.log('faq', response);
        setFaqs(data.rows);
        setTotalResults(data.count);
      })
      .catch((error) => {
        console.error('Error fetching FAQs:', error);
      });
  };

  useEffect(() => {
    fetchFaqs();
  }, [token,currentPage,order,resultsPerPage]);

  const handleEdit = (id, content) => {
    return new Promise((resolve, reject) => {
      const { heading: question, paragraph: answer } = content;

      if (!question.trim() || !answer.trim()) {
        return;
      }

      updateFaq(token, id, question, answer)
        .then(() => {
          setSuccessMessage('FAQ updated successfully.');
          setShowAddFaq(false);
          fetchFaqs();
          resolve(true);
        })
        .catch((error) => {
          console.error('Error updating FAQ:', error);
          reject(false);
        });
    });
  };

  const handleDelete = (id) => {
    deleteFaq(token, id)
      .then(() => {
        setSuccessMessage('FAQ deleted successfully.');
        console.log('FAQ deleted successfully.');
        fetchFaqs();
      })
      .catch((error) => {
        console.error('Error deleting FAQ:', error);
      });
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const confirmDelete = () => {
    if (selectedFaqId) {
      deleteFaq(token, selectedFaqId)
        .then(() => {
          console.log('FAQ deleted successfully.'); // Add this log
          setSuccessMessage('FAQ deleted successfully.'); // Setting success message here
          setSelectedFaqId(null);
          fetchFaqs();
        })
        .catch((error) => {
          console.error('Error deleting FAQ:', error);
          setSelectedFaqId(null);
        });
    }
  };


  const handleAddFaq = () => {
    const { heading, paragraph } = formData;

    if (!heading.trim() || !paragraph.trim()) {
      return;
    }

    createFaq(token, heading, paragraph)
      .then(() => {
        setSuccessMessage('FAQ added successfully.');
        fetchFaqs();
        setShowAddFaq(false);
        setFormData({
          heading: '',
          paragraph: '',
        });
      })
      .catch((error) => {
        console.error('Error adding FAQ:', error);
      });
  };

  const clearMessages = () => {
    setSuccessMessage('');
    setErrorMessage(''); // Clear error message
  };

  return (
  <>
  {faqs.length > 0 ? (
      <section>
      <SearchAndFilterPanel
         onSearch={onSearch}
         searchQuery={searchQuery}
         onChangeSearch={onChangeSearch}
         handleOders={handleOders}
      />
    {successMessage && <div className="success-message">{successMessage}</div>}
    {/* Remove the condition to always show the error message */}
    <div className="flex flex-col md:flex-row justify-between">
      <h1 className="text-2xl md:text-3xl text-main font-bold">Faqs</h1>
      <Button
        text="Add New Faq"
        icon={<MdAdd className="text-lg" />}
        rounded="md"
        bg="green"
        handleClick={() => {
          setShowAddFaq(true);
          clearMessages();
        }}
      />
    </div>

    {faqs.length === 0 && <div className="no-results-message">No data found.</div>}

    <div className="w-full grid my-4 gap-5">
      {faqs.map((item, index) => (
        <CRUDCard
          key={index}
          heading={item.question}
          paragraph={item.answer}
          handleEdit={(content) => handleEdit(item.id, content)}
          handleDelete={() => handleDelete(item.id)}
          defaultValue={{
            heading: item.question,
            paragraph: item.answer,
          }}
          imgInput={false}
        />
      ))}
    </div>
    <AddModal
      showModal={showAddFaq}
      setShowModal={setShowAddFaq}
      imgInput={false}
      handleBtnClick={handleAddFaq}
      formData={formData}
      setFormData={setFormData}
      validationErrors={validationErrors}
      setValidationErrors={setValidationErrors} // Pass setValidationErrors here
      setErrorMessage={setErrorMessage} // Also, pass setErrorMessage if needed
    />

    <Pagination
      total={totalResults}
      perPage={resultsPerPage}
      current={currentPage}
      onPageChange={handlePageChange}
    />
    {selectedFaqId && (
      <div className="confirmation-modal">
        <p>Are you sure you want to delete this FAQ?</p>
        <button onClick={confirmDelete}>Yes</button>
        <button onClick={() => setSelectedFaqId(null)}>No</button>
      </div>
    )}
  </section>
  ) : (
    <NoResults heading="Faqs" />

  )}
  </>
  );
};

export default Faqs;
