import React, { useEffect, useState } from "react";
import CRUDCard from "../components/CRUDCard";
import Pagination from "../components/Pagination";
import Button from "../components/Button";

//react-icons
import { MdAdd } from "react-icons/md";

// modals
import AddModal from "../modals/AddModal";

import axios from "axios";
import NoResults from "../components/NoResults";

const PrivacyPolicy = () => {
  const [showAddPolicy, setShowAddPolicy] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = sessionStorage.getItem('authToken');
  const [formData, setFormData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  // const [showEditModal, setShowEditModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleEdit = (content, id) => {
    console.log(content, id);
    return new Promise((resolve, reject) => {
      if (content.heading && content.paragraph) {
        const apiUrl = `${baseUrl}/content`;
        const headers = {
          "x-auth-token": authToken, // Replace with your actual token
        };
  
        const jsonData = {
          "page": "privacy_policy",
          "data": [
            ...(privacyPolicy || []).map((item, index) => ({
              'key': index,
              'title': item.title,
              'description': item.description
            }))
          ]
        };
        const indexToUpdate = jsonData.data.findIndex((item) => item.key === id);
  
        if (indexToUpdate !== -1) {
          jsonData.data[indexToUpdate] = {
            'key': id,
            'title': content.heading,
            'description': content.paragraph,
          };
  
          console.log(jsonData);
          axios
            .put(apiUrl, jsonData, {
              headers: headers,
            })
            .then((response) => {
              console.log(response);
              setSuccessMessage('Privacy Policy updated successfully.');
              getPolicies();
              resolve(true);
            })
            .catch((error) => {
              console.error(error);
              reject(false);
            });
        } else {
          resolve(false); 
        }
      } else {
        resolve(false);
      }
    });
  };
  
  
  const handleDelete = (id) => {
    console.log('id', id);
    const apiUrl = `${baseUrl}/content`;
    const headers = {
      "x-auth-token": authToken, // Replace with your actual token
    };


    const jsonData = {
      "page": "privacy_policy",
      "data": [
        ...(privacyPolicy || []).map((item, index) => ({
          'key': index,
          'title': item.title,
          'description': item.description
        }))
      ]
    };

    jsonData.data = jsonData.data.filter((item) => item.key !== id);
    
    axios
        .put(apiUrl, jsonData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response);
          setSuccessMessage('Privacy Policy deleted successfully.');
          getPolicies();
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const handleAddPolicy = (content) => {
    if (formData.heading && formData.paragraph) {
    const apiUrl = `${baseUrl}/content`;
    const headers = {
      "x-auth-token": authToken, // Replace with your actual token
    };
    
    const jsonData = {
      "page": "privacy_policy",
      "data": [
        ...(privacyPolicy || []).map((item, index) => ({
          'key': index,
          'title': item.title,
          'description': item.description
        })),
        {
          'title': formData?.heading,
          'description': formData?.paragraph
        }
      ]
    };

    axios
    .put(apiUrl, jsonData, {
      headers: headers,
    })
      .then((response) => {
        const responseData = response.data.data;
        setSuccessMessage('Privacy Policy added successfully.');
        getPolicies();
        setFormData('');
        setShowAddPolicy(false);

      })
      .catch((error) => {
        console.error(error);
      });
    }
  };


  const getPolicies = () => {
    const apiUrl = `${baseUrl}/content`;
    const headers = {
      "x-auth-token": authToken,
    };
  
    const params = {
      page: 'privacy_policy',
      
    };
  
    axios
      .get(apiUrl, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        const responseData = response.data.data;
        setPrivacyPolicy(responseData);
        console.log('jsonData',  response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  useEffect(() => {
    getPolicies();
  }, []);

  const handlePolicies = () => {

  }

  const handleNextPage = () => {};

  const handlePrevPage = () => {};

  return (
  <>
  {privacyPolicy.length > 0 ? (
      <section>
      <div className="flex justify-between mb-8">
        <h1 className="text-2xl md:text-3xl text-main font-bold">
          Privacy Policy
        </h1>
        <Button
          text="Add New Policy"
          icon={<MdAdd className="text-lg" />}
          rounded="md"
          bg="green"
          handleClick={() => setShowAddPolicy(true)}
        />
      </div>
      {successMessage && <div className="success-message">{successMessage}</div>}
      <div className="w-full grid my-4 gap-5">
        {privacyPolicy?.map((item, key) => {
          return (
            <CRUDCard
              key={key}
              heading={item.title}
              paragraph={item.description}
              handleEdit={(content) => handleEdit(content, key)}
              handleDelete={() => handleDelete(key)}
              defaultValue={{
                heading: item.title,
                paragraph: item.description,
              }}
              imgInput={false}
              
            />
          );
        })}
      </div>

      <AddModal
        showModal={showAddPolicy}
        setShowModal={setShowAddPolicy}
        imgInput={false}
        formData={formData}
        setFormData={setFormData}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleBtnClick={(content) => handleAddPolicy(content)}
      />

      <Pagination total={5} current={1} />
    </section>
  ) : (
    <NoResults heading="Privacy Policy" />

  )}
  </>
  );
};

export default PrivacyPolicy;
