import React, {useState} from "react";
import FullWidthCard from "./FullWidthCard";
import Confirmation from "../modals/Confirmation";

const PhotographerManagementTabs = ({ userList, status, changeStatusFunc, setUserIdToDelete, setPhotoToDelete, setStatusToDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);



  const confirmDelete = (userId, photographerId) => {
    setUserIdToDelete(userId);
    setPhotoToDelete(photographerId);
    setStatusToDelete(status);
    setShowDeleteModal(true);
  };

  return (
    <section className="w-full my-2">
      {userList?.map((item, index) => {
        return (
          <FullWidthCard
            key={index}
            text={item?.Photographer?.studioName}
            city={item?.city}
            phone={item?.phoneNo}
            btnText={status}
            showRating={true}
            rating={item.rating}
            btnHandleClick={() => confirmDelete(item.id, item?.Photographer?.id)}
            // btnHandleClick={() => changeStatusFunc(item.id, item?.Photographer?.id, status)}
          />
          );
          
        })}
        <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        handleConfirm={changeStatusFunc}
        heading="Are you sure?"
        text={status == 'whitelist' 
          ? "Are you sure you want to make this photographer whitelist?" 
          : "Are you sure you want to make this photographer blacklist?" }
        btnText={status == 'whitelist' ? "whitelist" : "blacklist"}
      />
    </section>
  );
};

export default PhotographerManagementTabs;
