import React, { useState, useEffect } from "react";
import TagsInput from "react-tagsinput";
import axios from "axios";

import "../assets/css/tags-input.css";

import Button from "../components/Button";

const AddStates = () => {
  const [states, setStates] = useState([]);
  const [checkedStates, setCheckedStates] = useState([]);
  const [citiesOfStates, setCitiesOfStates] = useState([]);
  const token = sessionStorage.getItem("authToken");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const headers = {
    "x-auth-token": token,
  };

  const baseUrl = process.env.REACT_APP_URL;

  useEffect(() => {
    const apiUrl = `${baseUrl}/state/states`;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data.data;
        console.log(data);
        // Initialize checkedStates and citiesOfStates arrays based on the API data
        const initialCheckedStates = data.map(
          (state, index) => state.Cities.length > 0
        );
        const initialCitiesOfStates = data.map((state) =>
          state.Cities.map((city) => city.name)
        );

        setStates(data);
        setCheckedStates(initialCheckedStates);
        setCitiesOfStates(initialCitiesOfStates);
      })
      .catch((error) => {
        console.error("Error fetching states:", error);
      });
  }, []);

  const handleCheck = (index) => {
    const updatedCheckedStates = [...checkedStates];
    updatedCheckedStates[index] = !updatedCheckedStates[index];
    setCheckedStates(updatedCheckedStates);
  };

  const handleChangeCities = (newTags, stateIndex) => {
    const updatedCities = [...citiesOfStates];
    updatedCities[stateIndex] = newTags;

    setCitiesOfStates(updatedCities);
  };

  const handleSave = () => {
    setErrorMessage("");
    setSuccessMessage("");
    let allCheckedStates = [];
    checkedStates?.map((item, index) => {
      if (item === true) {
        allCheckedStates.push(index);
      }
    });

    if (allCheckedStates.some((item) => citiesOfStates[item] === undefined)) {
      alert("Cities can't be empty!");
    } else {
      const data = allCheckedStates.map((stateIndex) => {
        return {
          stateId: states[stateIndex].id,
          cities: citiesOfStates[stateIndex],
        };
      });

      const apiUrl = `${baseUrl}/city/create`;

      axios
        .post(apiUrl, data, { headers })
        .then((response) => {
          console.log(response.data.data);
          setSuccessMessage("State Added/Updated Successfully");
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
          setErrorMessage(error.response.data.error.message);
        });
    }
  };

  return (
    <section>
      <h1 className="text-xl md:text-3xl text-main font-bold mb-3">
        Add States
      </h1>

      <div className="grid gap-3 my-7">
        {states?.map((state, stateIndex) => {
          return (
            <div key={state.code} className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name={state.code.toLowerCase()}
                  id={state.code.toLowerCase()}
                  onChange={() => handleCheck(stateIndex)}
                  checked={checkedStates[stateIndex] || false}
                />
                <label htmlFor={state.code.toLowerCase()} className="text-sm">
                  {state.name}
                </label>
              </div>
              {checkedStates[stateIndex] === true && (
                <TagsInput
                  value={citiesOfStates[stateIndex]}
                  onChange={(newTags) =>
                    handleChangeCities(newTags, stateIndex)
                  }
                  inputProps={{ placeholder: "Add Cities" }}
                />
              )}
            </div>
          );
        })}
      </div>
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <Button text="Save" rounded="md" handleClick={handleSave} />
    </section>
  );
};

export default AddStates;
