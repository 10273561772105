import React, { useState, useEffect } from "react";
import axios from "axios";
// layout
import HorizontalTabsLayout from "../layout/HorizontalTabsLayout";

//component
import HorizontalTab from "../components/HorizontalTab";
import PhotographerManagementTabs from "../components/PhotographerMangementTab";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";
// helpers
// import { PHOTOGRAPHERS } from "../helpers/constants";

const PhotographerManagement = () => {
  const [activeTab, setActiveTab] = useState("whitelist");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const token = sessionStorage.getItem("authToken");
  const [data, setData] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [photoToDelete, setPhotoToDelete] = useState(null);
  const [statusToDelete, setStatusToDelete] = useState(null);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const [searchQuery, setSearchQuery] = useState("");

  const changeStatusFunc = () => {

    const apiUrl = `${baseUrl}/user/photographer`;
    const headers = {
      "x-auth-token": token,
    };

    const data = {
      userId: userIdToDelete,
      photographerId: photoToDelete,
      status: statusToDelete == "whitelist" ? 'APPROVED' : 'SUSPENDED',
    };

    axios
      .put(apiUrl, data, { headers: headers })
      .then((response) => {
        var status = response.data.data.status;
        fetchData();
        setSuccessMessage(`Photographer ${status} Successfully`)
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  };

  const fetchData = () => {

    const apiUrl = `${baseUrl}/user/photographers`;
    const headers = {
      "x-auth-token": token,
    };

    const params = {
      status: activeTab === "whitelist" ? "APPROVED" : "SUSPENDED",
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      search: searchQuery,
      sortOrder: order
    };

    // Make a GET request to the API
    axios
      .get(apiUrl, {
        params: params,
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        // Handle the successful response here
        setData(response.data.data.rows);
        setTotalResults(response.data.data.count); // Set the total number of items
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching data:", error);
      });
  }


  useEffect(() => {
    fetchData();

  }, [activeTab, currentPage, order, resultsPerPage]);

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // const handleNextPage = () => {
  //   if (currentPage * itemsPerPage < totalItems) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePrevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }


  return (
    <>
   {data.length > 0 ? (
     <section className="w-full ">

     {successMessage && (
       <div>
         <p
           style={{
             backgroundColor: "#51c151",
             width: "300px",
             width: "362px",
             padding: "15px",
             borderRadius: " 5px",
             marginBottom: "20px",
           }}
         >
           {successMessage}
         </p>
       </div>
     )}
     
     <HorizontalTabsLayout>
       <HorizontalTab
         text={"Whitelist Photographer"}
         selected={activeTab === "whitelist"}
         handleClick={() => setActiveTab("whitelist")}
       />{" "}
       <HorizontalTab
         text={"Blacklist Photographer"}
         selected={activeTab === "blacklist"}
         handleClick={() => setActiveTab("blacklist")}
       />
     </HorizontalTabsLayout>
     <SearchAndFilterPanel
       onSearch={(e) => onSearch(e)}
       searchQuery={searchQuery}
       onChangeSearch={onChangeSearch}
       handleOders={handleOders}
     />
     {activeTab === "whitelist" ? (
       <PhotographerManagementTabs
         userList={data}
         status="blacklist"
         changeStatusFunc={changeStatusFunc}
         setUserIdToDelete={setUserIdToDelete}
         setStatusToDelete={setStatusToDelete}
         setPhotoToDelete={setPhotoToDelete}
       />
     ) : (
       <PhotographerManagementTabs
         userList={data}
         status="whitelist"
         changeStatusFunc={changeStatusFunc}
         setUserIdToDelete={setUserIdToDelete}
         setStatusToDelete={setStatusToDelete}
         setPhotoToDelete={setPhotoToDelete}
       />
     )}
     
     
     
     <Pagination
       total={totalResults}
       perPage={resultsPerPage}
       current={currentPage}
       onPageChange={handlePageChange}
     />
     </section>
   ): (
    <NoResults heading="Photographer Management" />

   )}
    </>
  );
};

export default PhotographerManagement;
