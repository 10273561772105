import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../components/Button";

const RemoveUser = ({
  showModal,
  setShowModal,
  btnText,
  handleBtnClick,
  allUsers,
}) => {
  const [email, setEmail] = useState("");

  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const showSuggestions = () => {
    if (email.length >= 2) {
      const suggestedEmails = allUsers.filter((item) => {
        return item.includes(email);
      });

      setSuggestions(suggestedEmails);
    } else {
      setSuggestions([]);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-[25%] z-20 flex flex-col gap-5 pb-8 min-w-[80vw] md:min-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-cente rw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Message</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <div className="flex flex-col items-center gap-3 rounded-md w-3/4 mx-auto">
              <p className="text-main">Enter Admin Id</p>
              <input
                className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full"
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                onKeyDown={showSuggestions}
              />
            </div>

            {/* <div className="flex flex-col items-center rounded-md w-3/4 h-[40%] mx-auto">
              <div className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full text-main text-center">
                Search Result
              </div>
              {suggestions.length > 0 && (
                <ul className="w-full">
                  {suggestions?.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => setEmail(item)}
                        className="text-center bg-white text-secondary hover:bg-secondary hover:text-white p-2"
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div> */}
            <div className="text-center">
              <Button
                text={btnText}
                handleClick={() => handleBtnClick(email)}
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default RemoveUser;
