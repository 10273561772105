import React from "react";
import { useNavigate } from "react-router-dom";

import FullWidthCard from "./FullWidthCard";

const RegistrationPendingTabs = ({ pendingUserList }) => {
  const navigate = useNavigate();

  const viewDetailsFunc = (id) => {
    return navigate(`/registration-pending/view-details/${id}`);
  };

  return (
    <section className="w-full my-2">
      {pendingUserList?.map((item, index) => {
        console.log(item);
        return (
          <FullWidthCard
            key={index}
            text={item?.Photographer?.studioName}
            city={item?.city}
            phone={item?.phoneNo}
            btnText="View Details"
            btnHandleClick={() => viewDetailsFunc(item?.Photographer?.id)}
          />
        );
      })}
    </section>
  );
};

export default RegistrationPendingTabs;
