import React from "react";
import { Link } from "react-router-dom";

//react-icons
import { FaFolder } from "react-icons/fa";

const Folder = ({ color, text, href, data }) => {
  return (
    <>
      <Link
        to={href}
        state={{ myData: data }}
        className="flex flex-col items-center h-max w-full"
      >
        <FaFolder
          className="text-8xl cursor-pointer"
          style={{ color: color }}
        />
        <p className="text-sm font-semibold text-center text-gray-700">
          {text}
        </p>
      </Link>
    </>
  );
};

export default Folder;
