import React from "react";

const TextInput = ({ type, label, name, placeholder, value, handleChange }) => {
  return (
    <div className="grid md:grid-flow-col md:grid-cols-3 items-center justify-between gap-3 rounded-md w-full m-auto">
      <p className="text-gray-500 text-sm min-w-max md:col-span-1">{label}</p>
      <input
        className="md:col-span-2 border border-gray-300 bg-white p-2 outline-none text-sm "
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextInput;
