import React, { useState, useEffect } from "react";
import Confirmation from "../modals/Confirmation";
//react-icons
import { MdEdit } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";

const CouponCard = ({ handleEdit, coupon, handleDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const openDeleteModal = (itemId) => {
    setShowDeleteModal(true);
    setDeleteItemId(itemId);
  };

  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  const currentDate = new Date().toISOString().split("T")[0];

  // console.log(coupon?.map((item) => formatDate(item.endDate)));
  // console.log();

  return (
    <>
      {coupon.map((item, index) => (
        <div
          className={
            "bg-gradient-to-br from-secondary to-main text-white text-center py-5 md:py-10 px-10 md:px-20 rounded-lg shadow-md relative"
          }
        >
          <div className="flex flex-col gap-2 md:gap-4">
            <h3 className="text-2xl font-semibold">{item.amount} INR</h3>
            <div className="flex justify-between gap-4">
              <p className="text-sm">
                <b>Category:</b> {item.category}
              </p>
              <div className="flex flex-col  w-full">
                <p className="text-xs md:text-sm">
                  {item.applicableToLifeTime == true
                    ? "*Lifetime Accessibility"
                    : ""}
                </p>
                <p className="text-xs md:text-sm">
                  {item.applicableToPhotography == true
                    ? "*Photography Services"
                    : ""}
                </p>
                <p className="text-xs md:text-sm">
                  {item.isFree == true ? "*Free" : ""}
                </p>
                <p className="text-xs md:text-sm">
                  {item.isPro == true ? "*Pro" : ""}
                </p>
                <p className="text-xs md:text-sm">
                  {item.isUltra == true ? "*Ultra" : ""}
                </p>
              </div>
            </div>
            <div className="flex gap-3">
              <p className="text-xs">
                <b>Valid From:</b> {formatDate(item.startDate)}
              </p>
              <p className="text-xs">
                <b>Valid Till:</b> {formatDate(item.endDate)}
              </p>
            </div>
          </div>
          <div className="flex gap-2 items-center absolute top-0 right-0">
            <button
              // className={`${
              //   currentDate > formatDate(item.endDate)
              //     ? "cursor-not-allowed"
              //     : ""
              // } bg-green-500 p-2 rounded-full`}
              className={`bg-green-500 p-2 rounded-full`}
              // disabled={currentDate > formatDate(item.endDate)}
              onClick={() => handleEdit(item.id)}
            >
              <MdEdit className="text-white text-lg" />
            </button>
            <button
              className="bg-red-500 p-2 rounded-full"
              onClick={() => openDeleteModal(item.id)}
            >
              <AiFillDelete className="text-white text-lg" />
            </button>
          </div>
          <div className="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 left-0 -ml-6"></div>
          <div className="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 right-0 -mr-6"></div>
        </div>
      ))}

      <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        heading="Are you sure?"
        text="Are you sure you want to Delete?"
        btnText="Delete"
        handleConfirm={() => handleDelete(deleteItemId)}
      />
    </>
  );
};

export default CouponCard;
