import React from "react";

const TextDetail = ({ label, value, required }) => {
  return (
    <div className="grid grid-flow-row gap-2 md:gap-0 md:grid-flow-col md:grid-cols-3 ">
      <p className="text-sm md:col-span-1 text-gray-600">
        {label} {required && <span className="text-red-500">*</span>}
      </p>
      <p className="text-sm md:col-span-2 text-secondary bg-white p-2">
        {value}
      </p>
    </div>
  );
};

export default TextDetail;
