import React from "react";

const DataTable = ({
  masterUser,
  data,
  option,
  removeAdmin,
  viewDetails,
  showViewDetailsAction = false,
  hideRemoveBtn
}) => {
  console.log(masterUser, "adminnndataaa")
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 border border-b-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Id
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            {option == "customer" ||
              (option == "photographer" && (
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
              ))}
            {option == "customer" && (
              <th scope="col" className="px-6 py-3">
                Phone No
              </th>
            )}
            {option == "photographer" && (
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            )}
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            console.log(item, "itemssssss")
            return (
              <tr className="bg-white border-b " key={index}>
                <th className="px-6 py-4 text-gray-900">{item.id}</th>
                <td
                  scope="row"
                  className="px-6 py-4 font-medium whitespace-nowrap "
                >
                  {item.email} {item.User?.email}
                </td>
                {option == "customer" ||
                  (option == "photographer" && (
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium whitespace-nowrap "
                    >
                      {item.name} {item.User?.name}
                    </td>
                  ))}
                {option == "customer" && (
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap "
                  >
                    {item.phoneNo}
                  </td>
                )}

                {/* {option == "customer" && (
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap "
                  >
                    {item.status}
                  </td>
                )} */}
                {option == "photographer" && (
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium whitespace-nowrap "
                  >
                    {item.Photographer?.status}
                  </td>
                )}
                {/* <td className="px-6 py-4">{item.role}</td> */}
                {/* <td className="px-6 py-4">
                  <button
                    className="font-medium text-red-600  hover:underline"
                    onClick={removeAdmin}
                  >
                    Remove
                  </button>
                </td> */}
                <td className="px-6 py-4 flex gap-3 items-center">
                  {hideRemoveBtn === true ? null : (
                     masterUser !== item.email && (
                      <button
                        className="text-sm font-medium text-red-600  hover:underline"
                        onClick={() => removeAdmin(item.id, item.email)}
                      >
                        Remove
                      </button>
                    )
                  ) }
                 
                  {showViewDetailsAction && (
                    <button
                      className="text-sm font-medium text-green-600  hover:underline"
                      onClick={() => viewDetails(item.Photographer?.id)}
                    >
                      View Details
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
