import React from "react";
import { useParams } from "react-router-dom";

// react-icons
import { FcFolder } from "react-icons/fc";

import { PAST_EVENTS } from "../helpers/constants";

const PastEventFootages = () => {
  const params = useParams();

  const event = PAST_EVENTS.find((item) => item.id === params.id);

  return (
    <section className="flex flex-col">
      <div className="w-full  my-7 flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl text-main font-bold">{event.name}</h2>
          <p className="">{event.phone}</p>
        </div>
        <p className="text-sm">{event.location}</p>
        <div className="flex justify-between items-center">
          <p className="text-sm">
            Event Date: {event.eventStartDate} - {event.eventEndDate}
          </p>
          <p className="text-sm">{event.city}</p>
        </div>
      </div>

      <div className="flex gap-5">
        <div className="flex flex-col items-center justify-center cursor-pointer">
          <FcFolder className="text-7xl text-blue-500" />
          <p className="text-sm ">Raw Footages</p>
        </div>
        <div className="flex flex-col items-center justify-center cursor-pointer">
          <FcFolder className="text-7xl text-blue-500" />
          <p className="text-sm">Edited Footages</p>
        </div>
      </div>
    </section>
  );
};

export default PastEventFootages;
