import axios from "axios";

const BASEURL = `${process.env.REACT_APP_BASE_URL}`;

const authApi = axios.create({
  baseURL: BASEURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set the Authorization header
authApi.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("authToken");
  console.log(token, "token:authAPi");
  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
});

authApi.interceptors.response.use(
  (response) => {
    // handle success
    // console.log(response, "response from axios");
    return response;
  },
  (error) => {
    // handle error
    if (error.response.status == 403) {
      // localStorage.removeItem("user");
      window.location.href = "/";

      console.log(error, "network error");
    } else if (error.response.status == 401) {
      console.log(error.response.status, "statusserrror")
      // NotificationWithIcon("error", "Session Expired! Please Login Again");
      localStorage.removeItem("user");
      window.location.href = "/";
    } else {
      // localStorage.removeItem("user")
      console.log(error.response.data, "errorrupadered");
      console.log(error.response.status);
      console.log(error.response.headers);
    }

    return Promise.reject(error);
  }
);

export default authApi;
