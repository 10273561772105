import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { CustomerContext } from "../ContextApi/Customer";

const RootLayout = ({ children }) => {
  const [data, setData] = useState({});
  const pathName = useLocation().pathname;

  const showSidebar = useLocation().pathname !== "/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName]);

  return (
    <CustomerContext.Provider value={{ data, setData }}>
      <section
        className={`${
          pathName === "/" ? "" : "grid md:grid-cols-[23%_77%] grid-cols-1"
        } `}
      >
          {showSidebar && <Sidebar />}
        <div className="content">{children}</div>
      </section>
    </CustomerContext.Provider>
  );
};

export default RootLayout;
