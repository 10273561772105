import React from "react";

//react-icons

const Image = ({ src, alt, id, open }) => {
  return (
    <>
      <div className="relative cursor-pointer ">
        <img
          src={src}
          alt={alt}
          // onDoubleClick={selectImg}
          //   onClick={handleCheck}
          onDoubleClick={open}
          style={{ border: "1px solid gray" }}
        />
      </div>
    </>
  );
};

export default Image;
