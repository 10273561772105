"use client";

import Chartjs from "chart.js/auto";
import { Chart } from "chart.js";
import { Pie } from "react-chartjs-2";

Chart.defaults.color = "#979ca6";

const PieChart = ({ label, dataLabels, chartData, title, dataChecked}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          color: "#979ca6",
        },
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  const data = {
    labels: dataLabels,
    datasets: [
      {
        label: label,
        data: chartData,
        borderColor: [
          "rgba(45,139,186,1)",
          "rgba(65,184,213,1)",
          "rgba(49,53,110,1)",
          "rgba(94,57,103,1)",
          "rgba(18, 203, 196,1)",
          "rgba(185,114,134,1)",
          "rgba(251, 197, 49,1)",
          "rgba(76, 209, 55,1)",
          "rgba(232, 65, 24,1)",
          "rgba(252, 66, 123,1)",
          "rgba(255, 184, 184,1)",
          "rgba(205, 132, 241,1)",
        ],
        backgroundColor: [
          "rgba(45,139,186,0.8)",
          "rgba(65,184,213,0.8)",
          "rgba(49,53,110,0.8)",
          "rgba(94,57,103,0.8)",
          "rgba(18, 203, 196,0.8)",
          "rgba(185,114,134,0.8)",
          "rgba(251, 197, 49,0.8)",
          "rgba(76, 209, 55,0.8)",
          "rgba(232, 65, 24,0.8)",
          "rgba(252, 66, 123,0.8)",
          "rgba(255, 184, 184,0.8)",
          "rgba(205, 132, 241,0.8)",
        ],
        borderWidth: 1,
        color: "#FFFFFF",
      },
    ],
  };

  console.log({chartData})
  console.log({data})


  return (
    <div>
      <Pie
        options={options}
        data={data}
        width={350}
        height={350}
        style={{ width: "350px important", height: "350px important" }}
      />
    </div>
  );
};
export default PieChart;
