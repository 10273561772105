import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import Button from "../components/Button";
import AddComment from "../components/AddComment";

// helpers
import { CARD_DATA } from "../helpers/constants";
import axios from "axios";


// react-icons
import { RiAccountCircleFill } from "react-icons/ri";

const EventTimeline = () => {
  const params = useParams();
  const paramsId = params.id.split("=")[0]
  const status = params.id.split("=")[1]
  console.log({status})
  const baseUrl = process.env.REACT_APP_BASE_URL_NON_ADMIN;
  const token = sessionStorage.getItem("authToken");
  const [data, setData] = useState()
  const baseUrlAdmin = process.env.REACT_APP_BASE_URL;
  // Use your actual API endpoint here
  const apiUrl = `${baseUrlAdmin}/bookings`;
  const [booking, setBooking] = useState("")

  console.log(booking, "boookinggg")
  const headers = {
    "x-auth-token": token,
  };

  console.log(data, "Dataaaaaa")

  const fetchDataUser = async () => {
    

    try {

      const response = await axios.get(apiUrl, {
        params: {
          status: status,
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        if (Array.isArray(responseData.rows)) {
          // Ensure rows is an array
          setBooking(responseData.rows);
        } else {
          console.error("Invalid data format: rows is not an array.");
        }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };



  const fetchData = async () => {
    try {
      const data = await axios.get(`${baseUrl}/booking/${paramsId}/timeline`, { headers });
      console.log(data, "dataaaaaadas")
      setData(data.data.data)

      // return data.data;
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchDataAndUserData = async () => {
      try {
        await fetchDataUser();
        await fetchData();
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAndUserData();
  }, []);

  const card = booking && booking?.find((item) => item?.id == paramsId);

  console.log(card, "card?ddddddd")

  const [comment, setComment] = useState("");

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const saveComment = () => {
    console.log(comment);
  };

  return (
    <section className="px-3 ">
      <div className="w-full my-7 flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl text-main font-bold">{card?.PhotographerUser?.name || "Name not available"}</h2>
          <p className="">{card?.PhotographerUser?.phoneNo || "Phone number not available"}</p>
        </div>
        <p className="text-sm">{card?.address || "Address not available"}</p>
        <div className="flex justify-between items-center">
          <p className="text-sm">
            Event Date: {card?.startDate ? card.startDate + " - " + card.endDate : "Event date not available"}
          </p>
          <p className="text-sm">{card?.bookedCity || "Booked city not available"}</p>
        </div>
      </div>


      <div className="w-full flex flex-col">
        <h2 className="text-lg font-semibold text-main mb-2">Comments</h2>
        {card?.comments && (
          <ul className="w-full flex flex-col">
            {card?.comments?.map((item, index) => {
              return (
                <li
                  key={index}
                  className="text-sm text-gray-500 p-1 flex gap-1 items-center"
                >
                  <RiAccountCircleFill className="text-lg" />
                  <span>{item}</span>
                </li>
              );
            })}
          </ul>
        )}
        <AddComment
          value={comment}
          handleChange={handleChange}
          handleBtnClick={saveComment}
        />
      </div>

      <VerticalTimeline lineColor={"#620808"}>
        {data?.map((val, index) => {
          const lastIndex = data?.length - 1;
          return (
            <>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={val.status === true ? { background: "#7ed957", color: "#fff" } : { background: "#ffff", color: "#fff" }}
                contentStyle={(index === lastIndex) ? { background: "#7ed957", color: "#fff" } : null}
                iconClassName={"!top-5 !w-[25px] !h-[25px] !left-[50%] !-ml-[12px]"}
              >
                <h3 className="font-semibold">{val?.title}</h3>
                <p className="!text-sm !font-normal">{val?.description}</p>

              </VerticalTimelineElement>



            </>
          )
        })}



      </VerticalTimeline>
    </section>
  );
};

export default EventTimeline;


{/* <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: "#7ed957", color: "#fff" }}
          iconClassName={"!top-5 !w-[25px] !h-[25px] !left-[50%] !-ml-[12px]"}
        >
          <h3 className="font-semibold inline">Editor assignment</h3>
          <span className="bg-main px-2 py-1 text-white text-sm font-bold rounded-lg float-right">
            Add Editor
          </span>
          <input
            className=" w-full !text-xs !font-normal !px-2 !py-2 !my-2 border border-gray-400 outline-none"
            placeholder="Editor Email Id"
          />
          <div>
            <Button text={"Send"} bg="#620808" rounded="lg" />
          </div>
        </VerticalTimelineElement> */}