import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

// Assets
import ExampleProfile from "../assets/profile.png";
import { CustomerContext } from "../ContextApi/Customer";
import { useSession } from "./SessionContext";

const Chat = ({
  profilePic,
  customerName,
  href,
  data,
  lastMsg,
  lastMsgTime,
  unread,
  customer,
  newMsgCount,
  isFromPgUserChat=false,
}) => {
  const { data:oldMsgCount, setData } = useContext(CustomerContext);
  const { unReadMsg, setUnReadMsg } = useSession();
  const navigate = useNavigate();

  // console.log(unReadMsg, "==oldMsgCountoldMsgCount");
  
  const handleClick = () => {
    if(isFromPgUserChat){
      navigate(href, { state: { data } });
    }
    else {
      const newMsgCount2 = {}
      let keys = Object.keys(unReadMsg);
      console.log({keys});
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if(key == customer.id) continue;
        newMsgCount2[key] = unReadMsg[key];
      }
      console.log({newMsgCount2});
      setUnReadMsg(newMsgCount2);
      navigate(`/customer-chats/${customer.id}`, { state: { data: customer } });
    }
  };

  return (
    <div 
      onClick={handleClick}
      // to={`/customer-chats/${customer.id}`}
      // state={{ data: customer }}
      className={`${
        unread ? "bg-gray-300/40" : "bg-white"
      } w-full p-2 flex justify-between items-center  border-b-[1px] border-b-gray-300 ${newMsgCount === 0 ? "order-2":""}`}
    >
      <div className="flex items-center gap-5">
        <div>
          <img
            src={profilePic ? profilePic : ExampleProfile}
            alt="profile"
            className="rounded-full w-[50px] h-[50px]"
          />
        </div>
        <div className="flex flex-col">
          <p className="font-bold">{customerName}</p>
          <p className="text-gray-500 text-sm">{lastMsg}</p>
        </div>
      </div>
      {/* <div className="flex items-end">
        <p className="text-xs text-gray-500">{lastMsgTime}</p>
      </div> */}
      {newMsgCount > 0 ? <div className="flex items-center mr-5">
        <p className="text-xs text-gray-500">
          <span className={`w-[20px] h-[20px] rounded-full bg-main text-white text-sm font-bold flex justify-center items-center`}>
            {newMsgCount}
          </span>
        </p>
      </div>:null}
    </div>
  );
};

export default Chat;
