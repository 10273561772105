import React, { useRef } from "react";
import Button from "../components/Button";

//react-icons
import { GoMail } from "react-icons/go";
import { BiSolidUser } from "react-icons/bi";
import { AiFillEdit } from "react-icons/ai";

//assets
import profile from "../assets/profile.png";

const MyProfile = () => {
  const profileRef = useRef();

  return (
    <section>
      <h1 className="text-3xl text-main font-bold">My Profile</h1>
      <form className="flex flex-col my-4 gap-3">
        {/* Profile Image  */}
        <div className="w-full flex justify-start my-4">
          <div className="w-20 h-20 flex ">
            <img src={profile} alt="profile" className="min-w-full" />
            <div className="relative flex flex-col w-full h-full z-10">
              <div
                className="bg-gray-500 cursor-pointer p-1 rounded-full absolute right-0 bottom-0"
                onClick={() => profileRef.current.click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  className="w-full"
                  ref={profileRef}
                />
                <AiFillEdit className="text-white" />
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-1/2 w-full flex flex-col gap-3">
          {/* Name */}
          <div className="border shadow-sm rounded flex">
            <div className="px-4 py-3 dark:text-dark-primarytext flex items-center border-r">
              <BiSolidUser />
            </div>
            <input
              type="text"
              name="name"
              className="pl-3 py-3 w-full text-sm focus:outline-none placeholder-gray-500 rounded bg-transparent text-gray-500 dark:text-dark-primarytext"
              placeholder="Name"
            />
          </div>

          {/* Email */}
          <div className="border shadow-sm rounded flex cursor-not-allowed  ">
            <div className="px-4 py-3 dark:text-dark-primarytext flex items-center border-r">
              <GoMail />
            </div>
            <input
              type="email"
              name="email"
              className="cursor-not-allowed pl-3 py-3 w-full text-sm focus:outline-none placeholder-gray-500 rounded bg-transparent text-gray-500 dark:text-dark-primarytext"
              placeholder="example@gmail.com"
              disabled
            />
          </div>
        </div>

        <div>
          <Button text="Save" type="submit" rounded="md" />
        </div>
      </form>
    </section>
  );
};

export default MyProfile;
