// socket.js
import io from "socket.io-client";

let socket = null;
// console.log(process.env.REACT_APP_BASE_URL, "REACT_APP_BASE_URL");
export const initiateSocket = (token, setUnReadMsg=()=>{}) => {
  socket = io.connect(process.env.REACT_APP_BACKEND_BASE_URL, {
    extraHeaders: {
      "Authorization": token,
    },
  });

  socket?.on("connect", () => {
    console.log("Socket connected");
    // sendMessage({ senderId: 1, receiverId: 178, message: { text: "Hello Ack", type: "text" }});
    // sendMessage({ senderId: 178, receiverId: null, message: { text: "Hello Admin", type: "text" }, isForAdmin: true });
    // readMessageAndUpdateStatus({ userId: 178 }, (data) => {
    //   console.log(data, "msg data of userId = 178");
    // });
  });

  socket?.on('disconnect', () => {
    console.log('Socket disconnected');
  });
  
  // Get all new messages
  // socket.on('new_message', (data) => {
  //   console.log('New Message: ', data);
  // });



  // After Login (back to app) if admin has any unread msg from any user (or multiple msg from multiple user)
  // then this function gives you a key value pairs of userId (msg_sender_id) and unread_msg_count 
  // for example: { 178: 4 } mean user whose id is 178 has send you 4 msg which you have not read yet.
  // socket.on("unread_msg_count", (data) => {
  //   console.log("unread_msg_count", data);
  // });

  // emit the EVENT when user/admin open specific user message;
  socket?.on("new_message_for", (data) => {
    console.log("new_message_for", data);
    setUnReadMsg(prev => ({ ...prev, [data.senderId]: (prev[data.senderId] || 0)  + 1 }))
  });

  socket?.on("message_read", (data) => {
    console.log("unread_msg_count", data);
  });

  socket?.on("unread_msg_count", (data) => {
    console.log("unread_msg_count", data);
    setUnReadMsg(data);
  });

  socket?.on('error', (error) => {
    // Handle the error here
    console.log('Socket error:', error);
  });

  return socket;
}


export function sendMessage(data, callback=()=>{}){
  // data = { senderId=1, receiverId=2, message={ text: "hi, this is msg", type: "text" } }
  socket?.emit("new_message_by_admin", data, callback);
}

// when admin (any one admin) click on specific user msg then this function is called with current User Msg.
export function readMessageAndUpdateStatus(data, callback){
  // data = { userId: 178 }
  socket?.emit("get_user_message", data, callback);
}


export const getSocket = () => socket;
