// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Faqs.css (Custom CSS for styling) */

.success-message {
    background-color: #4caf50; /* Green background for success messages */
    color: white;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .error-message {
    background-color: #f44336; /* Red background for error messages */
    color: white;
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  /* Add more styling as needed */
  `, "",{"version":3,"sources":["webpack://./src/pages/message.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;IACI,yBAAyB,EAAE,0CAA0C;IACrE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB,EAAE,sCAAsC;IACjE,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA,+BAA+B","sourcesContent":["/* Faqs.css (Custom CSS for styling) */\n\n.success-message {\n    background-color: #4caf50; /* Green background for success messages */\n    color: white;\n    padding: 10px;\n    text-align: center;\n    margin-bottom: 10px;\n    border-radius: 5px;\n  }\n  \n  .error-message {\n    background-color: #f44336; /* Red background for error messages */\n    color: white;\n    padding: 10px;\n    text-align: center;\n    margin-bottom: 10px;\n    border-radius: 5px;\n  }\n  \n  /* Add more styling as needed */\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
