import React from "react";
import SearchBar from "../components/SearchBar";
import Filter from "../components/Filter";

const SearchAndFilterPanel = ({
  onSearch,
  searchQuery,
  onChangeSearch,
  ratingFilter = false,
  handleOders
}) => {
  return (
    <div className="w-full flex gap-2 items-center">
      <SearchBar
        onSearch={onSearch}
        value={searchQuery}
        onChange={onChangeSearch}
      />
      <Filter rating={ratingFilter} handleOders={handleOders}/>
    </div>
  );
};

export default SearchAndFilterPanel;
