import React, { useState } from "react";
import Button from "../components/Button";
import ResetPassword from "./ResetPassword";
//react-icons
import { BiSolidUser } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";

// logo
import logo from "../assets/logo.png";

const ForgotPassword = ({ showForgotPassword, setShowForgotPassword }) => {
  const [showResetPassword, setShowResetPassword] = useState(false);

  const [sendOtp, setSendOtp] = useState(false);

  const [verifyOtp, setVerifyOtp] = useState(false);

  const handleSendOTP = () => {
    // if otp is sent successfully then
    setSendOtp(true);
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    setVerifyOtp(true);
    setShowResetPassword(true);
  };

  return (
    <>
      {showForgotPassword && !verifyOtp && (
        <div className=" md:w-[40%] bg-white shadow-xl rounded-md p-8">
          <div className="flex flex-col gap-3 items-center">
            <img
              src={logo}
              alt=""
              width={200}
              height={50}
              className="cursor-pointer"
            />

            <h1 className="md:text-2xl text-main font-bold">Forgot Password</h1>

            <form className="w-full" onSubmit={handleVerifyOtp}>
              <div className="w-full flex flex-col gap-4">
                {/* Email Field */}
                <div className="flex flex-col gap-2">
                  <label htmlFor="email" className="text-gray-800">
                    Email
                  </label>
                  <div className="w-full flex  gap-1 bg-white border border-gray-300">
                    <div className="bg-gray-300 flex items-center px-2 ">
                      <BiSolidUser />
                    </div>
                    <input
                      id="email"
                      type="email"
                      required
                      placeholder="Enter Email"
                      className="outline-none border-none p-1 w-full"
                    />
                  </div>
                </div>

                {sendOtp && (
                  <div className="flex flex-col gap-2">
                    <label htmlFor="otp" className="text-gray-800">
                      Enter OTP
                    </label>
                    <div className="w-full flex  gap-1 bg-white border border-gray-300">
                      <div className="bg-gray-300 flex items-center px-2 ">
                        <FaRegEye />
                      </div>
                      <input
                        id="otp"
                        type="text"
                        required
                        placeholder="Enter OTP"
                        className="outline-none border-none p-1 w-full"
                      />
                    </div>
                  </div>
                )}
                <div className="flex justify-center gap-3">
                  <Button
                    type="button"
                    text={!sendOtp ? "Send OTP" : "Resend OTP"}
                    handleClick={handleSendOTP}
                  />
                  {sendOtp && <Button type="submit" text={"Verify OTP"} />}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <ResetPassword
        showResetPassword={showResetPassword}
        setShowResetPassword={setShowResetPassword}
      />
    </>
  );
};

export default ForgotPassword;
