import React, { useEffect, useState, useRef } from "react";
import Button from "../components/Button";

//react-icons
import { RxCross1 } from "react-icons/rx";
import { FiUpload } from "react-icons/fi";

//assets
import galleryIcon from "../assets/galleryicon.png";

const EditModal = ({
  showModal,
  setShowModal,
  handleBtnClick,
  defaultValue,
  validationErrors, 
  setValidationErrors,
  setErrorMessage,
  imgInput,
}) => {
  const fileUploadRef = useRef();
  

  const [img, setImg] = useState(imgInput ? defaultValue.imageUrl : "");
  const [content, setContent] = useState({
    heading: defaultValue.heading,
    paragraph: defaultValue.description ?? defaultValue.paragraph,
  });

  const handleChange = (e) => {
    setContent({
      ...content,
      [e.target.name]: e.target.value,
    });

    if (validationErrors && validationErrors[e.target.name]) {
      setValidationErrors({
        ...validationErrors,
        [e.target.name]: "",
      });
    }
  };

  const handleImgInput = (e) => {
    let file = e.target.files[0];

    let reader = new FileReader();

    reader.onload = (e) => {
      setImg(e.target.result);
      setContent({
        ...content,
        imgUrl: file
      })
    };

    reader.readAsDataURL(file);
  };

  const handleUpdate = (setShowModal) => {
    if (imgInput) {
      handleBtnClick({ ...content, img });
    } else {
      handleBtnClick(content, setShowModal);
    }


    let errors = {};

    if (!content.heading.trim()) {
      errors.heading = "Heading is required.";
    }

    if (!content.paragraph.trim()) {
      errors.paragraph = "Paragraph is required.";
    }

    // If there are validation errors, set them
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Clear validation errors
    setValidationErrors({});

    // Set error message using the callback function if there are validation errors
    setErrorMessage("Please fix the validation errors.");
  };

  const closeModal = () => {
    setShowModal(false);
    setContent({
      heading: defaultValue.heading,
      paragraph: defaultValue.paragraph,
    });
    setImg(imgInput ? defaultValue.img : "");

    setValidationErrors({});
    // Clear error message when closing the modal
    setErrorMessage("");
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div
            className="fixed z-20 flex flex-col gap-5 p-3 min-w-[80vw] max-w-[80vw] md:min-w-[50vw] md:max-w-[50vw] bg-bgMain rounded-lg"
            style={{ top: !imgInput ? "20%" : "" }}
          >
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Edit</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            {imgInput && (
              <div className="flex flex-col gap-3 justify-center items-center border-black rounded-md w-full m-auto">
                <div className=" flex justify-center max-h-[150px] overflow-auto">
                  <img
                    src={img ? img : galleryIcon}
                    alt=""
                    className="w-full h-full "
                  />
                </div>
                <button
                  className="bg-gray-700 flex items-center gap-1 px-4 py-2 text-white rounded-md"
                  onClick={() => fileUploadRef.current.click()}
                >
                  <FiUpload />
                  Upload Image
                </button>
                <input
                  type="file"
                  ref={fileUploadRef}
                  hidden
                  onChange={handleImgInput}
                />
              </div>
            )}

            <div className="flex flex-col gap-1 rounded-md w-full m-auto">
              <p className="text-gray-500 text-sm min-w-max">Heading</p>
              <input
                type="text"
                className="resize-none border border-gray-300 bg-white p-2 outline-none text-sm w-full"
                name="heading"
                value={content.heading}
                onChange={handleChange}
                placeholder="Heading"
              />
            </div>
            {validationErrors && validationErrors.heading && (
                <p className="text-red-500 text-xs">
                  {validationErrors.heading}
                </p>
              )}
            <div className="flex flex-col gap-1 rounded-md w-full m-auto">
              <p className="text-gray-500 text-sm min-w-max">Paragraph</p>
              <textarea
                type="text"
                className="resize-none border border-gray-300 bg-white p-2 outline-none text-sm w-full"
                name="paragraph"
                value={content.paragraph}
                onChange={handleChange}
                placeholder="Paragraph"
                rows={4}
              />
            </div>
            {validationErrors && validationErrors.paragraph && (
                <p className="text-red-500 text-xs">
                  {validationErrors.paragraph}
                </p>
              )}

            <div className="text-end">
              <Button text="Update" handleClick={handleUpdate} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditModal;
