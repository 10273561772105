import React, { useState, useEffect } from "react";

import Button from "../components/Button";
import Pagination from "../components/Pagination";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import { MdAdd, MdDelete } from "react-icons/md";
import ExclusiveDealCard from "../components/ExclusiveDealCard";
import AddExclusiveDeal from "../modals/AddExclusiveDeal";
import axios from "axios";

//helpers
import { EXCLUSIVE_DEALS } from "../helpers/constants";
import NoResults from "../components/NoResults";

const ExclusiveDeals = () => {
  const [showAddDeal, setShowAddDeal] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = sessionStorage.getItem("authToken");
  const [formData, setFormData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  // const [showEditModal, setShowEditModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getDeals, setGetDeals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    handleFetch();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAdd = (content) => {
    if (content.heading && content.imgFile && content.intro) {
      const apiUrl = `${baseUrl}/exclusive-deal`;
      const headers = {
        "x-auth-token": authToken,
      };

      const formData = new FormData();
      formData.append("heading", content.heading);
      formData.append("intro", content.intro);
      formData.append("imageUrl", content.imgFile);
      formData.append("termsNconditions", content.termsNconditions);

      axios
        .post(apiUrl, formData, {
          headers: headers,
        })
        .then((response) => {
          const responseData = response.data.data;
          console.log("jsonData", response.data.data);
          setSuccessMessage(response.data.message);
          handleFetch();
          setShowAddDeal(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setErrorMessage("Please fill all required fields.");
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = (id) => {
    console.log(id);

    const apiUrl = `${baseUrl}/exclusive-deal/${id}`;
    const headers = {
      "x-auth-token": authToken,
    };

    axios
      .delete(apiUrl, {
        headers: headers,
      })
      .then((response) => {
        const responseData = response.data.data;
        console.log("jsonData", response.data.data);
        handleFetch();
        setSuccessMessage(response.data.message);
        handleFetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEdit = (id, content) => {
    console.log(id, content);

    const apiUrl = `${baseUrl}/exclusive-deal/${id}`;
    const headers = {
      "x-auth-token": authToken,
    };

    const formData = new FormData();
    formData.append("heading", content.heading);
    formData.append("intro", content.intro);
    formData.append("imageUrl", content.imgFile);

    axios
      .put(apiUrl, formData, {
        headers: headers,
      })
      .then((response) => {
        const responseData = response.data.data;
        console.log("jsonData", response.data.data);
        handleFetch();
        setSuccessMessage(response.data.message);
        handleFetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFetch = () => {
    const apiUrl = `${baseUrl}/exclusive-deal`;
    const headers = {
      "x-auth-token": authToken,
    };

    const params = {
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      search: searchQuery,
      sortOrder: order

    };

    axios
      .get(apiUrl, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        const responseData = response?.data;
        setGetDeals(responseData?.data);
        console.log("jsonData", response.data.data);
        setTotalResults(responseData?.data?.length);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    handleFetch();
  }, [order, currentPage]);

  return (
    <>
      <section>
        <SearchAndFilterPanel
          onSearch={(e) => onSearch(e)}
          searchQuery={searchQuery}
          onChangeSearch={onChangeSearch}
          handleOders={handleOders}
        />
        <div className="flex flex-col md:flex-row justify-between">
          <h1 className="text-main text-xl md:text-3xl font-bold">
            Exclusive Deals
          </h1>
          <Button
            text="Add Deal"
            icon={<MdAdd className="text-lg" />}
            rounded="md"
            bg="green"
            handleClick={() => setShowAddDeal(true)}
          />
        </div>
        {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
        {getDeals?.length === 0 && (
          <div className="flex justify-center items-center w-full ">

            <NoResults
              text="No results found"
              icon={<MdDelete className="text-lg" />}
              rounded="md"
            />
          </div>

        )}
        <div className="grid md:grid-cols-2 gap-3 my-7 ">

          {getDeals?.map((item, index) => {
            return (
              <ExclusiveDealCard
                key={index}
                id={item.id}
                text={item.heading}
                img={item.imageUrl}
                handleEdit={(content) => handleEdit(item.id, content)}
                handleDelete={(id) => handleDelete(id)}
                defaultValues={{
                  heading: item.heading,
                  intro: item.intro,
                  img: item.img,
                  terms: item.terms,
                }}
              />
            );
          })}
        </div>
        <AddExclusiveDeal
          showModal={showAddDeal}
          setShowModal={setShowAddDeal}
          handleSave={(content) => handleAdd(content)}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
        {getDeals?.length !== 0 && (
          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />)}

      </section>

    </>
  );
};

export default ExclusiveDeals;
