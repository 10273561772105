import React from "react";
import Button from "./Button";

const CategoryCards = ({ name, img, handleBtnClick,  successMessage }) => {

  return (
    <div
      className="w-full min-h-[200px] flex flex-col justify-end bg-zinc-300"
      style={{
        backgroundImage: `url('${img[0]?.imageUrl}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="bg-white/40 mx-[5%] my-5 p-3  rounded-lg backdrop-blur-sm flex justify-between items-center">
        <h1 className="text-gray-700 font-bold text-lg">{name}</h1>
        <Button
          text="Add Photos"
          bg="#2d3436"
          rounded="md"
          handleClick={handleBtnClick}
        />
      </div>
    </div>
  );
};

export default CategoryCards;
