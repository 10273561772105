import React, { useEffect, useState } from "react";
import HorizontalTabsLayout from "../layout/HorizontalTabsLayout";
import HorizontalTab from "../components/HorizontalTab";
import PhotographersPriceTab from "../components/PhotographersPriceTab";
import BoophoPriceTab from "../components/BoophoPriceTab";
import AddPackages from "../components/AddPackages";
import { useParams } from "react-router-dom";
import axios from "axios";

// react-icons
import { RxCross1 } from "react-icons/rx";

const ApproveRegistration = ({ showModal, setShowModal, photographerId, userId, isApproved, photographer }) => {
  const params = useParams();

  const [activeTab, setActiveTab] = useState("photographersPrice");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("authToken");
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    userId,
    "photographer_services": {},
    "boopho_services": {},
    "package_services": {},
  });


  const getAllServices = () => {
    const photoUrl = `${baseUrl}/user/photographer/${photographerId}/packages`;
    const headers = {
      "x-auth-token": token,
    };

    axios
      .get(photoUrl, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        const services = response.data.data.rows;
        console.log('servi',services);
        const updatedFormData = {
        photographer_services: services[0]?.services,
        boopho_services: services[1]?.services,
        package_services: services[2]?.services,
      };

      // Update the formData state with the merged object
      setFormData(updatedFormData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSaveClick = () => {

    setSuccessMessage('');
    setErrorMessage('');
    const headers = {
      "x-auth-token": token,
    };

    const photoUrl = `${baseUrl}/user/photographer/${photographerId}/packages`;

    formData.userId = userId;
    axios
      .post(photoUrl, formData, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        console.log(response.data.data);
          if(!isApproved) {
            const apiUrl = `${baseUrl}/user/photographer`;

            const data = {
              userId: userId,
              photographerId: photographerId,
              status: "APPROVED",
            };

            axios
              .put(apiUrl, data, { headers: headers })
              .then((response) => {
                console.log(response.data);
              })
              .catch((error) => {
                console.error("Error updating status:", error);
              });
          }
          setSuccessMessage('Price Added Successfully')
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setErrorMessage(error.response.data.error.message);
      });

  };


  useEffect(() => {
    getAllServices();
  }, [photographerId]);


  return (
    <>
      {showModal && (
        <section
          className={`fixed bg-black/50 flex w-full top-0 right-0 left-0 h-full justify-center z-50`}
        >
          <div className="relative flex overflow-auto flex-col gap-5 p-3 min-w-[80vw] md:min-w-[60vw] md:max-w-[60vw] h-min max-h-full bg-bgMain rounded-lg">
            <RxCross1
              className="absolute top-6 right-2 text-red-500 text-2xl cursor-pointer"
              onClick={() => setShowModal(false)}
            />
            <HorizontalTabsLayout gap="5">
              <HorizontalTab
                text="Photographers Price"
                selected={activeTab === "photographersPrice"}
                selectedColor="#7ed957"
                unslectedColor="#9f606d"
                className="rounded-t-lg text-white !w-[30%]"
                handleClick={() => setActiveTab("photographersPrice")}
              />
              <HorizontalTab
                text="Boopho Price"
                selected={activeTab === "boophoPrice"}
                selectedColor="#7ed957"
                unslectedColor="#9f606d"
                className="rounded-t-lg text-white !w-[30%]"
                handleClick={() => setActiveTab("boophoPrice")}
              />
              {params.slug !== "editor" && (
                <HorizontalTab
                  text="Add Packages"
                  selected={activeTab === "addPackages"}
                  selectedColor="#7ed957"
                  unslectedColor="#9f606d"
                  className="rounded-t-lg text-white !w-[30%]"
                  handleClick={() => setActiveTab("addPackages")}
                />
              )}
            </HorizontalTabsLayout>
            {activeTab === "photographersPrice" ? (
              <PhotographersPriceTab
                userId={userId}
                formData={formData}
                setFormData={setFormData}
                photographer={photographer}
                // photographerPriceData={photographerPriceData}
                handleCancel={() => setShowModal(false)}
              />
            ) : activeTab === "boophoPrice" ? (
              <BoophoPriceTab
                setFormData={setFormData}
                formData={formData}
                photographer={photographer}
                // boophoPriceData={boophoPriceData}
                handleCancel={() => setShowModal(false)} />
            ) : (
              <AddPackages
                handleCancel={() => setShowModal(false)}
                formData={formData}
                setFormData={setFormData}
                handleSave={handleSaveClick}
                // packagesData={packagesData}
                successMessage={successMessage}
                errorMessage={errorMessage}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                photographer={photographer}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default ApproveRegistration;
