import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";
import axios from "axios";
import { toast } from 'react-toastify';
import { CARD_DATA } from "../helpers/constants";




const RequestedEvent = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cardData, setCardData] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const apiUrl = `${baseUrl}/bookings`;
  const token = sessionStorage.getItem("authToken");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()



  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchData();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }


  const handleDecline = async (id) => {
    try {
      const headers = {
        "x-auth-token": token,
      };

      const data = {
        status: "CANCELLED",
      };

      const response = await axios.patch(
        `${baseUrl}/booking/${id}`,
        data,
        {
          headers: headers,
        }
      );
        
      if (response.status === 200) {
        console.log(response);
        fetchData();
        toast.success('Photographer Decline Successfully', {
          position: 'top-right',
          autoClose: 3000,       
        });
      } 
    } catch (error) {
      console.error(
        "Error while adding admin:",
        error.response.data.error.message
      );
      toast.error(error.response.data.error.message, {
        position: 'top-right',
        autoClose: 3000,       
      });
    }
  };


  const handleEdit = async (id) => {
    try {
      const headers = {
        "x-auth-token": token,
      };

      const data = {
        status: "CONFIRMED",
      };

      const response = await axios.patch(
        `${baseUrl}/booking/${id}`,
        data,
        {
          headers: headers,
        }
      );
        
      if (response.status === 200) {
        console.log(response);
        fetchData();
        toast.success('Photographer Confirmed Successfully', {
          position: 'top-right',
          autoClose: 3000,       
        });
      } 
    } catch (error) {
      console.error(
        "Error while adding admin:",
        error.response.data.error.message
      );
      toast.error(error.response.data.error.message, {
        position: 'top-right',
        autoClose: 3000,       
      });
      // setErrorMessage(error.response.data.error.message);
    }


  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  
  const fetchData = async () => {
    try {
      const headers = {
        "x-auth-token": token,
      };

      const response = await axios.get(apiUrl, {
        params: {
          status: "PENDING",
          limit: resultsPerPage,
          offset: (currentPage - 1) * resultsPerPage,
          search: searchQuery,
          sortOrder: order
        },
        headers: headers,
      });

      if (response.status === 200) {
        const responseData = response.data.data;
        if (Array.isArray(responseData.rows)) {
          // Ensure rows is an array
          setCardData(responseData.rows);
          setTotalResults(responseData.count)

          setTotalPages(Math.ceil(responseData.count / 10));
        } else {
          console.error("Invalid data format: rows is not an array.");
        }
      } else {
        console.error("Unable to fetch data.");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage,order, token]);

  return (
    <>
          <SearchAndFilterPanel
            onSearch={(e) => onSearch(e)}
            searchQuery={searchQuery}
            onChangeSearch={onChangeSearch}
            handleOders={handleOders}
          />
      {cardData.length > 0 ? (
        <section>
          <h1 className="text-xl md:text-3xl text-main font-bold mb-3">
            Requested Events
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {cardData.map((item) => (
              <Card
                key={item.id}
                id={item.id}
                status={"PENDING"}
                href={`/event-timeline/${item.id}`}
                name={item?.PhotographerUser?.name}
                city={item?.PhotographerUser?.Photographer?.serviceLocation}
                phone={item?.PhotographerUser?.phoneNo}
                eventStartDate={item.startDate}
                eventEndDate={item.endDate}
                location={item.address}
                btn={true}
                btnText="Decline"
                btnBg="red"
                handleDecline={(id) => handleDecline(id)}
                editBtn={true}
                editBtnText="Confirmed"
                editBtnBg="green"
                handleEdit={handleEdit}
              />
            ))}
          </div>
          <Pagination
             total={totalResults}
             perPage={resultsPerPage}
             current={currentPage}
             onPageChange={handlePageChange}
          />
        </section>
      ) : (
        <NoResults heading="Requested Events" />
      )}


   
    </>
  );
};

export default RequestedEvent;
