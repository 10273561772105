import React, { createContext, useContext, useEffect, useState } from 'react';
import { initiateSocket } from '../Utils/socket';

// Create the session context
const SessionContext = createContext();

export const useSession = () => {
  return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem('loggedIn') === 'true'
  );
  const [userName, setUserName] = useState(
    sessionStorage.getItem('role')
  );
  const [userId, setUserId] = useState(sessionStorage.getItem('userId'));
  const [socket, setSocket] = useState(null);
  const [unReadMsg, setUnReadMsg] = useState({});

  // console.log(unReadMsg, "SessionContext:unReadMsg");

  useEffect(()=>{
    const token = sessionStorage.getItem("authToken");
    if(token) {
      let socket = initiateSocket(token, setUnReadMsg);
      setSocket(socket);
    };
  }, [loggedIn]);

  return (
    <SessionContext.Provider
      value={{ loggedIn, setLoggedIn, userName, setUserName, userId, setUserId, socket, setUnReadMsg, unReadMsg }}
    >
      {children}
    </SessionContext.Provider>
  );
};
