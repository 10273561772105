import React, { useState, useEffect } from "react";
import CRUDCard from "../components/CRUDCard";
import Pagination from "../components/Pagination";
import Button from "../components/Button";
import { MdAdd } from "react-icons/md";
import AddModal from "../modals/AddModal";
import { getAllAboutUs, createAboutUs, updateAboutUs, deleteAboutUs } from "../api";
import "./message.css";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import NoResults from "../components/NoResults";

const AboutUs = () => {
  const [showAddAbout, setShowAddAbout] = useState(false);
  const [aboutUsList, setAboutUsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedAboutUsId, setSelectedAboutUsId] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()


  const [formData, setFormData] = useState({
    heading: "",
    description: "",
    imageUrl: "", // Add a field for image URL
  });

  const token = sessionStorage.getItem("authToken");

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchAboutUsList();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchAboutUsList = () => {
    getAllAboutUs(token, resultsPerPage, currentPage, searchQuery, order)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setAboutUsList(data.rows);
        setTotalResults(data.count);
      })
      .catch((error) => {
        console.error("Error fetching About Us content:", error);
      });
  };

  useEffect(() => {
    fetchAboutUsList();
  }, [token, order,currentPage]);

  const handleEdit = (id, content) => {
    return new Promise((resolve, reject) => {
    const { heading, paragraph, imgUrl } = content;

    if (!heading.trim() || !paragraph.trim()) {
      return;
    }

    updateAboutUs(token, id, heading, paragraph, imgUrl)
      .then(() => {
        setSuccessMessage("About Us content updated successfully.");
        setShowAddAbout(false);
        fetchAboutUsList();
        resolve(true);
      })
      .catch((error) => {
        resolve(false); 
        console.error("Error updating About Us content:", error);
      });
    
});
  };

  const handleDelete = (id) => {
    deleteAboutUs(token, id)
      .then(() => {
        console.log("About Us content deleted successfully.");
        setSuccessMessage("About Us content deleted successfully.");
        fetchAboutUsList();
      })
      .catch((error) => {
        console.error("Error deleting About Us content:", error);
      });
  };

  const confirmDelete = () => {
    if (selectedAboutUsId) {
      deleteAboutUs(token, selectedAboutUsId)
        .then(() => {
          console.log("About Us content deleted successfully.");
          setSuccessMessage("About Us content deleted successfully.");
          setSelectedAboutUsId(null);
          fetchAboutUsList();
        })
        .catch((error) => {
          console.error("Error deleting About Us content:", error);
          setSelectedAboutUsId(null);
        });
    }
  };

  const handleAddAbout = () => {
    const { heading, paragraph, img } = formData;

    if (!heading.trim() || !paragraph.trim()) {
      return;
    }

    createAboutUs(token, heading, paragraph, img)
      .then(() => {
        setSuccessMessage("About Us content added successfully.");
        fetchAboutUsList();
        setShowAddAbout(false);
        setFormData({
          heading: "",
          description: "",
          imageUrl: "",
        });
      })
      .catch((error) => {
        console.error("Error adding About Us content:", error);
      });
  };

  const clearMessages = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleNextPage = () => {
    // Pagination logic for next page
  };

  const handlePrevPage = () => {
    // Pagination logic for previous page
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
    {aboutUsList.length > 0 ? (
      <section>
      <SearchAndFilterPanel
          onSearch={onSearch}
          searchQuery={searchQuery}
          onChangeSearch={onChangeSearch}
          handleOders={handleOders}
        />
   {successMessage && <div className="success-message">{successMessage}</div>}
    <div className="flex flex-col md:flex-row justify-between">
      <h1 className="text-2xl md:text-3xl text-main font-bold">About Us</h1>
      <Button
        text="Add New About"
        icon={<MdAdd className="text-lg" />}
        rounded="md"
        bg="green"
        handleClick={() => {
          setShowAddAbout(true);
          clearMessages();
        }}
      />
    </div>

    <div className="w-full grid my-4 gap-5">
      {aboutUsList.map((item, index) => (
        <CRUDCard
          key={index}
          heading={item.heading}
          paragraph={item.description}
          imageUrl={item.imageUrl} // Pass image URL
          handleEdit={(content) => handleEdit(item.id, content)}
          handleDelete={() => handleDelete(item.id)}
          defaultValue={{
            heading: item.heading,
            description: item.description,
            imageUrl: item.imageUrl,
          }}
          imgInput={true}
        />
      ))}
    </div>
    <AddModal
      showModal={showAddAbout}
      setShowModal={setShowAddAbout}
      imgInput={true}
      handleBtnClick={handleAddAbout}
      formData={formData}
      setFormData={setFormData}
      validationErrors={validationErrors}
      setValidationErrors={setValidationErrors}
      setErrorMessage={setErrorMessage}
    />
   <Pagination
           total={totalResults}
           perPage={resultsPerPage}
           current={currentPage}
           onPageChange={handlePageChange}
        />
    {selectedAboutUsId && (
      <div className="confirmation-modal">
        <p>Are you sure you want to delete this About Us content?</p>
        <button onClick={confirmDelete}>Yes</button>
        <button onClick={() => setSelectedAboutUsId(null)}>No</button>
      </div>
    )}
  </section>
    ) : (
      <NoResults heading="About Us" />
    )}
    </>
  );
};

export default AboutUs;
