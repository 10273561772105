import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TextDetail from "../components/TextDetail";
import CheckBoxDetail from "../components/CheckBoxDetail";
import ImageDetail from "../components/ImageDetail";
import Button from "../components/Button";
import RequestEdit from "../modals/RequestEdit";
import ApproveRegistration from "../modals/ApproveRegistration";
import Confirmation from "../modals/Confirmation";
import axios from "axios";

//react-icons
import { BiArrowBack } from "react-icons/bi";

const ViewDetails = () => {
  const [typesOfPhotography, setTypesOfPhotography] = useState([]);
  const [typesOfVideography, setTypesOfVideography] = useState([]);
  const [occasionCategories, setOccasionCategories] = useState([]);
  const [categoryImages, setCategoryImages] = useState({});
  const [favoritePhoto, setFavoritePhoto] = useState([]);
  const navigate = useNavigate();
  const { slug } = useParams();
  console.log(slug, "SLUg");
  const [showApproveRegistration, setShowApproveRegistration] = useState(false);
  const [showRequestEdit, setShowRequestEdit] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [photographer, setPhotographer] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("authToken");
  const [approved, setApproved] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPlaceId, setNewPlaceId] = useState("");
  const handleRequestEdit = (comment) => {
    console.log(comment);

    const apiUrl = `${baseUrl}/user/photographer/${slug}/edit-request`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    axios
      .post(
        apiUrl,
        {
          adminComment: comment,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setSuccessMessage(response.data.message);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setErrorMessage(error.response.data.error.message);
      });
  };

  const handleReject = () => {};

  const fetchPhotographers = () => {
    // Define the API URL
    const apiUrl = `${baseUrl}/user/photographer/${slug}`;
    const headers = {
      "x-auth-token": token, // Replace with your actual token
    };

    axios
      .get(apiUrl, {
        headers: headers, // Pass the headers object here
      })
      .then((response) => {
        console.log(response.data.data);
        setPhotographer(response.data.data);
        // photographer?.placeId
        const photographerData = response.data.data;
        setNewPlaceId(photographerData?.placeId);

        console.log(photographerData.typesOfPhotography);
        console.log("this is a photographer", photographerData);

        // Update the state with dynamic data
        setTypesOfPhotography(photographerData.typesOfPhotography);
        setTypesOfVideography(photographerData.typesOfVideography);
        setFavoritePhoto(photographerData.FavouritePictures);
        const data = Object.keys(photographerData.category);
        const category = [];

        data.forEach((id) => {
          if (id === "17") {
            category.push({ id: id, name: "Wedding" });
          } else if (id === "18") {
            category.push({ id: id, name: "Maternity" });
          } else if (id === "22") {
            category.push({ id: 22, name: "New-Born Baby" });
          } else if (id === "24") {
            category.push({ id: 24, name: "Birthday" });
          } else {
            category.push({ id: 25, name: "Business" });
          }
        });

        setOccasionCategories(category);
        setCategoryImages(photographerData.category);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  console.log(occasionCategories, "occasionCategories");

  useEffect(() => {
    fetchPhotographers(token);
  }, [token]);

  const handlePlaceIdUpdate = () => {
    if (newPlaceId.length > 0) {
      const apiUrl = `${baseUrl}/user/photographer/${slug}`;
      const headers = {
        "x-auth-token": token, // Replace with your actual token
      };

      axios
        .put(
          apiUrl,
          {
            placeId: newPlaceId,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          setSuccessMessage(response.data.message);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setErrorMessage(error.response.data.error.message);
        });
    } else {
      alert("field cannot be empty");
    }
  };

  return (
    <section className="flex flex-col gap-8 p-4">
      <div>
        <button
          className="flex items-center gap-1 bg-secondary rounded-md text-white text-sm py-1 px-3"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack /> <span>Back</span>
        </button>
      </div>
      <TextDetail
        label="Name"
        value={photographer.User?.name}
        required={true}
      />
      <TextDetail
        label="Contact Number"
        value={photographer.User?.phoneNo}
        required={true}
      />
      <TextDetail
        label="E-Mail ID"
        value={photographer.User?.email}
        required={true}
      />
      <TextDetail
        label="Studio Name"
        value={photographer.studioName}
        required={true}
      />
      <TextDetail
        label="Service Location"
        value={photographer.serviceLocation}
        required={true}
      />
      <TextDetail
        label="Experience in photography"
        value={photographer.experienceInPhotography}
        required={true}
      />
      <TextDetail
        label="Languages Known"
        value={photographer.languagesKnown}
        required={true}
      />
      {/* <CheckBoxDetail
        label="Type of Photography"
        value={TYPE_OF_PHOTOGRAPHY}
        required={true}
      />
      <CheckBoxDetail
        label="Type of Videography"
        value={TYPE_OF_VIDEOGRAPHY}
        required={true}
      />
      <CheckBoxDetail label="Occasion" value={OCCASSION} required={true} />
      <ImageDetail
        label="Wedding Photos and Videos"
        required={false}
        value={WEDDING_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="Maternity Photos and Videos"
        required={false}
        value={MATERNITY_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="New Born Photos and Videos"
        required={false}
        value={NEW_BORN_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="Birthday Photos and Videos"
        required={false}
        value={BIRTHDAY_PHOTOS_VIDEOS}
      />
      <ImageDetail
        label="Business Photos and Videos"
        required={false}
        value={BUSINESS_PHOTOS_VIDEOS}
      /> */}

      <CheckBoxDetail
        label="Type of Photography"
        value={typesOfPhotography}
        required={true}
      />
      <CheckBoxDetail
        label="Type of Videography"
        value={typesOfVideography}
        required={true}
      />
      {/* Comment code because code split */}
      <CheckBoxDetail
        label="Occasion"
        value={occasionCategories.map(
          (occasionCategory) => occasionCategory.name
        )}
        required={true}
      />

      {/* Render category images dynamically */}
      {/* Comment code because code split */}
      {occasionCategories.map((occasionCategory) => (
        <ImageDetail
          key={occasionCategory.id}
          label={`${occasionCategory.name}`}
          required={false}
          value={categoryImages[occasionCategory.id]}
          categoryId={occasionCategory.id}
          favPhoto={favoritePhoto}
        />
      ))}
      <TextDetail
        label="Willing to Travel"
        value={photographer.willingToTravel || "No"}
        required={true}
      />
      <TextDetail
        label="Which State You can travel"
        value={photographer?.States?.map((obj) => obj.name).join(", ")}
        required={true}
      />
      <TextDetail
        label="Delivery Time"
        value={photographer.deliveryTime}
        required={true}
      />
      <TextDetail
        label="Are you an individual/ a team"
        value={photographer.individualOrTeam}
        required={true}
      />
      <TextDetail
        label="Number of members in the team"
        value={photographer.numberOfTeamMembers}
        required={true}
      />

      <div className="grid grid-flow-row gap-2 md:gap-0 md:grid-flow-col md:grid-cols-3 ">
        <p className="text-sm md:col-span-1 text-gray-600">
          Google Place Id {<span className="text-red-500">*</span>}
        </p>
        <p className="text-sm md:col-span-2 flex flex-row justify-between text-secondary bg-white p-2">
          {/* {value} */}
          <input
            type="text"
            className={`form-control outline-none focus:outline-none w-1/2`}
            placeholder=""
            name="businessLink"
            value={newPlaceId}
            onChange={(e) => {
              setNewPlaceId(e.target.value);
            }}
          />
          <Button
            text="Update Google Id"
            bg="rgb(98 8 8)"
            rounded="sm"
            handleClick={() => handlePlaceIdUpdate()}
          />
        </p>
      </div>

      <TextDetail
        label="Any highlights about your services"
        value={photographer.anyHighligths}
      />
      <TextDetail
        label="About You"
        value={photographer.aboutYou}
        required={true}
      />
      <TextDetail
        label="Any Additional Info you would like to provide us ?"
        value={
          photographer?.additionalInfo
            ? photographer?.additionalInfo
            : "No Info"
        }
      />

      <div className="w-full flex justify-evenly">
        {photographer.status !== "APPROVED" &&
          photographer.status !== "REJECT" && (
            <Button
              text="Submit Price"
              bg="#7ed957"
              rounded="sm"
              handleClick={() => setShowApproveRegistration(true)}
            />
          )}
        {photographer.status === "APPROVED" && (
          <Button
            text="Manage"
            bg="#f1dfb7"
            color="#a66871"
            rounded="sm"
            handleClick={() => {
              setApproved(true);
              setShowApproveRegistration(true);
            }}
          />
        )}
        {photographer.status !== "REJECT" && (
          <Button
            text="Request edit"
            bg="#f1dfb7"
            color="#a66871"
            rounded="sm"
            handleClick={() => setShowRequestEdit(true)}
          />
        )}

        {photographer.status !== "APPROVED" &&
          photographer.status !== "REJECT" && (
            <Button
              text="Reject"
              bg="#ff3131"
              rounded="sm"
              handleClick={() => setShowReject(true)}
            />
          )}
      </div>
      <RequestEdit
        showModal={showRequestEdit}
        setShowModal={setShowRequestEdit}
        handleBtnClick={handleRequestEdit}
        successMessage={successMessage}
        errorMessage={errorMessage}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />

      <ApproveRegistration
        showModal={showApproveRegistration}
        setShowModal={setShowApproveRegistration}
        photographerId={photographer.id}
        userId={photographer.User?.id}
        isApproved={approved}
        photographer={photographer}
      />

      <Confirmation
        heading="Are you sure?"
        text="Are you sure you want to Reject?"
        btnText="Reject"
        handleConfirm={handleReject}
        showModal={showReject}
        setShowModal={setShowReject}
        photographerId={photographer.id}
        userId={photographer.User?.id}
      />
    </section>
  );
};

export default ViewDetails;
