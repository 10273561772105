import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../components/Button";

const SetEventDate = ({ showModal, setShowModal, handleBtnClick }) => {
  const [eventName, setEventName] = useState("");

  const handleSave = () => {
    handleBtnClick(eventName);
    setEventName("");
  };

  const closeModal = () => {
    setShowModal(false);
    setEventName("");
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-5 p-3 min-w-[80vw] md:min-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Set Event Title</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <div className="flex flex-col gap-3">
              {/* <label htmlFor="eventName">Event Name</label> */}
              <input
                id="eventName"
                type="text"
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                className="w-full outline-none p-2 border border-gray-300"
                placeholder="Event Name"
              />
              <div className="mx-auto flex gap-2">
                <Button text="Save" rounded="md" handleClick={handleSave} />
                <Button
                  text="Cancel"
                  rounded="md"
                  handleClick={() => setShowModal(false)}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SetEventDate;
