import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import Image from "../components/Image";

// these are dynamic pages and data are on behalf on helpers: MY_FILES & SHARED_FILES
// example url of this page:
// http://localhost:3000/my-footage/my-files/58814/photos

const CustomerFiles = () => {
  const params = useParams();

  const [customer, setCustomer] = useState("");
  const [assets, setAssets] = useState([]);
  const [filterAssets, setFilterAssets] = useState([]);
  const [showOpenImage, setShowOpenImage] = useState(false);
  const [image, setImage] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedSortFilter, setSelectedSortFilter] = useState("See All");
  const [selectedFilter, SetSelectedFilter] = useState("");
  const [allFavourites, setAllFavourites] = useState([]);
  const [approved, setApproved] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL_NON_ADMIN;
  const token = sessionStorage.getItem("authToken");
  console.log(selectedSortFilter, "==selectedSortFilterselectedSortFilter");
  const location = useLocation();

  const { myData } = location.state;

  useEffect(() => {
    // const customer = files.find((item) => item.id == params.id);
    // setCustomer(customer);
    // setSelectedFiles(new Array(customer[params.folder].length).fill(false));
    // setAssets(customer[params.folder]);
    setImageLoading(true);
    console.log(
      params.slug,
      " = params.slug, ",
      params.id,
      " = params.id, ",
      params.folder,
      " = folder"
    );

    let hash = params.id;

    if (params.id === "editor-uploads") {
      hash = hash + "/" + params.folder;
    }

    const headers = {
      "x-auth-token": token,
    };
    axios
      .get(
        `${baseUrl}/share/get-all-files-from-s3/${encodeURIComponent(hash)}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res.data, "res:get-all-files");
        // const images = res.data.data?.filter(imageUrl => imageExtensions.includes(imageUrl.slice(-3).toLocaleLowerCase()));
        // setAssets(images);
        setAssets(res.data.data);
        setFilterAssets(res.data.data);
        setImageLoading(false);
      })
      .catch((error) => {
        setImageLoading(false);
      });
  }, []);

  const openImage = (index) => {
    setImage(customer[params.folder][index]);
    setShowOpenImage(true);
  };

  const downloadImg = (src) => {
    let anchorTag = document.createElement("a");
    document.body.appendChild(anchorTag);
    fetch(src)
      .then((res) => res.blob())
      .then((value) => {
        const url = URL.createObjectURL(value);

        anchorTag.href = url;
        anchorTag.download = "my_image.png";

        anchorTag.click();
      })
      .catch((err) => console.log(err));

    document.body.removeChild(anchorTag);
  };

  const handleImageSelect = (index) => {
    const updatedImagesSelection = [...selectedFiles];
    updatedImagesSelection[index] = !updatedImagesSelection[index];
    setSelectedFiles(updatedImagesSelection);
  };

  const handleApprove = (id) => {
    const headers = {
      "x-auth-token": token,
    };
    axios
      .put(
        `${baseUrl}/share/photographer-shares/${id}/approval`,
        {
          approved: true,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        console.log(res.data, "res:get-all-files");
        setApproved(true);
      })
      .catch((error) => {
        setImageLoading(false);
      });
  };

  return (
    <>
      <section className="w-[80%] mx-auto p-4 min-h-full">
        <div className="w-full ">
          {/* <BackButton /> */}
          <div
            className="flex flex-row justify-between w-100 align-middle"
            style={{ width: "100%" }}
          >
            <h1 className="md:text-3xl text-xl text-main font-bold text-center">
              {/* {customer.folderName} */}
              {myData?.User?.name}
            </h1>

            <div className="flex flex-row">
              <h3>
                Event Timeline :{" "}
                {`${myData?.Booking?.startDate} - ${myData?.Booking?.endDate}`}
              </h3>
              <button
                className={`${
                  !approved ? "bg-main" : "bg-green-500"
                } text-white px-3 py-1 ml-3`}
                id="basic-addon1"
                type="submit"
                onClick={() => handleApprove(myData.id)}
              >
                Approve Footage
              </button>
            </div>
          </div>
        </div>
        <div></div>

        <div className="grid gap-3 grid-cols-2 md:grid-cols-5 my-10 ">
          {imageLoading ? (
            <p>Loading...</p>
          ) : selectedSortFilter === "My Favorites" &&
            filterAssets?.length === 0 ? (
            <p>No Favorites Image Found</p>
          ) : (
            filterAssets?.map((item, index) => {
              return (
                <Image
                  key={item}
                  src={`${
                    process.env.REACT_APP_NEW_BACKEND_BASE_URL
                  }/api/files/${encodeURIComponent(item)}`}
                  alt="photo"
                  item={item}
                  allFavourites={allFavourites}
                  setAllFavourites={(data) => setAllFavourites(data)}
                  id={Date.now() + index}
                  open={() => openImage(index)}
                  download={() => downloadImg(item)}
                  share={() => setShowShare(true)}
                  onlyOpenOption={params.slug === "shared-files"}
                  onSelect={() => handleImageSelect(index)}
                  addToFolder={() => setShowAddFolder(true)}
                />
              );
            })
          )}
        </div>
      </section>
      {/* <OpenImage
        showModal={showOpenImage}
        setShowModal={setShowOpenImage}
        user="Rahul"
        download={() => downloadImg(image)}
        // allComments={comments}
        share={() => setShowShare(true)}
        image={image}
      /> */}
    </>
  );
};

export default CustomerFiles;
