import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

//react-icons
import { IoLocationSharp } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";
import Confirmation from "../modals/Confirmation";

const Card = ({
  name,
  id,
  city,
  phone,
  eventStartDate,
  eventEndDate,
  location,
  status,
  href,
  btn = false,
  btnText,
  handleDecline,
  btnBg,
  editBtnBg,
  editBtn = false,
  editBtnText,
  handleEdit,
  handleStartEvent,
  handleToUpcomingEvent
}) => {

  const [modalDelete, setModalDelete] = useState(false);
  const [startEventModal, setStartEventModal] = useState(false);
  const [confirmedModal, setConfirmedModal] = useState(false);
  const [upcomingEventModal, setUpcomingEventModal] = useState(false);

  const handleBtnClick = () => {
    setModalDelete(true);
  };

  const handleStartEventClick = () => {
    setStartEventModal(true);
  };

  const handleConfirmedClick = () => {
    setConfirmedModal(true);
  };

  const handleUpcomingEventClick = () => {
    setUpcomingEventModal(true);
  };

console.log(href, "hrefffffffff")
  return (
    <>

    <Link to={`${href}=${status}`} className="bg-white p-4 rounded-md shadow-md">
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <p className="text-xl text-main font-bold">{name}</p>
          <p className="text-sm">{phone}</p>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <IoLocationSharp />
            <p className="text-xs">{city}</p>
          </div>
          <p className="text-xs">
            <b>Event Date:</b> {eventStartDate} - {eventEndDate}
          </p>
        </div>
        <p className="text-xs">{location}</p>
        <div className="flex justify-end z-10">
            {editBtnText == "Start Event" && (
              <Button text={editBtnText} bg={editBtnBg} rounded="md" handleClick={handleStartEventClick} />
            )}
            {btnText == "Decline" && (
              <Button text={btnText} bg={btnBg} rounded="md" handleClick={handleBtnClick} />
            )}
            {editBtnText == "Confirmed" && (
              <Button text={editBtnText} bg={editBtnBg} rounded="md" handleClick={handleConfirmedClick} />
            )}
            {btnText == "Upcoming Event" && (
              <Button text={btnText} bg={btnBg} rounded="md" handleClick={handleUpcomingEventClick} />
            )}
          </div>
       
      </div>
    </Link>

    <Confirmation
        showModal={modalDelete}
        setShowModal={setModalDelete}
        heading="Are you sure?"
        text="Are you sure you want to Decline this photographer?"
        btnText="Decline"
        handleConfirm={() => handleDecline(id)}
      />

      <Confirmation
        showModal={startEventModal}
        setShowModal={setStartEventModal}
        heading="Are you sure?"
        text="Are you sure you want to start this event?"
        btnText="Sure"
        handleConfirm={() => handleStartEvent(id)}
      />

      <Confirmation
        showModal={confirmedModal}
        setShowModal={setConfirmedModal}
        heading="Are you sure?"
        text="Are you sure you want to Confirmed this photographer?"
        btnText="Confirm"
        handleConfirm={() => handleEdit(id)}
      />

      <Confirmation
        showModal={upcomingEventModal}
        setShowModal={setUpcomingEventModal}
        heading="Are you sure?"
        text="Are you sure you want to go back to the upcoming event?"
        btnText="Sure"
        handleConfirm={() => handleToUpcomingEvent(id)}
      />
  </>
);
};

export default Card;
