import React from "react";

const Button = ({
  bg = "#9f606d",
  color = "#ffffff",
  rounded,
  text,
  handleClick,
  type = "button",
  icon,
}) => {
  const borderRadiusClass = rounded
    ? rounded === "sm"
      ? "rounded-sm"
      : rounded === "md"
      ? "rounded-md"
      : "rounded-lg"
    : "";

  return (
    <button
      className={`${borderRadiusClass} ${
        icon ? "flex items-center justify-center gap-1" : ""
      } bg-secondary px-6 py-2 text-sm capitalize`}
      onClick={handleClick}
      style={{ backgroundColor: bg, color: color }}
      type={type}
    >
      {icon}
      {text}
    </button>
  );
};

export default Button;
