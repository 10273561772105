import React, { useContext, useEffect, useState } from "react";
import Chat from "../components/Chat";
import authApi from "../Utils/axiosConfig";
import { getSocket } from "../Utils/socket";


// helpers
import { CUSTOMER_CHATS } from "../helpers/constants";
import { CustomerContext } from "../ContextApi/Customer";
import { useSession } from "../components/SessionContext";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";
import { MdDelete } from "react-icons/md";


const CustomerChats = () => {
  const [customers, setCustomer] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { data: newMsgCounts, setData } = useContext(CustomerContext);
  const { socket: newSocket, unReadMsg } = useSession();
  const socket = getSocket();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()


  // console.log(newSocket, "===newSocketnewSocket:alop");

  // useEffect(()=>{
  //   newSocket?.on("unread_msg_count", (data) => {
  //     console.log("unread_msg_count:cus", data);
  //   });
  // }, [newSocket]);
  const fetchChat = () => {
    authApi.get("/user/customers", {
      params: {
        limit: resultsPerPage,
        offset: (currentPage - 1) * resultsPerPage,
        search: searchQuery,
        sortOrder: order
      },
    })
      .then(res => {
        console.log(res.data.data.rows, "customersssssss:ress");
        setCustomer(res?.data?.data?.rows || []);

        setTotalResults(res?.data?.data?.count)

        setError(null);
      }).catch(err => {
        console.log(err, "customersssssss:error");
        // setError({ message: "Can't fetch customer's data" });
      });
  }

  useEffect(() => {
    fetchChat()
  }, [currentPage, order]);

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchChat();
  };
  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  return (
    <>
      <section>
        <h1 className="text-2xl md:text-3xl text-main font-bold mb-8">
          Customer Chats
        </h1>
        <div className="w-full grid grid-cols-1">
          <SearchAndFilterPanel
            onSearch={(e) => onSearch(e)}
            searchQuery={searchQuery}
            onChangeSearch={onChangeSearch}
            handleOders={handleOders}
          />

          {customers?.length === 0 && (
            <div className="flex justify-center items-center w-full ">

              <NoResults
                text="No results found"
                icon={<MdDelete className="text-lg" />}
                rounded="md"
              />
            </div>

          )}

          {customers?.map((item, index) => {
            return (
              <>

                <Chat
                  key={index}
                  customer={item}
                  profilePic={item.profilePic}
                  customerName={item.name}
                  lastMsg={item.lastMsg}
                  lastMsgTime={item.lastMsgTime}
                  unread={item.unread}
                  newMsgCount={unReadMsg[item.id] ?? 0}
                />
              </>
            );
          })}
        </div>

           {customers?.length !== 0 && (
          <Pagination
            total={totalResults}
            perPage={resultsPerPage}
            current={currentPage}
            onPageChange={handlePageChange}
          />)}
      </section>

    </>
  );
};

export default CustomerChats;
