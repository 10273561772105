import React from "react";
import Button from "./Button";
import StarRatings from "react-star-ratings";
import { IoLocationSharp } from "react-icons/io5";

const FullWidthCard = ({
  text,
  city,
  phone,
  showBtn = true,
  showRating = false,
  rating,
  btnText,
  btnHandleClick,
  email
}) => {
  return (
    <div className="w-full flex flex-col gap-4 p-4 my-1 bg-white border border-[#FFE6D4]">
      <div className="flex justify-between">
        <h1 className="text-2xl font-semibold text-main">{text}</h1>
        {showRating && (
          <StarRatings
            rating={rating}
            starRatedColor="#ffa534"
            numberOfStars={5}
            name="rating"
            starDimension="20px"
            starSpacing="2px"
          />
        )}
      </div>
      <div className="flex gap-1">
        <IoLocationSharp />
        <p className="text-xs text-main">{city}</p>
      </div>
      <div className="w-full flex justify-between">
        <p>{phone}</p>
        {email && <p>{email}</p>}
        {showBtn && (
          <Button text={btnText} rounded="lg" handleClick={btnHandleClick} />
        )}
      </div>
    </div>
  );
};

export default FullWidthCard;
