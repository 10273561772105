import React, { useState, useEffect } from "react";
import HorizontalTabsLayout from "../layout/HorizontalTabsLayout";
import HorizontalTab from "../components/HorizontalTab";
import RegistrationPendingTabs from "../components/RegistrationPendingTabs";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";

import axios from "axios";

const RegistrationPending = () => {
  const [activeTab, setActiveTab] = useState("photographer");

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [pendingPhotographer, setPendingPhotographer] = useState([]);
  const token = sessionStorage.getItem("authToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage = 10; // Number of results per page
  const [order, setOrders] = useState()

  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchPhotographers();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  const fetchPhotographers = () => {
    const apiUrl = `${baseUrl}/user/photographers`;
    const headers = {
      "x-auth-token": token,
    };

    const params = {
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
      status: "PENDING",
      search: searchQuery,
      sortOrder: order

    };

    axios
      .get(apiUrl, {
        params: params,
        headers: headers,
      })
      .then((response) => {
        setPendingPhotographer(response.data.data.rows);
        setTotalResults(response.data.data.count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchPhotographers(currentPage, itemsPerPage, token);
  }, [currentPage, order, resultsPerPage, token]);

  return (
    <section className="w-full">
      <HorizontalTabsLayout>
        <HorizontalTab
          text={"Photographers"}
          selected={activeTab === "photographer"}
          handleClick={() => setActiveTab("photographer")}
        />{" "}
        {/* <HorizontalTab
          text={"Editors"}
          selected={activeTab === "editor"}
          handleClick={() => setActiveTab("editor")}
        /> */}
      </HorizontalTabsLayout>
      <SearchAndFilterPanel
        onSearch={onSearch}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
        handleOders={handleOders}
      />
      {pendingPhotographer.length > 0 ? (
        <>
          {/* {activeTab === "photographer" ? ( */}
          <RegistrationPendingTabs pendingUserList={pendingPhotographer} />
          {/*  ) : (  <RegistrationPendingTabs pendingUserList={editors} /> */}
          {/* )} */}
          <Pagination
             total={totalResults}
             perPage={resultsPerPage}
             current={currentPage}
             onPageChange={handlePageChange}
          />
        </>
      ) : (
        <NoResults />
      )}
    </section>
  );
};

export default RegistrationPending;
