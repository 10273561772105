import React, { useEffect, useState, useRef } from "react";

//components
import Button from "../components/Button";

// react-icons
import { RxCross1 } from "react-icons/rx";
import { FiUpload } from "react-icons/fi";

//assets
import galleryIcon from "../assets/galleryicon.png";

const EditExclusiveDeal = ({
  showModal,
  setShowModal,
  handleSave,
  defaultValues,
}) => {
  const fileUploadRef = useRef();

  const [content, setContent] = useState({
    heading: defaultValues?.heading,
    intro: defaultValues?.intro,
    termsNconditions: defaultValues?.terms,
  });

  const [img, setImg] = useState(defaultValues?.img);
  const [imgFile, setImgFile] = useState('');

  const handleChange = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const handleImgInput = (e) => {
    let file = e.target.files[0];

    let reader = new FileReader();

    reader.onload = (e) => {
      setImg(e.target.result);
    };

    setImgFile(file);

    reader.readAsDataURL(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave({ ...content, imgFile });
    setContent({
      heading: defaultValues?.heading,
      intro: defaultValues?.intro,
      termsNconditions: defaultValues?.terms,
    });
    setImg(defaultValues?.img);
    setShowModal(false);
  };

  const cancelChanges = () => {
    setShowModal(false);
    setContent({
      heading: defaultValues?.heading,
      intro: defaultValues?.intro,
      termsNconditions: defaultValues?.terms,
    });
    setImg(defaultValues?.img);
  };

  const closeModal = () => {
    setShowModal(false);
    setContent({
      heading: defaultValues?.heading,
      intro: defaultValues?.intro,
      termsNconditions: defaultValues?.terms,
    });
    setImg(defaultValues?.img);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={`fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <form
            className="overflow-auto fixed h-screen z-20 flex flex-col gap-1 pb-8 min-w-[80vw] max-w-[80vw] md:min-w-[60vw] md:max-w-[60vw] bg-bgMain rounded-lg"
            onSubmit={handleSubmit}
          >
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Update Exlusive Deal</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
            <div className="w-[90%] m-auto flex flex-col gap-1">
              {/* Img Upload Starts here*/}
              <div className="flex flex-col gap-3 justify-center items-center border-black rounded-md w-full m-auto">
                <div className=" flex justify-center max-h-[150px] overflow-auto">
                  <img
                    src={img ? img : galleryIcon}
                    alt=""
                    className="w-full h-full "
                  />
                </div>
                <button
                  className="bg-gray-700 flex items-center gap-1 px-4 py-2 text-white rounded-md"
                  type="button"
                  onClick={() => fileUploadRef.current.click()}
                >
                  <FiUpload />
                  Upload Image
                </button>
                <input
                  type="file"
                  ref={fileUploadRef}
                  hidden
                  onChange={handleImgInput}
                />
              </div>
              {/* Img Upload ends here */}

              {/* Heading input starts here */}
              <div className="flex flex-col gap-1 w-full m-auto">
                <p className="text-gray-500 text-sm min-w-max">Heading</p>
                <input
                  type="text"
                  className="resize-none border border-gray-300 bg-white p-2 outline-none text-sm w-full"
                  name="heading"
                  value={content.heading}
                  onChange={handleChange}
                  placeholder="Heading"
                />
              </div>
              {/* Heading input ends here */}

              {/* Intro input starts here */}
              <div className="flex flex-col gap-1 w-full m-auto">
                <p className="text-gray-500 text-sm min-w-max">Intro</p>
                <textarea
                  type="text"
                  className="resize-none border border-gray-300 bg-white p-2 outline-none text-sm w-full"
                  name="intro"
                  value={content.intro}
                  onChange={handleChange}
                  placeholder="Intro"
                  rows={12}
                />
              </div>
              {/* Intro input ends here */}

              {/* Terms & Conditions input starts here */}
              {/* <div className="flex flex-col gap-1 w-full m-auto">
                <p className="text-gray-500 text-sm min-w-max">
                  Terms & Conditions
                </p>
                <textarea
                  type="text"
                  className="resize-none border border-gray-300 bg-white p-2 outline-none text-sm w-full"
                  name="termsNconditions"
                  value={content.termsNconditions}
                  onChange={handleChange}
                  placeholder="Terms and Conditions"
                  rows={12}
                />
              </div> */}
              {/* Terms & Conditions input ends here */}
            </div>

            <div className="flex gap-3 justify-center">
              <Button text="Cancel" type="button" handleClick={cancelChanges} />
              <Button text="Save" type="submit" />
            </div>
          </form>
        </section>
      )}
    </>
  );
};

export default EditExclusiveDeal;
