import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ dataLabels, chartData, label, title }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  const data = {
    labels: dataLabels,
    datasets: [
      {
        label: label,
        data: chartData,
        backgroundColor: "#2d8bba",
      },
    ],
  };
  return (
    <div>
      {" "}
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
