import React, { useState } from "react";
import Button from "../components/Button";

//react-icons
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ChangePassword = () => {
  const [oldPass, setOldPass] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);

  return (
    <form>
      <h1 className="text-main font-bold text-3xl">Security Details</h1>
      {/* Password and Confirm Password Fields */}
      <div className="flex flex-col gap-3 mt-7">
        <div className="relative md:w-1/3 w-full flex flex-col my-3 md:my-0">
          <input
            type={!oldPass ? "password" : "text"}
            name="oldPass"
            required
            className="border border-gray-300 pl-3 py-3 shadow-sm bg-white rounded text-sm focus:outline-none placeholder-gray-500 text-gray-500 "
            placeholder="Old Password"
          />
          <button
            type="button"
            className="absolute top-[33%] right-2"
            onClick={() => setOldPass(!oldPass)}
          >
            {!oldPass ? <AiFillEye /> : <AiFillEyeInvisible />}
          </button>
        </div>

        <div className="relative md:w-1/3 w-full flex flex-col my-3 md:my-0">
          <input
            type={!newPass ? "password" : "text"}
            name="newPass"
            required
            className="border border-gray-300  pl-3 py-3 shadow-sm bg-white rounded text-sm focus:outline-none placeholder-gray-500 text-gray-500 "
            placeholder="New Password"
          />
          <button
            type="button"
            className="absolute top-[33%] right-2"
            onClick={() => setNewPass(!newPass)}
          >
            {!newPass ? <AiFillEye /> : <AiFillEyeInvisible />}
          </button>
        </div>

        <div className="relative md:w-1/3 w-full flex flex-col my-3 md:my-0">
          <input
            type={!confirmPass ? "password" : "text"}
            name="confirmPass"
            required
            className="border border-gray-300  pl-3 py-3 shadow-sm bg-white rounded text-sm focus:outline-none placeholder-gray-500 text-gray-500 "
            placeholder="Confirm Password"
          />
          <button
            type="button"
            className="absolute top-[33%] right-2"
            onClick={() => setConfirmPass(!confirmPass)}
          >
            {!confirmPass ? <AiFillEye /> : <AiFillEyeInvisible />}
          </button>
        </div>

        <div>
          <Button text="Save" type="submit" rounded="md" />
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
