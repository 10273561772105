import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

// react icons
import { IoMdNotifications } from "react-icons/io";
import { Link } from "react-router-dom";

// helpers
import { NOTIFICATION_MESSAGES } from "../helpers/constants";

const NotificationMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const notifications = NOTIFICATION_MESSAGES.slice(0, 5);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenMenu(false)}>
      <div className="relative flex flex-col">
        <IoMdNotifications
          className="cursor-pointer"
          onClick={() => setOpenMenu(!openMenu)}
        />
        <ul
          className={`${
            openMenu ? "opacity-1 flex" : "opacity-0 hidden"
          } flex-col absolute rounded-md right-0 top-[200%] text-xs w-[200px] bg-white shadow-lg `}
        >
          {notifications?.map((item, index) => {
            return (
              <Link
                to="/notifications"
                className=" text-gray-500 w-full hover:bg-gray-100 px-3 py-3 "
                key={index}
              >
                {item}
              </Link>
            );
          })}
        </ul>
      </div>{" "}
    </OutsideClickHandler>
  );
};

export default NotificationMenu;
