import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../components/Button";

const AddUser = ({
  showModal,
  setShowModal,
  btnText,
  handleBtnClick,
  setErrorMessage,
  errorMessage,
}) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [passwordValidation, setPasswordValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');

  console.log("error", errorMessage);
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const closeModal = () => {
    setShowModal(false);
    setUser({
      email: "",
      password: "",
    });
    setErrorMessage("");
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-1 pb-8 min-w-[80vw] md:min-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Message</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <div className="flex flex-col items-center gap-3 rounded-md w-3/4 m-auto">
              <p className="text-main">Enter Email Id</p>
              <input
                type="email"
                className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full"
                name="email"
                value={user.email}
                onChange={handleChange}
              />
            </div>
            {user.email === "" && emailValidation && (
              <p style={{ color: "red", marginLeft: "60px" }}>
                Email is Required
              </p>
            )}

            <div className="flex flex-col items-center gap-3 rounded-md w-3/4 m-auto">
              <p className="text-main">Enter Password</p>
              <input
                type="password"
                className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
            </div>
            {user.password === "" && emailValidation && (
              <p style={{ color: "red", marginLeft: "60px" }}>
                Password is Required
              </p>
            )}

            {errorMessage && (
              <p className="text-xs text-red-500 mx-auto">{errorMessage}</p>
            )}

            <div className="text-center">
              <Button
                text={btnText}
                handleClick={() =>
                  handleBtnClick(
                    user,
                    setEmailValidation,
                    setPasswordValidation
                  )
                }
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AddUser;
