import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";

//components
import Button from "../components/Button";

// react-icons
import { RxCross1 } from "react-icons/rx";

//helpers
import { CATEGORIES, INDIA_STATES } from "../helpers/constants";

const EditCouponCode = ({
  showModal,
  editCoupon,
  setShowModal,
  editCodeFunc,
}) => {
  const [amount, setAmount] = useState("");
  const [checkBoxesInput, setCheckBoxesInput] = useState({
    lifetimeChecked: false,
    moreThanLacChecked: false,
  });
  const [categorySelected, setCategorySelected] = useState("");
  const [dateStarts, setDateStarts] = useState("");
  const [dateEnds, setDateEnds] = useState("");
  const [statesOptions, setStatesOptions] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const [isUltra, setIsUltra] = useState(false);
  const [selectedStates, setSelectedStates] = useState([]);
  const [code, setCode] = useState("");
  const baseUrl = process.env.REACT_APP_URL;
  const currentDate = new Date().toISOString().split("T")[0];

  const couponId = editCoupon?.id;

  console.log(editCoupon, "stateeeeeeeeessva")

  const handleCheck = (e) => {
    setCheckBoxesInput({
      ...checkBoxesInput,
      [e.target.name]: e.target.checked,
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Helper function to format the date
  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  useEffect(() => {
      const generateOptions = () => {
        let options = [];
  
        const apiUrl = `${baseUrl}/state/states`;
  
      axios
        .get(apiUrl)
        .then((response) => {
          const data = response.data.data;
          console.log('states', data);
          
          data.map((item) => {
            let option = {
              value: item.id,
              label: item.name,
            };
            options.push(option);
          });
  
          setStatesOptions(options);
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
        });
    };

    generateOptions();
  }, []);

  useEffect(() => {
    if (editCoupon) {
      setAmount(editCoupon.amount || "");
      setCheckBoxesInput({
        lifetimeChecked: editCoupon.applicableToLifeTime || false,
        moreThanLacChecked: editCoupon.applicableToPhotography || false,
      });
      setCategorySelected(editCoupon.category || "");
      setDateStarts(formatDate(editCoupon.startDate) || "");
      setDateEnds(formatDate(editCoupon.endDate) || "");
      setIsFree(editCoupon.isFree || false);
      setIsPro(editCoupon.isPro || false);
      setIsUltra(editCoupon.isUltra || false);
      setSelectedStates(editCoupon?.States[0]?.name || "");
      setCode(editCoupon?.code|| "");
    }
  }, [editCoupon]);

  return (
    <>
      {showModal && (
        <section className={`fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed z-20 flex flex-col gap-1 pb-8 min-w-[80vw] md:min-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-center w-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">Edit Coupon</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
            <div className="flex flex-col w-3/4 mx-auto gap-4 my-3">
              {/* Amount Input Starts */}
              <div>
                <input
                  className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full placeholder:text-secondary"
                  type="number"
                  name="amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>
              {/* Amount Input Ends */}
              <div className="flex flex-col justify-start">
                <label htmlFor="categories" className="text-sm text-secondary">
                  Code
                </label>
                <input
                  className="border border-gray-300 bg-white rounded-md p-2 outline-none text-sm w-full placeholder:text-secondary"
                  type="text"
                  name="code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="Enter Code"
                />
              </div>

              {/* Category Select Starts */}
              <div className="flex flex-col justify-start">
                <label htmlFor="categories" className="text-sm text-secondary">
                  Categories
                </label>
                <select
                  id="categories"
                  className="text-sm outline-none border border-gray-300 py-1 px-2 text-secondary"
                  value={categorySelected}
                  onChange={(e) => setCategorySelected(e.target.value)}
                >
                  <option hidden value="">
                    Select category
                  </option>
                  {CATEGORIES?.map((item, index) => (
                    <option key={index} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Category Select Ends */}

              <div className="flex flex-col justify-start text-secondary">
                <label htmlFor="dateStarts" className="text-sm ">
                  Start Date
                </label>
                <input
                  id="dateStarts"
                  type="date"
                  className="outline-none border border-gray-300 px-2 py-1 text-sm"
                  value={dateStarts}
                  min={currentDate}
                  onChange={(e) => setDateStarts(e.target.value)}
                />
              </div>
              <div className="flex flex-col justify-start text-secondary">
                <label htmlFor="dateEnds" className="text-sm ">
                  End Date
                </label>
                <input
                  id="dateEnds"
                  type="date"
                  className="outline-none border border-gray-300 px-2 py-1 text-sm"
                  value={dateEnds}
                  min={dateStarts}
                  disabled={dateStarts === ""}
                  onChange={(e) => setDateEnds(e.target.value)}
                />
              </div>

              <div className="flex flex-col justify-start text-secondary">
                <label htmlFor="location" className="text-sm">
                  Location
                </label>
                <Select
                  defaultValue="All India"
                  isMulti
                  name="location"
                  options={statesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={selectedStates}
                  defaultInputValue={selectedStates}
                  onChange={(selectedOptions) =>
                    setSelectedStates(selectedOptions)
                  }
                />
              </div>

              {/* Membership Checkboxes Starts */}
              <div className="flex flex-col text-secondary">
                <label htmlFor="membership" className="text-sm">
                  Applicable for membership
                </label>
                <div id="membership" className="flex justify-evenly">
                  <div className="flex gap-1">
                    <input
                      type="checkbox"
                      id="free"
                      checked={isFree}
                      onChange={(e) => setIsFree(e.target.checked)}
                    />
                    <label htmlFor="free">Free</label>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="checkbox"
                      id="pro"
                      checked={isPro}
                      onChange={(e) => setIsPro(e.target.checked)}
                    />
                    <label htmlFor="pro">Pro</label>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="checkbox"
                      id="ultra"
                      checked={isUltra}
                      onChange={(e) => setIsUltra(e.target.checked)}
                    />
                    <label htmlFor="ultra">Ultra</label>
                  </div>
                </div>
              </div>
              {/* Membership Checkboxes Ends */}

              {/*other Checkboxes starts */}
              <div className="flex flex-col gap-3 text-secondary">
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="lifetime"
                    name="lifetimeChecked"
                    checked={checkBoxesInput.lifetimeChecked}
                    onChange={handleCheck}
                  />
                  <label htmlFor="lifetime" className="text-sm">
                    Applicable for lifetime accessibility only
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="morethanlac"
                    name="moreThanLacChecked"
                    checked={checkBoxesInput.moreThanLacChecked}
                    onChange={handleCheck}
                  />
                  <label htmlFor="morethanlac" className="text-sm">
                    Applicable for photography services only
                  </label>
                </div>
              </div>
              {/*other Checkboxes Ends */}

              {/* Btn */}
              <div className="flex justify-center">
                <Button
                  text="Generate Code"
                  handleClick={() =>
                    editCodeFunc(
                      couponId,
                      amount,
                      checkBoxesInput,
                      categorySelected,
                      dateStarts,
                      dateEnds,
                      isFree,
                      isPro,
                      isUltra,
                      selectedStates,
                      code
                    )
                  }
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EditCouponCode;
