import React from "react";

const VideoGrid = ({ videoUrl, closeModal }) => {
  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgb(0 0 0 / 39%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modal: {
      background: "white",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      position: "relative",
      textAlign: "center",
      maxWidth: "90%",
      maxHeight: "90%",
    },
    header: {
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "gray",
      padding: "10px",
      borderRadius: "8px 8px 0px 0px",
    },
    modalClose: {
      fontWeight: "600",
      color: "#ffff",
      cursor: "pointer",
    },
    modalContent: {
      overflow: "auto",
      padding: "20px",
    },
  };

  return (
    <div style={modalStyle.overlay}>
      <div style={modalStyle.modal}>
        <div style={modalStyle.header}>
          <button style={modalStyle.modalClose} onClick={closeModal}>
            X
          </button>
        </div>
        <div style={modalStyle.modalContent}>
          <video width="640" height="480" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoGrid;
