import React from "react";

// Assets
import ExampleProfile from "../assets/profile.png";
import { getTimeWithFormat } from "../Utils/utils";

const ChatMessage = ({
  role,
  profilePic,
  photographerProfilePic,
  msg,
  time,
}) => {
  console.log({role});
  return (
    <div
      className="flex items-center gap-2"
      style={{
        justifyContent:
          role == "admin" || role == "photographer" ? "flex-end" : "flex-start",
      }}
    >
      <img
        src={
          role === "admin"
            ? ExampleProfile
            : role === "photographer" && photographerProfilePic
            ? photographerProfilePic
            : role === "customer" && profilePic
            ? profilePic
            : ExampleProfile
        }
        alt="profile"
        width={40}
        height={40}
        className="rounded-full"
      />
      <div className="flex flex-col items-end gap-1">
        <p className="text-xs text-gray-500">{getTimeWithFormat(time)}</p>
        <p
          className="text-white text-sm px-3 py-1 rounded-sm"
          style={{
            backgroundColor:
              role == "admin" || role == "photographer" ? "#3498DB" : "#2ECC71",
          }}
        >
          {msg}
        </p>
      </div>
    </div>
  );
};

export default ChatMessage;
