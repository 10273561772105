import React from "react";

const HorizontalTab = ({
  text,
  handleClick,
  selected = false,
  selectedColor = "#620808",
  unslectedColor = "#ffffff",
  className = "",
}) => {
  return (
    <li
      className={`${className} ${
        selected ? "" : "border border-secondary/60"
      } w-1/2 md:w-1/4`}
      style={{ backgroundColor: selected ? selectedColor : unslectedColor }}
      onClick={handleClick}
    >
      <span
        aria-current="page"
        className={`${
          selected ? "text-white" : ""
        } inline-block py-3 w-full cursor-pointer`}
      >
        {text}
      </span>
    </li>
  );
};

export default HorizontalTab;
