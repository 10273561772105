import React, { useState, useEffect } from "react";
import Card from "../components/Card";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";

// helpers
import { CARD_DATA } from "../helpers/constants";

// url of page: /change-events-date

const ChangeEventDate = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const onSearch = (e) => {
    e.preventDefault();
    console.log(searchQuery);
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <SearchAndFilterPanel
        onSearch={onSearch}
        searchQuery={searchQuery}
        onChangeSearch={onChangeSearch}
      />
      {CARD_DATA.length > 0 ? (
        <section>
          <h1 className="text-xl md:text-3xl text-main font-bold mb-3">
            Change Event Date
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {CARD_DATA.map((item) => (
              <Card
                key={item.id}
                href={`/change-events-date/${item.id}`}
                name={item.name}
                city={item.city}
                phone={item.phone}
                eventStartDate={item.eventStartDate}
                eventEndDate={item.eventEndDate}
                location={item.location}
              />
            ))}
          </div>
          <Pagination
            total={totalPages}
            current={currentPage}
            onPageChange={handlePrevPage} // Pass the handlePrevPage function as onPageChange
            onNextPage={handleNextPage} // Pass the handleNextPage function as onNextPage
          />
        </section>
      ) : (
        <NoResults heading="Upcoming Events" />
      )}
    </>
  );
};

export default ChangeEventDate;
