// faqApi.js
import axios from "axios";
const faqBaseUrl = `${process.env.REACT_APP_BASE_URL}/faq`;
const aboutBaseUrl = `${process.env.REACT_APP_BASE_URL}/about`;


///////////////////about

export const getAllAboutUs = (token, resultsPerPage, currentPage, searchQuery,order) => {
  const headers = {
    "x-auth-token": token,
  };
  const params = {
    limit: resultsPerPage,
    offset: (currentPage - 1) * resultsPerPage,
    search: searchQuery,
    sortOrder: order
    
  };

  return axios
  .get(aboutBaseUrl, {
    params: params,
    headers: headers, // Pass the headers object here
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createAboutUs = (token, heading, description, imgFile) => {
  const headers = {
    "x-auth-token": token,
    // 'Content-Type': 'multipart/form-data',
    // 'Accept': 'application/json'
  };

  const formData = new FormData();
  formData.append("media", imgFile);
  formData.append("heading", heading);
  formData.append("description", description);

  return axios
    .post(aboutBaseUrl, formData, { headers })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error in POST request:", error);
      throw error;
    });
};


export const updateAboutUs = (token, id, heading, description, imgFile) => {
  const headers = {
    "x-auth-token": token,
  };

  const formData = new FormData();
  formData.append("media", imgFile);
  formData.append("heading", heading);
  formData.append("description", description);

  const url = `${aboutBaseUrl}/${id}`;

  return axios
    .put(url, formData, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteAboutUs = (token, id) => {
  const headers = {
    "x-auth-token": token,
  };

  const url = `${aboutBaseUrl}/${id}`;

  return axios
    .delete(url, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

///////////////////faqs
export const getAllFaqs = (token, itemsPerPage, currentPage, searchQuery,order) => {
  const headers = {
    "x-auth-token": token,
  };

  const params = {
    limit: itemsPerPage,
    offset: (currentPage - 1) * itemsPerPage,
    search: searchQuery,
    sortOrder: order

  };

  return axios
  .get(faqBaseUrl, {
    params: params,
    headers: headers, // Pass the headers object here
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const createFaq = (token, question, answer) => {
  const headers = {
    "x-auth-token": token,
  };

  const data = {
    question,
    answer,
  };

  return axios
    .post(faqBaseUrl, data, { headers })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error in POST request:", error);
      throw error;
    });
};

export const updateFaq = (token, id, question, answer) => {
  const headers = {
    "x-auth-token": token,
  };

  const data = {
    question,
    answer,
  };

  const url = `${faqBaseUrl}/${id}`;

  return axios
    .put(url, data, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteFaq = (token, id) => {
  const headers = {
    "x-auth-token": token,
  };

  const url = `${faqBaseUrl}/${id}`;

  return axios
    .delete(url, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
