import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../components/Button";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Confirmation = ({
  showModal,
  setShowModal,
  handleConfirm,
  text,
  heading,
  btnText,
  photographerId,
  userId
}) => {
  const closeModal = () => {
    setShowModal(false);
  };
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("authToken");

  const handleDelete = async () => {
    await handleConfirm();

    if(photographerId) {
      const apiUrl =
        `${baseUrl}/user/photographer`;
      const headers = {
        "x-auth-token": token,
      };
  
      const data = {
        "userId": userId,
        "photographerId": photographerId,
        "status": "REJECT"
      }

      axios
    .put(apiUrl, data, { headers: headers })
    .then((response) => {
      toast.success("Photographer rejected successfully!");
      console.log(response.data);
      // Handle the response here if needed
    })
    .catch((error) => {
      console.error("Error updating status:", error);
    });
  }
  setShowModal(false);
  };

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }

    return () => {
      document.body.style.overflowY = "visible";
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed top-1/4 z-20 flex flex-col gap-10 p-3 min-w-[80vw] md:min-w-[40vw] bg-bgMain rounded-lg">
            <div className="relative flex justify-center items-centerw-full bg-secondary p-2 rounded-t-md">
              <p className="text-white">{heading}</p>
              <RxCross1
                className="absolute right-2 text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>

            <p className="text-gray-800 text-center">{text}</p>

            <div className="flex justify-end gap-3">
              {/* <Button text={btnText} handleClick={handleDelete} /> */}
              <Button text={btnText} handleClick={() => {
  handleDelete();// Close the AddPhoto component
}} />
              <Button text="Cancel" handleClick={() => setShowModal(false)} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Confirmation;
