import React, { useEffect, useState } from "react";

//components
import Button from "../components/Button";

// modals
import GenerateCoupon from "../modals/GenerateCoupon";
import Success from "../modals/Success";

//react-icons
import { BiSolidCoupon } from "react-icons/bi";
import CouponCard from "../components/CouponCard";
import EditCouponCode from "../components/EditCouponCode";
import axios from "axios";
import { async } from "q";
import SearchAndFilterPanel from "../components/SearchAndFilterPanel";
import Pagination from "../components/Pagination";
import NoResults from "../components/NoResults";


const CouponCodeGenerator = () => {
  const [showGenerateCoupon, setShowGenerateCoupan] = useState(false);
  const [showEditCoupon, setShowEditCoupon] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const token = sessionStorage.getItem('authToken');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [storeAmount, setStoreAmount] = useState('');
  const [coupon, setCoupon] = useState([]);
  const [editCoupon, setEditCoupon] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this according to your needs
  const [totalItems, setTotalItems] = useState(0);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [searchQuery, setSearchQuery] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL_NON_ADMIN;
  const [totalResults, setTotalResults] = useState(0);
  const resultsPerPage =10; // Number of results per page
  const [order, setOrders] = useState()


  const onSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    getAllcodes();
  };

  const onChangeSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const generateCode = async (
    amount,
    code,
    checkBoxes,
    category,
    dateStarts,
    dateEnds,
    isFree,
    isPro,
    isUltra,
    selectedStates,
    resetForm
  ) => {
    const apiUrl = `${baseUrl}/coupon-code/create`;
    const headers = {
      "x-auth-token": token
    };
    
    
    console.log('selectedstateeeeee', code);
    let states = [];
    if (selectedStates && selectedStates?.length > 0) {
      states = selectedStates?.map((item) => item.value);
    }

    const data = {
      "amount": amount,
      "applicableToLifeTime": checkBoxes?.lifetimeChecked,
      "applicableToPhotography": checkBoxes?.onlyPhotography,
      "category": category,
      "startDate": dateStarts,
      "endDate": dateEnds,
      "isFree": isFree,
      "isUltra": isUltra,
      "isPro": isPro,
      "code": code,
      "states": states,
    };

    console.log(data, "codedededede");

    try {
      const response = await axios.post(apiUrl, data, { headers });
      console.log("Response:", response.data);
      setStoreAmount(amount);
      console.log(response.data.message);
      setSuccessMessage(response.data.message);
      resetForm()
      getAllcodes();
      // Handle success, show a success message or redirect
      setShowGenerateCoupan(false);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.response.data.error.message);
      // Handle the error, show an error message, etc.
    }
  };

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < totalItems) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const editCodeFunc = async (
    couponId,
    amount,
    checkBoxes,
    category,
    dateStarts,
    dateEnds,
    isFree,
    isPro,
    isUltra,
    selectedStates,
    code
  ) => {
    const apiUrl = `${baseUrl}/coupon-code/update/${couponId}`;
    const headers = {
      "x-auth-token": token
    };

    let states = [];
    console.log(code, "selelelelelele")
    // if (selectedStates && selectedStates.length > 0) {
    //   states = selectedStates?.map((item) => item.value);
    // }

    const data = {
      "amount": amount,
      "applicableToLifeTime": checkBoxes.lifetimeChecked,
      "applicableToPhotography": checkBoxes.moreThanLacChecked,
      "category": category,
      "startDate": dateStarts,
      "endDate": dateEnds,
      "isFree": isFree,
      "isUltra": isUltra,
      "isPro": isPro,
      "states": selectedStates,
      "code": code
    };

    try {
      const response = await axios.put(apiUrl, data, { headers });
      console.log("Response:", response.data);
      setStoreAmount(amount);
      console.log(response.data.message);
      setSuccessMessage(response.data.message);
      getAllcodes();
      // Handle success, show a success message or redirect
      setShowEditCoupon(false);
      setShowSuccess(true);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.response.data.error.message);
      // Handle the error, show an error message, etc.
    }
  };

  const getAllcodes = async () => {
    try {
      const apiUrl = `${baseUrl}/coupon-code`;
      const params = {
        limit: resultsPerPage,
        offset: (currentPage - 1) * resultsPerPage,
        search: searchQuery,
        sortOrder: order
      };
      const response = await
        axios
          .get(apiUrl, {
            params: params,
          });
      console.log("Response:", response.data);
      setCoupon(response.data.data.rows);
      setTotalResults(response.data.data.count);
      setSuccessMessage(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error?.response?.data?.error?.message);
    }
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOders = (prop) => {
    console.log(prop, "propessssssss")
    setOrders(prop)
  }

  const handleDelete = async (id) => {
    try {
      const headers = {
        "x-auth-token": token
      };
      const apiUrl = `${baseUrl}/coupon-code/delete/${id}`;

      const response = await axios.delete(apiUrl, { headers });
      console.log("Response:", response.data);
      getAllcodes();
      setSuccessMessage(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      // setErrorMessage(error.response.data.error.message);
    }
  }
  const handleEdit = async (id) => {
    setShowEditCoupon(true);

    try {
      const headers = {
        "x-auth-token": token
      };
      const apiUrl = `${baseUrl}/coupon-code/${id}`;
      const response = await axios.get(apiUrl, { headers });
      console.log("Response:", response.data);
      setEditCoupon(response.data.data);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(error.response.data.error.message);
    }

  };

  useEffect(() => {
    getAllcodes();
  }, [currentPage,order, resultsPerPage]);

  console.log(successMessage);
  return (
    <section className="overflow-x-hidden">
      <div className=" flex-col gap-3 md:gap-0 md:flex-row md:items-center justify-between my-5">
        <h1 className="text-main md:text-3xl font-bold">
          Generate Coupon Code
        <SearchAndFilterPanel
            onSearch={onSearch}
            searchQuery={searchQuery}
            onChangeSearch={onChangeSearch}
            handleOders={handleOders}
          />
        </h1>
        <Button
          text="Generate Coupon Code"
          icon={<BiSolidCoupon className="text-lg" />}
          rounded="md"
          handleClick={() => setShowGenerateCoupan(true)}
        />
      </div>
      <EditCouponCode
        showModal={showEditCoupon}
        setShowModal={setShowEditCoupon}
        editCoupon={editCoupon}
        editCodeFunc={editCodeFunc}
      />
      <Success
        showModal={showSuccess}
        setShowModal={setShowSuccess}
        text={`Coupon code for amount ${storeAmount}- has been generated and sent to your e-mail id.
        `}
      />
      <GenerateCoupon
        showModal={showGenerateCoupon}
        setShowModal={setShowGenerateCoupan}
        errorMessage={errorMessage}
        successMessage={successMessage}
        generateCodeFunc={(
          amount,
          code,
          checkBoxesInput,
          category,
          dateStarts,
          dateEnds,
          isFree, isPro, isUltra,
          selectedStates,
          resetForm
        ) =>
          generateCode(amount, code, checkBoxesInput, category, dateStarts, dateEnds, isFree, isPro, isUltra,
            selectedStates,resetForm)
        }
      />
      {coupon.length > 0 ? (
        <>
         <div className="grid md:grid-cols-2 gap-3">
        {/* {successMessage ? <p>{successMessage}</p> : ''} */}
        <CouponCard
          coupon={coupon}
          handleEdit={(id) => handleEdit(id)}
          handleDelete={(id) => handleDelete(id)} />
        {/* <CouponCard handleEdit={() => setShowEditCoupon(true)} /> */}
      </div>
      
      
      
      <Pagination
        total={totalResults}
        perPage={resultsPerPage}
        current={currentPage}
        onPageChange={handlePageChange}
      />
        </>
      ) : (
    <NoResults heading="" />

      )}
     

    </section>
  );
};

export default CouponCodeGenerator;
