import React, { useEffect, useRef, useState } from "react";
import Confirmation from "./Confirmation";

//components
import Button from "../components/Button";

// react-icons
import { RxCross1 } from "react-icons/rx";
import { AiOutlineCloudUpload } from "react-icons/ai";

const AddCategoryPhotos = ({ showModal, setShowModal, alreadyUploadedImgs , uploadedFile,
  setUploadedFile,
  onFileUpload,
  saveFile,
  handleDelete,
  errorMessage,
  successMessage,
  setSuccessMessage,
  setErrorMessage,
  categoryId}) => {
  const fileUploadRef = useRef();

  const [photos, setPhotos] = useState(alreadyUploadedImgs);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checkedPhotos, setCheckedPhotos] = useState([]);
  console.log('already',photos);

  const [indexMap, setIndexMap] = useState([]);

  const handleUploadPhotos = async (e) => {
    const files = e.target.files;
    const uploadedPhotosSrc = [...photos];
    const uploadedFiles = [...uploadedFile];
    const newIndices = [];

    const promises = Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          uploadedPhotosSrc.push(event.target.result);
          uploadedFiles.push(file);
          newIndices.push(uploadedFiles.length - 1); // Save the index
          resolve();
        };
        reader.readAsDataURL(file);
      });
    });
    await Promise.all(promises);
    setUploadedFile(uploadedFiles);
    setPhotos(uploadedPhotosSrc);

    // Update the index mapping
    setIndexMap([...indexMap, ...newIndices]);
  };
  

  const handleCheck = (e, index) => {
    if (e.target.checked) {
      const uploadedFileIndex = indexMap[index];
      const updatedCheckedPhotos = [...checkedPhotos];
      updatedCheckedPhotos.push(photos[index]);
      setCheckedPhotos(updatedCheckedPhotos);
    }
    if (!e.target.checked) {
      const updatedCheckedPhotos = [...checkedPhotos];
      updatedCheckedPhotos.splice(index, 1);
      setCheckedPhotos(updatedCheckedPhotos);
    }
  };
  
  const handleRemove = (e, index) => {
    const updatedPhotos = [...photos];
    const updatedUploadedFiles = [...uploadedFile];

    // Use indexMap to find the corresponding index in uploadedFile
    const uploadedFileIndex = indexMap[index];

    updatedPhotos.splice(index, 1);
    updatedUploadedFiles.splice(uploadedFileIndex, 1);
    setPhotos(updatedPhotos);
    setUploadedFile(updatedUploadedFiles);

    // Update the index mapping after removal
    const newIndexMap = indexMap.slice(0, index).concat(indexMap.slice(index + 1));
    setIndexMap(newIndexMap);
  };
  


console.log('uploaded',uploadedFile);  

  console.log('cc',checkedPhotos);

  const closeModal = () => {
    setShowModal(false);
    setPhotos(alreadyUploadedImgs);
    setCheckedPhotos([]);
  };

  console.log(photos);

  useEffect(() => {
    if (showModal === true) {
      document.body.style.overflowY = "hidden";
      setPhotos(alreadyUploadedImgs);
    } else {
      document.body.style.overflowY = "visible";
      setPhotos([]);
    }

    return () => {
      document.body.style.overflowY = "visible";
      setPhotos(alreadyUploadedImgs);
    };
  }, [showModal, alreadyUploadedImgs]);

  return (
    <>
      {showModal && (
        <section className={` fixed inset-0 flex justify-center z-50`}>
          <div
            className="bg-black/50 absolute w-full h-full top-0"
            onClick={closeModal}
          ></div>

          <div className="fixed z-20 flex flex-col gap-1 pb-8 min-w-[80vw] md:min-w-[40vw] md:max-w-[40vw] max-h-screen bg-bgMain rounded-lg">
            <div className="w-full flex justify-between items-center bg-secondary p-3">
              <p className="text-white text-xl">Add Photos</p>
              <RxCross1
                className="text-white text-xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
            {/* {errorMessage && ( <p style={{ color: 'red',  marginLeft: '10px' }}>{errorMessage}</p>)}
            {successMessage && ( <p style={{ color: 'green', marginLeft: '10px' }}>{successMessage}</p>)} */}
            <div className=" flex flex-col gap-3 items-center my-3  overflow-auto">
              <div
                className="flex gap-2 justify-center items-center cursor-pointer bg-main text-white p-2 rounded-md"
                onClick={() => fileUploadRef.current.click()}
              >
                <AiOutlineCloudUpload className="text-3xl font-semibold" />
                <h1 className="text-xl font-semibold">Upload Photos</h1>
                <input
                  type="file"
                  hidden
                  multiple
                  ref={fileUploadRef}
                  onChange={handleUploadPhotos}
                />
              </div>
              <div className="grid gap-3 grid-cols-3">
                {photos?.map((item, index) => (
                  item.imageUrl ? (
                    <div key={index} className="relative w-[150px]">
                    <img src={item.imageUrl} alt="" className="max-h-[150px] w-full" />
                    <input
                      type="checkbox"
                      className="absolute top-0 right-0"
                      onChange={(e) => handleCheck(e, index)}
                    />
                  </div>
                  ) : (
                    <div key={index} className="relative w-[150px]">
                    <img src={item} alt="" className="max-h-[150px] w-full" />
                    <button className="absolute top-0 right-0" style={{ color:'red', fontSize:'30px' }}
                    onClick={(e) => handleRemove(e, index)}
                    >x</button>
                  </div>
                  )
                 
                ))}
              </div>

              {/* <div className="grid gap-3 grid-cols-3">
                {defaultPhotos?.map((item, index) => (
                  <div key={index} className="relative">
                    <img
                      src={item}
                      alt=""
                      className="max-h-[150px] max-w-[100px]"
                    />
                    <input type="checkbox" className="absolute top-0 right-0" />
                  </div>
                ))}
              </div> */}
            </div>

            <div className="flex gap-2 justify-center items-center">
              <Button
                text="Save"
                rounded="md"
                handleClick={saveFile}
              />
              {checkedPhotos.length > 0 && (
                <Button
                  text="Delete"
                  rounded="md"
                  bg="red"
                  handleClick={() => setShowDeleteModal(true)}
                />
              )}
            </div>
          </div>
        </section>
      )}
      <Confirmation
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        heading="Are you sure?"
        text="Are you sure you want to Delete?"
        btnText="Delete"
        handleConfirm={() => handleDelete(checkedPhotos)}
      />
    </>
  );
};

export default AddCategoryPhotos;
